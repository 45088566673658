<script setup>
import {computed} from 'vue'
import DDL from '../../DDL.vue'
import Field from '../../Field/HeldProperty.vue'
const styleOptions = [
  {
    label: 'Information',
    code: 'info'
  },
  {
    label: 'Warning',
    code: 'warning'
  },
  {
    label: 'Promotion',
    code: 'promo'
  }
]
const props = defineProps({
  data: {
    type: Object,
    required: true
  },
  itemType: {}
})
if (!props.data._estoppel && !props.data.style) {
  props.data.style = styleOptions[0].code
}
const emit = defineEmits(['empty'])
const onDelete = () => emit('empty')
const maxLength = computed(() => (props.itemType && props.itemType.includes('annotation') ? 50 : 1024))
</script>
<template>
  <div class="MR__Action__QuantityMessage">
    <div>
      <span>as</span>
      <DDL v-model="data.style" :options="styleOptions" />
      <span>that says</span>
    </div>
    <Field type="text" :maxLength="maxLength" :holder="data" name="message" class="messageText" />
    <div class="MR__Delete" @click="onDelete" />
  </div>
</template>
<style lang="scss">
.MR__Action__QuantityMessage {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-grow: 1;
  > div:first-child {
    display: flex;
    align-items: center;
    @extend %triggerActionSpan;
    > span {
      padding: 0 16px;
    }
    > .v-select {
      width: 128px;
    }
  }
  .messageText {
    flex-grow: 1;
  }
  .TextAreaHolder {
    > textarea {
      height: 116px;
      width: 100%;
    }
    p {
      font-size: 12px;
      color: $v3-note-color;
    }
  }
  .MR__Delete {
    align-self: flex-start;
    height: 36px;
  }
}
</style>
