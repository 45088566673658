<script setup lang="ts">
// @cms-next src/components/elements/CodeViewer.vue
import {ref, computed} from 'vue'

//
// Selectable formatted code textarea that makes it easy to copy the value out of.
//

interface Props {
  code: string
  unstyled?: boolean
}

const props = defineProps<Props>()

const previewEl = ref<HTMLTextAreaElement>(null)

// Calculates the actual required height to fit all the content, padding and borders on initialization.
const previewHeight = computed(() => {
  const el = previewEl.value
  if (el) {
    return el.scrollHeight + el.offsetHeight - el.clientHeight + 'px'
  }
  // No value until the component is mounted.
})

// Selects all the content on focus.
function selectAll() {
  previewEl.value.select()
}
</script>

<template>
  <textarea v-html="code" @focus="selectAll" readonly ref="previewEl" :style="{height: previewHeight}" :class="{unstyled}" class="code-view" />
</template>

<style scoped lang="scss">
textarea.code-view {
  $padding: 8px;

  width: calc(100% - 2 * $padding);
  overflow-x: auto;
  overflow-y: auto;
  /* Initialize to 0, so that the scrollHeight can be calculated correctly. */
  height: 0;
  padding: $padding;
  resize: none;
  font-family: monospace;
  font-size: 80%;
  color: inherit;

  &.unstyled {
    border-color: transparent !important;
    resize: none;
  }
}
</style>
