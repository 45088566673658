<script>
import {computed, reactive, watch, toRef} from 'vue'
import generic from './Generic.vue'
import event from './Event.vue'

const findFilterHolder = (t, key) => t.filters.find(f => f.field === key || f.join === key)

const getFilterData = (t, key) => {
  if (!t.filters) {
    return []
  }
  const holder = findFilterHolder(t, key)
  return holder ? holder.in : []
}

const labels = {
  gateway_id: subject => `Only show the selected gateways in the ${subject} output. Otherwise show all.`,
  audit_action: subject => `Only show the selected audit actions in the ${subject} output. Otherwise show all.`,
  provider: subject => `Only show the selected providers in the ${subject} output. Otherwise show all.`,
  seller_id: subject => `Only show the selected sellers in the ${subject} output. Otherwise show all.`,
  seller: subject => `Only show the selected seller in the ${subject} output. Otherwise show all.`,
  hidden_type: subject => `Only show the selected hidden types in the ${subject} output. Otherwise show all.`
}

const manageFilterData = (t, key, value, mk, mv) => {
  let holder
  if (t.filters) {
    holder = findFilterHolder(t, key)
  }
  if (value && value.length) {
    if (!t.filters) {
      t.filters = []
    }
    if (!holder) {
      mv = typeof mv === 'object' && mv !== null && !Array.isArray(mv) ? mv : false
      holder = {
        field: mv && mv.field ? mv.field : key
      }
      if (mk === 'event' && key !== 'event_template_id') {
        // Event category
        holder.join = 'event_template'
      } else if (mv && mv.join) {
        holder.join = mv.join
      }
      t.filters.push(holder)
    }
    holder.in = value
  } else if (holder) {
    arrayRemove(t.filters, holder)
    if (!t.filters.length) {
      delete t.filters
    }
  }
}

export default {
  components: {
    generic,
    event
  },
  props: {
    subject: {
      type: String,
      default: 'webhook'
    },
    type: {
      type: String,
      required: true
    },
    meta: {
      type: Object,
      required: true
    },

    // Event Category filters look like this:
    // {
    //   "field": "category",
    //   "in": [
    //     "cat1"
    //   ],
    //   "join": "event_template"
    // }
    // see https://tixtrack.atlassian.net/browse/TIC-2363?focusedCommentId=85830
    //
    // Event ID filters look either this:
    // {
    //     "field": "id",
    //     "in": [
    //         "<GUID1>"
    //     ],
    //     "join": "event_template"
    // }
    // or this:
    // {
    //     "field": "event_template_id",
    //     "in": [
    //         "<GUID1>"
    //     ]
    // }
    // See https://tixtrackteam.slack.com/archives/C02E6JSGNHM/p1724941576856729
    //
    // The specific flavour of Event ID filter used by this component is controlled by the
    // "eventIdJoin" boolean parameter; when set to TRUE, the former flavour is used, otherwise the latter.

    eventIdJoin: {
      type: Boolean
    },
    data: {
      type: Object,
      required: true
    }
  },
  async setup(props) {
    for (const fsk in props.meta) {
      for (const k in props.meta[fsk]) {
        const holder = props.meta[fsk][k]
        if (holder && typeof holder === 'object' && typeof holder.options === 'function') {
          // The function returns a Promise. The meta value needs to be turned into its result.
          // Substituting the original function for the Promise result is fine (and in fact is good so that we don't have to do it again):
          holder.options = await holder.options()
        }
      }
    }
    const values = reactive({})
    // Intermediary holder for selected filters
    // (can't write to "data" right away because the relevant holder objects will crop up and disappear depending on whether the value array is empty or not).
    const items = computed(() => {
      // Group filters in pairs so that there's no more than 2 in a row
      const rows = []
      const meta = props.meta[props.type]
      if (meta) {
        let row = []
        let prevMk
        Object.keys(meta).forEach(mk => {
          if (row.length === 2 || prevMk === 'event') {
            // Start a new row
            rows.push(row)
            row = []
          }
          row.push([mk, meta[mk]])
          const keys = mk === 'event' ? [props.eventIdJoin ? 'id' : 'event_template_id', 'category'] : [mk]
          keys.forEach(k => {
            if (!values[k]) {
              values[k] = getFilterData(props.data.trigger, k)
              watch(toRef(values, k), v => manageFilterData(props.data.trigger, k, v, mk, meta[mk]), {deep: true})
            }
          })
          prevMk = mk
        })
        rows.push(row)
      }
      return rows
    })
    watch(
      () => props.type,
      v => {
        Object.keys(values).forEach(k => {
          values[k] = []
        })
      }
    )
    return {
      items,
      values,
      labels
    }
  }
}
</script>
<template>
  <div class="FieldWrappers AutomatedTaskFilters" v-for="row in items">
    <template v-for="f in row" :key="type + ':' + f[0]">
      <template v-if="f[0] === 'event'">
        <event :holder="values" :eventIdJoin="eventIdJoin" :subject="subject" />
        <div class="flexbreak"></div>
      </template>
      <generic v-else v-model="values[f[0]]" :type="f[0]" :options="f[1].options || f[1]" :multiple="f[1].multiple ?? true" :optional="f[1].optional ?? false" :class="'ATFilter__' + f[0]" :description="labels[f[0]](subject, f[1])" />
    </template>
  </div>
</template>
<style lang="scss">
.AutomatedTaskFilters .FieldWrapper {
  flex-grow: unset !important;
}
</style>
