<template>
  <div class="add-group">
    <div class="Settings21__Heading">
      <h2>{{ mode === 'create' ? 'Create' : 'Edit' }} Staff Group</h2>
    </div>

    <div class="Settings21__Form">
      <div class="name-field">
        <label class="required">Name</label>
        <input type="text" v-model="name" />
      </div>

      <div class="sso-field" v-if="isSsoSupported">
        <label>SSO Identifier (optional)</label>
        <input type="text" v-model="sso_identifier" />
      </div>

      <div class="description-field">
        <label>Description (optional)</label>
        <textarea v-model="description" />
      </div>
    </div>

    <div class="InfoBlockWrap">
      <div class="InfoBlock">Add and remove sellers and assign roles to them based on your staff group’s needs.</div>
      <div v-for="error in errors" class="WarningBlockRed">{{ error }}</div>

      <div v-if="sellerRoles.length > 1" class="table-headings">
        <div class="name">Seller</div>
        <div class="role">Role</div>
        <div class="spacer"></div>
      </div>

      <SellerRow v-for="(sellerRole, index) in sellerRoles" :key="sellerRole.seller.id" :sellerRole="sellerRole" @delete="removeSeller(index)" @input="input => updateSeller(index, input)" />

      <button @click="openModal" class="btn btn-black uppercase add-seller">Add Seller</button>
    </div>

    <div class="Form21__Buttons">
      <a href="#" @click.prevent="close" class="btn btn-default uppercase">Cancel</a>
      <button @click.prevent="submit" :disabled="submitDisabled" class="btn btn-primary uppercase">Save Staff Group</button>
    </div>

    <Modal v-model="modalOpen" :onSubmit="addSeller" :trapFocus="true" submitText="Confirm">
      <template #header>Add Seller</template>

      <DDL :options="sellerOptions" v-model="selectedSeller" />
    </Modal>
  </div>
</template>

<script lang="ts">
import toast from '@/classes/ToastSingleton'
import {ref, computed, Ref, ComputedRef} from 'vue'
import Modal from '../Modal.vue'
import SellerRow from './SellerRow.vue'
import type {Seller, SellerResponse, SellerRole, StaffGroup} from './types'
import DDL from '../DDL.vue'
import GETCache from '@/classes/GETCache'
import {fetchStaffGroups, fieldsByName} from '@/components/Staff/helpers'
import {useRouter} from 'vue-router'
import {isSsoSupported} from '@/helpers/access'

// Used to display an "all" seller option to users
const allSeller: Seller = {
  name: 'All Sellers',
  id: 'all',
  portal_id: '',
  slug: '',
  ticket_template_id: '',
  timezone: ''
}

export default {
  name: 'AddStaffGroup',
  props: {
    id: {
      type: String
    }
  },
  components: {
    Modal,
    SellerRow,
    DDL
  },
  async setup({id}) {
    const router = useRouter()

    const sellerRoles: Ref<SellerRole[]> = ref([])
    const name: Ref<string> = ref('')
    const description: Ref<string> = ref('')
    const sso_identifier: Ref<string> = ref('')

    let group: StaffGroup = null
    const groups: Ref<Array<StaffGroup>> = ref([])
    const mode = id ? 'update' : 'create'

    if (mode === 'update') {
      await fetchStaffGroups(groups, id)

      if (groups.value.length > 0) {
        group = groups.value[0]
      }

      if (group) {
        name.value = group.name
        description.value = group.description
        sellerRoles.value = group.sellerRoles
        sso_identifier.value = group.sso_identifier
      }
    } else {
      sellerRoles.value = [
        {
          seller: allSeller,
          roles: {parentRole: 'portal-admin'}
        }
      ]
    }

    const submitting: Ref<boolean> = ref(false)
    const submitDisabled: ComputedRef<boolean> = computed(() => {
      return !name.value || submitting.value || !validInput.value || errors.value.size > 0
    })

    const errors: ComputedRef<Set<string>> = computed(() => {
      const errors: Set<string> = new Set()

      // Generate map of seller IDs and all roles associated with each ID
      // This is used to check for duplicate seller-role combinations
      const rolesBySeller: Record<string, Array<string>> = {}
      sellerRoles.value.forEach(sellerRole => {
        const sellerId = sellerRole.seller.id
        if (!rolesBySeller[sellerId]) rolesBySeller[sellerId] = []
        rolesBySeller[sellerId].push(sellerRole.roles.parentRole)
        if (sellerRole.roles.subRoles) {
          rolesBySeller[sellerId] = rolesBySeller[sellerId].concat(sellerRole.roles.subRoles)
        }
      })

      const hasPortalAdmin = rolesBySeller.all && rolesBySeller.all.includes('portal-admin')
      const hasOtherRoles = sellerRoles.value.length > 1
      if (hasPortalAdmin && hasOtherRoles) {
        errors.add(`You currently have the '${fieldsByName.get('portal-admin').label}' role assigned to 'All Sellers' but have another seller set up with a less privileged role. To resolve this, either assign a less privileged role to 'All Sellers', or remove it entirely.`)
      }

      Object.values(rolesBySeller).forEach(r => {
        const hasDuplicates = r.some((item, index) => r.indexOf(item) !== index)
        if (hasDuplicates) {
          errors.add('You currently have at least two sellers configured with the same role. To resolve this, either assign different roles for each of the sellers or remove the duplicates.')
        }
      })

      return errors
    })

    const close = () => {
      router.push({name: 'staff-groups'})
    }

    const selectedSeller: Ref<string> = ref('all')
    const sellers: Ref<SellerResponse> | null = ref(null)
    GETCache.get('seller').then(response => (sellers.value = response))

    const sellerOptions: ComputedRef<{label: string; code: string}[]> = computed(() => {
      if (!sellers.value) return []

      const options: Array<[string, Seller]> = [['all', allSeller], ...Array.from(sellers.value)]

      return options.map(([id, seller]) => ({
        label: seller.name,
        code: id
      }))
    })

    function addSeller() {
      const isAllSeller = selectedSeller.value === 'all'

      sellerRoles.value.push({
        seller: isAllSeller ? allSeller : sellers.value.get(selectedSeller.value),
        roles: {parentRole: isAllSeller ? 'portal-admin' : 'seller-admin'}
      })

      // So the default seller to "All Sellers" when adding a new seller
      selectedSeller.value = 'all'
    }

    function removeSeller(index) {
      sellerRoles.value.splice(index, 1)
    }

    const validInput: Ref<Boolean> = ref(true)
    function updateSeller(index, input) {
      sellerRoles.value[index].roles = input.roles
      validInput.value = input.isValid
    }

    const modalOpen: Ref<Boolean> = ref(false)
    function openModal() {
      modalOpen.value = true
    }

    function createOrUpdateGroup(): Promise<void> {
      const roles = []

      sellerRoles.value.map(sellerRole => {
        const sellerID = sellerRole.seller.id === 'all' ? null : sellerRole.seller.id
        if (sellerRole.roles.subRoles) {
          sellerRole.roles.subRoles.forEach(role => {
            roles.push({
              name: role,
              seller_id: sellerID
            })
          })
        } else {
          roles.push({
            name: sellerRole.roles.parentRole,
            seller_id: sellerID
          })
        }
      })

      const payload = {
        name: name.value,
        description: description.value,
        sso_identifier: sso_identifier.value,
        roles
      }

      if (!payload.sso_identifier) {
        delete payload.sso_identifier
      }

      if (group) {
        const successMessage = `${name.value} has been successfully edited.`
        return apiRequest('patch', `staff_group/${group.id}`, payload, successMessage)
      } else {
        const successMessage = `${name.value} has been successfully created as a Staff Group.`
        return apiRequest('post', 'staff_group', payload, successMessage)
      }
    }

    function apiRequest(method, endpoint, payload, successMessage) {
      return window.APIService[method](endpoint, payload).then(() => toast.success(successMessage))
    }

    function submit() {
      submitting.value = true

      createOrUpdateGroup().finally(() => {
        submitting.value = false
        close()
      })
    }

    return {
      mode,
      close,
      modalOpen,
      selectedSeller,
      sellerRoles,
      addSeller,
      removeSeller,
      updateSeller,
      submit,
      openModal,
      name,
      description,
      sso_identifier,
      submitting,
      submitDisabled,
      errors,
      sellerOptions,
      isSsoSupported: isSsoSupported()
    }
  }
}
</script>

<style lang="scss">
.add-group {
  .WarningBlockRed {
    margin-top: 16px;
  }

  .name-field {
    margin-bottom: 16px;

    input {
      width: calc(50% - 8px);
    }
  }

  .sso-field {
    margin-bottom: 16px;
  }

  .table-headings {
    display: flex;
    font-size: 12px;
    text-transform: uppercase;
    margin-top: 16px;
    font-weight: 600;
    padding-left: 16px;
  }

  .table-headings,
  .seller-row .ExpandableBlock__Header__Main {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .name,
    .role {
      width: 40%;
    }

    .spacer {
      width: 112px;
    }
  }

  .RadioButton--label > div:first-child {
    &::before,
    &::after {
      top: 10px;
    }
  }

  .Checkbox [type='checkbox'] + label::before {
    position: relative;
    top: -10px;
  }

  .Form21__Buttons {
    border-top: 0;
  }
}
</style>
