<template>
  <form novalidate>
    <h1>{{ confirmAccountMode ? 'Confirm account' : 'Create new password' }}</h1>
    <p>{{ note }}</p>
    <div v-if="error" class="WarningBlockRed">{{ error }}</div>
    <div>
      <label for="email">{{ confirmAccountMode ? 'Password' : 'New password' }}</label>
      <input id="new-password" v-model="formData.password" name="new-password" type="password" autofocus tabindex="1" />
    </div>
    <div>
      <label for="password">{{ confirmAccountMode ? 'Re-enter password' : 'Confirm password' }}</label>
      <input v-model="formData.confirmPassword" id="confirm-new-password" name="confirm-new-password" type="password" tabindex="2" />
    </div>
    <button class="btn btn-primary uppercase" ref="submitButtonEl" @click.prevent.stop="onSubmit">{{ confirmAccountMode ? 'Confirm and log in' : 'Update password' }}</button>
    <div v-if="success" class="InfoBlock">{{ success }}</div>
  </form>
</template>

<script lang="ts">
import {computed, PropType, ref} from 'vue'
import {getQuery} from '@/helpers/route'
import {login} from '@/helpers/api'
import {runPostLoginTask} from '@/helpers/login'

/**
 * Return null on success, otherwise error message
 */
const validateFields = (password: string, confirmPassword: string): null | string => {
  if (password.trim() === '' || confirmPassword.trim() === '') {
    return 'Please enter both password fields.'
  }

  if (password !== confirmPassword) {
    return 'Passwords must match.'
  }

  if (password.length < 8) {
    return 'Passwords must have at least eight letters or numbers.'
  }

  return null
}

export type UserType = 'staff' | 'tix'

export default {
  props: {
    confirmAccountMode: {
      type: Boolean
    },
    userType: {
      type: String as PropType<UserType>,
      required: true
    },
    email: {
      required: true,
      type: String
    }
  },

  setup(props) {
    const formData = ref({
      password: '',
      confirmPassword: ''
    })
    const error = ref('')
    const success = ref('')
    const submitButtonEl = ref()
    const {token} = getQuery()

    const onSubmit = () => {
      error.value = ''

      const validationMsg = validateFields(formData.value.password, formData.value.confirmPassword)
      if (validationMsg) {
        error.value = validationMsg
        return false
      }

      const data = {token, password: formData.value.password}
      window.APIService.post('password_reset/reset', data)
        .then(() => {
          success.value = 'Password has been updated successfully'
          const authMethod = props.userType === 'staff' ? 'ticketure_staff' : 'ticketure'
          login(props.email, formData.value.password, authMethod, null, true).then(() => {
            runPostLoginTask(true, submitButtonEl.value)
          })
        })
        .catch(err => {
          error.value = err
        })
    }

    const note = computed(() => {
      if (!props.email) {
        return ''
      }

      return `Create a new password for ${props.email}. Passwords must have at least eight letters or numbers.`
    })

    return {
      formData,
      onSubmit,
      success,
      note,
      error,
      submitButtonEl
    }
  }
}
</script>
