<template>
  <ExpandableBlock class="seller-row">
    <template #header>
      <div class="name">{{ sellerRole.seller.name }}</div>
      <div class="role">{{ parentRoleLabel }}</div>
      <div class="actions">
        <a href="#" @click.prevent="$emit('delete', sellerRole.seller.id)" class="delete"><i class="fa fa-trash"></i></a>
      </div>
    </template>
    <template #content>
      <div class="RadioGroup">
        <template v-for="(field, key) in fields">
          <Field v-if="!field.hidden" type="radio" :name="`${fieldName}-${key}`" :label="field.label" :caption="field.caption" :value="field.name" v-model="parentRole" />
          <div v-if="!field.hidden && field.subFields && parentRole === field.name" class="sub-field">
            <template v-for="subField in field.subFields">
              <Field type="checkbox" :label="subField.label" :caption="subField.caption" :value="subField.name" v-model="input[subField.name]" />

              <div v-if="subField.subFields && input[subField.name]" class="sub-field">
                <h4>Optional extensions</h4>

                <Field v-for="subSubField in subField.subFields" :disabled="subSubField.disabled" type="checkbox" :label="subSubField.label" :caption="subSubField.caption" :value="subSubField.name" v-model="input[subSubField.name]" :title="subSubField.tooltip" />
              </div>
            </template>
          </div>
        </template>
      </div>
    </template>
  </ExpandableBlock>
</template>

<script lang="ts">
import {ref, computed, watch, Ref, ComputedRef} from 'vue'
import Field from '../Field/Field.vue'
import {roles, configurableRoles, fields, fieldsByName, staffMemberAdministrationRoles} from './helpers'
import {Roles} from './types'
import ExpandableBlock from '../ExpandableBlock.vue'
import {dClone} from '@/helpers/format'

export default {
  name: 'SellerRow',
  props: {
    sellerRole: {
      type: Object
    }
  },
  components: {
    ExpandableBlock,
    Field
  },

  setup({sellerRole}, {emit}) {
    const open: Ref<Boolean> = ref(false)
    const parentRole: Ref<null | string> = ref(null)
    const parentRoleLabel: ComputedRef<string> = computed(() => fieldsByName.get(parentRole.value).label)
    const input: Ref<Record<string, boolean>> = ref({})

    function initializeRoles() {
      const parentRoles = Object.keys(roles)

      // Initialise the roles input structure
      parentRoles.forEach(parent => {
        if (roles[parent]) {
          roles[parent].forEach(role => {
            input.value[role] = false
          })
        }
      })

      // If editing an existing group, enable the group's roles in the form
      parentRole.value = sellerRole.roles.parentRole
      sellerRole.roles.subRoles?.forEach(role => {
        input.value[role] = true

        if (staffMemberAdministrationRoles.has(role)) {
          // The administration checkbox has no associated role so enable it based on it's sub roles.
          input.value['administration'] = true
        }
      })
    }
    initializeRoles()

    const selectedRoles: ComputedRef<Roles> = computed(() => {
      const result: Roles = {parentRole: parentRole.value}

      if (isConfigurableRole.value) {
        result.subRoles = Object.keys(input.value)
          // Only include enabled roles
          .filter(r => input.value[r])
          // Only include roles for the selected configurable roles
          .filter(r => roles[parentRole.value].includes(r))
      }
      return result
    })

    const validInput: ComputedRef<Boolean> = computed(() => {
      return isConfigurableRole.value ? selectedRoles.value.subRoles.length > 0 : true
    })

    const isConfigurableRole = computed(() => {
      return configurableRoles.includes(parentRole.value)
    })

    const administrationCheckboxState = computed(() => {
      return input.value['administration']
    })

    function hasAdministrationSubRoles(role?: Roles): boolean {
      return role?.subRoles?.some(role => staffMemberAdministrationRoles.has(role))
    }

    const mapFieldsToSubFields = (fields, results = {}) => {
      fields.forEach(f => {
        if (f.subFields) {
          results[f.name] = f.subFields.map(subF => subF.name)

          mapFieldsToSubFields(f.subFields, results)
        }
      })

      return results
    }
    const fieldsToSubFieldsMap = mapFieldsToSubFields(fields)

    watch(
      selectedRoles,
      (current, previous) => {
        if (current.parentRole !== 'staff-member' || (hasAdministrationSubRoles(previous) && !hasAdministrationSubRoles(current))) {
          input.value['administration'] = false
        }

        const currSubRoles = current?.subRoles ?? []
        const prevSubRoles = previous?.subRoles ?? []
        const removedSubRoles = prevSubRoles.filter(r => !currSubRoles.includes(r))
        removedSubRoles.forEach(removed => {
          const subSubRoles = fieldsToSubFieldsMap[removed]
          if (subSubRoles) {
            subSubRoles.forEach(r => {
              input.value[r] = false
            })
          }
        })
        emit('input', {roles: current, isValid: validInput.value})
      },
      {immediate: true}
    )

    watch(administrationCheckboxState, checked => {
      if (checked) {
        input.value['configure-events'] = true
      } else if (!checked) {
        staffMemberAdministrationRoles.forEach(role => {
          input.value[role] = false
        })
      }
    })

    const fieldName: ComputedRef<string> = computed(() => `${sellerRole.seller.id}-role`)

    const displayFields = dClone(fields)
    displayFields.forEach(field => {
      if (sellerRole.seller.id !== 'all' && field.name === 'portal-admin') {
        field.hidden = true
      }
    })

    if (sellerRole.seller.id !== 'all') {
      const kibanaField = displayFields
        .find(f => f.name === 'read-only')
        .subFields?.find(f => f.name === 'reporting')
        .subFields?.find(f => f.name === 'reporting-author')

      kibanaField.disabled = true
      kibanaField.tooltip = 'Kibana access requires All Seller permissions. To grant access to author Kibana reports, adjust permissions to be for all sellers.'
    }

    return {
      open,
      parentRole,
      fieldName,
      selectedRoles,
      fields: displayFields,
      parentRoleLabel,
      input
    }
  }
}
</script>

<style lang="scss">
.seller-row {
  background-color: white;
  margin-top: 16px;
  border-radius: 2px;

  &:first-child {
    margin-top: 12px;
  }

  .ExpandableBlock {
    &__Header {
      height: 65px;

      &__Main {
        width: 100%;
        padding-right: 0;

        .name {
          font-size: 16px;
          font-weight: 600;
          color: $heading-color;
        }

        .delete,
        .expand {
          color: $v3-dark-color;
          padding: 6px 22px;
        }

        .delete {
          font-size: 20px;
          border-right: 1px solid #ececed;

          &:hover {
            color: #647288;
          }
        }

        .expand {
          font-size: 18px;
          border-left: 1px solid #ececed;

          .fa {
            font-weight: 700;
          }
        }
      }
    }

    &__Content {
      background-color: white;
      border-top: 1px solid #dddddd;

      .RadioGroup {
        padding: 16px;
      }
    }
  }

  .body {
    display: none;
    transition: max-height 0.6s ease-in;
    border-top: 1px solid #ececed;

    .RadioGroup {
      padding: 16px;
    }
  }

  &.open {
    .body {
      display: block;
    }

    .expand .fa {
      transform: rotate(180deg);
    }
  }

  .sub-field {
    margin-left: 32px;
  }

  h4 {
    font-size: 13px;
    margin: 12px 0;
  }

  .Checkbox {
    margin-bottom: 16px;
  }

  .RadioGroup:not(.Plain):not(.Switcher) {
    margin-top: 0;
    border: none;
    border-radius: 0;
    background-color: transparent;

    .RadioButton {
      margin-bottom: 16px;
    }

    .RadioButton--label {
      outline: 0;
      background-color: transparent;
      padding: 0 0 0 33px;

      > div {
        &:first-child {
          flex-wrap: wrap;

          .caption {
            width: 100%;
            margin-left: 0;
            color: #70757d;
            font-size: 14px;
          }
        }
      }
    }
  }
}
</style>
