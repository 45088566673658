<script>
import {watch} from 'vue'
import TriggerOrAction from '../../TriggerOrAction.vue'
import ArrayElement from './ArrayElement.vue'
import {addCfg, addWatch} from '../../../../helpers/vModelPassThrough'
import {randomString} from '@/helpers/format'

// These IDs are needed as keys for v-for
const ensureIdProp = holder => {
  holder = holder || {}
  if (!holder._id) {
    holder._id = randomString()
  }
  return holder
}

export default addCfg({
  props: {
    data: {
      type: Array, // This will be the "date_or_session" array
      required: true
    },
    options: {
      type: Array,
      required: true
    }
  },
  emits: ['empty', 'typechange'],
  setup(props, {emit}) {
    if (!props.data.length) {
      // Init the array holder with the first item
      props.data.push({})
    }
    props.data.forEach(ensureIdProp)
    const itemType = addWatch(props, emit)
    watch(itemType, (nv, ov) => emit('typechange', [nv, ov]))
    const onEmpty = index => {
      props.data.splice(index, 1)
      if (!props.data.length) {
        emit('empty', itemType.value)
      }
    }
    const cmpTeller = () => ArrayElement
    const onOr = () => props.data.push(ensureIdProp())
    return {
      itemType,
      onEmpty,
      cmpTeller,
      onOr
    }
  },
  components: {
    TriggerOrAction
  }
})
</script>
<template>
  <div class="MR__DateOrSession" :class="{moreThanOne: data.length > 1}">
    <template v-for="(holder, i) in data" :key="holder._id">
      <TriggerOrAction :cmpTeller="cmpTeller" v-model="itemType" :options="options" @empty="onEmpty(i)" :data="data[i]">
        <div class="MR__DateOrSession__Or" @click="onOr" v-if="i + 1 === data.length" />
      </TriggerOrAction>
      <div class="ItemSeparator" v-if="i + 1 < data.length" />
    </template>
  </div>
</template>
<style lang="scss">
.MR__DateOrSession {
  &.moreThanOne > .MR__Item > .v-select {
    @extend %ui-disabled;
  }

  > .MR__Item:last-child {
    position: relative;
    &::before {
      content: '';
      position: absolute;
      border-right: 1px solid $v3-border-color;
      bottom: 24px;
      height: calc(100% - 60px);
      left: 124px;
    }
    .MR__DateOrSession__Or {
      position: absolute;
      @extend %or-button;
      bottom: 0;
      left: 100px;
    }
  }
}
</style>
