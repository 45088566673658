import BaseManager from '../TwoProngForkDDL/Manager'

// There are 4 possible states:
// 1. "ticket_type_names": "ticket_type_names" includes values, "ticket_group_names" is empty.
// 2. "ticket_group_names": "ticket_group_names" includes values, "ticket_type_names" is empty.
// 3. "both": Both "ticket_group_names" and "ticket_type_names" include values.
// 4. "none": The 2 arrays are both empty. This state occurs when the last DDL is deleted.

class Manager extends BaseManager {
  static prongOptions = [
    {
      label: 'Ticket Group',
      code: 'ticket_group_names'
    },
    {
      label: 'Ticket Type',
      code: 'ticket_type_names'
    }
  ]

  get allowAdditions() {
    return true
  }

  constructor(holder) {
    super(holder, ['ticket_type_names', 'ticket_group_names'])
  }

  getAllAvailableValues(prong) {
    return new Set(window.config(`config.${prong}`))
  }

  async init() {
    // Ensure that Vue1 has loaded the config (Some TG/TT names come from there)
    await window.vue1AppSellerPromise
    this.afterInit()
  }
}

export default Manager
