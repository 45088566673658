import UniqueOptionsManager from '../UniqueOptionsManager'
import view from './View.vue'

class Manager extends UniqueOptionsManager {
  static HOLDER = 'actions'

  static OPTIONS = new Map([
    ['session_annotation', 'Display a session annotation'],
    ['session_message', 'Display a session message'],
    ['date_annotation', 'Display a date annotation'],
    ['date_message', 'Display a date message'],
    ['quantity_message', 'Display a quantity message']
  ])

  static cmpTeller = v => (v ? view : 'div')
}

export default Manager
