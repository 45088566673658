import {ref, reactive, watch} from 'vue'
import Base from '../../../../classes/Base'
import DDLManager from './DDLManager'

// Abstract.
// See View.vue for what TwoProngForkDDL is.
class Manager extends Base {
  get allowAdditions() {
    return false
  }

  // "holder" a reactive data object holding the keys representing the 2 fork prongs
  // e.g. ("event_categories" + "event_template_ids") OR ("ticket_type_names" + "ticket_group_names").
  // The object may be the message rule object itself, or only one of its sub-objects e.g. "quantity_trigger".
  // "the2prongs" is an array specifying what the 2 prong properties are named on the object.
  constructor(holder, the2prongs) {
    super()
    this.holder = holder
    this.the2prongs = the2prongs
    this.workHolder = {}
    this.watchers = []
    the2prongs.forEach(prong => {
      this.workHolder[prong] = reactive(holder[prong] || [])
      // The target data does not need empty arrays. When they are empty, they must be missing altogether.
      // But for the UI v-for reactivity to work nicely we need arrays at all times, even when they're empty.
      // So, we edit the data on detached arrays (which can be empty) and sync them with the triggers.
      this.watchers.push(
        watch(this.workHolder[prong], a => {
          if (!a.length) {
            delete holder[prong]
          } else if (holder[prong]) {
            holder[prong].splice(0, a.length, ...a)
          } else {
            holder[prong] = a
          }
        })
      )
    })
    // map for holding the DDL managers (up to 2):
    this.managerMap = {}
    // Array holding the DDL managers that are currently engaged in the UI:
    this.managers = ref([])
    // The state flag indicating one of the 4 states is imperative: changing it will
    // empty/fill the 2 prongs with data.
    // For specific examples of what the states are see the derived/concrete classes.
    // (It is much more robust and efficient to control the presence/absence of the data by leveraging
    // a state flag than to micromanage/marshal the data directly).
    // However, the initial flag state needs to be figured out from the input data:
    let stateFlag
    if (this.holdsProng(the2prongs[0]) && this.holdsProng(the2prongs[1])) {
      stateFlag = 'both'
    } else if (this.holdsProng(the2prongs[0]) && !this.holdsProng(the2prongs[1])) {
      stateFlag = the2prongs[0]
    } else {
      stateFlag = the2prongs[1]
    }
    this.setShallowReactive({
      state: stateFlag
    })
    this.init()
  }

  clearWatchers() {
    this.watchers.forEach(w => w())
  }

  teardown() {
    if (!this.tornDown) {
      this.clearWatchers()
      this.trigger('empty')
      this.tornDown = true
    }
  }

  // To be possibly overridden. Can be async (unlike the constructor), that's why it is a separate method.
  init() {
    this.afterInit()
  }

  afterInit() {
    this.onStateChange()
    watch(this.getRefs().state, () => this.onStateChange())
  }

  // Check whether a prong is present
  holdsProng(prong) {
    return this.workHolder[prong].length > 0
  }

  onStateChange() {
    this.the2prongs.forEach(prong => {
      if (![prong, 'both'].includes(this.sr.state)) {
        this.workHolder[prong].length = 0
      } else if (!this.holdsProng(prong)) {
        this.getDDLManager(prong).addValue()
      }
      if (this.holdsProng(prong)) {
        const ddlM = this.getDDLManager(prong)
        if (!this.managers.value.includes(ddlM)) {
          this.managers.value.push(ddlM)
        }
      } else if (this.managerMap[prong] && this.managers.value.includes(this.managerMap[prong])) {
        window.arrayRemove(this.managers.value, this.managerMap[prong])
      }
    })
    if (!this.managers.value.length) {
      this.teardown()
    }
  }

  // Abstract. Returns a Set.
  // getAllAvailableValues(prong) {}

  // Abstract. Returns a Function or NULL
  getGetNameByCodeFunction() {
    return null
  }

  getDDLManager(prong) {
    if (!this.managerMap[prong]) {
      this.managerMap[prong] = new DDLManager(prong, this.workHolder[prong], this.getAllAvailableValues(prong), this.getGetNameByCodeFunction(prong), this.allowAdditions)
      this.managerMap[prong].on('typechange', t => {
        this.sr.state = t
      })
      this.managerMap[prong].on('empty', () => {
        if (this.managers.value.length === 2) {
          this.sr.state = prong === this.the2prongs[0] ? this.the2prongs[1] : this.the2prongs[0]
        } else {
          this.teardown()
        }
      })
    }
    return this.managerMap[prong]
  }

  onUnmounted() {}
}

export default Manager
