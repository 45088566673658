<script>
import {ref, toRefs, watch, onUnmounted} from 'vue'
import makeModalCmp from './Abstract'
import Timeout from '@/classes/Timeout'
import {focusOnNextTick} from '@/helpers/ui'
import Node from '../Node'
import DDL from '@/components/DDL.vue'
const cmp = makeModalCmp(setup => {
  const input = ref(null)
  const modifierInput = ref(null)
  const calcButton = ref(null)
  const modifierOptions = Node.modifierOptions
  const n = setup.data.node
  modifierOptions.forEach(op => {
    if (['ADD', 'SUB'].includes(op.code) && !op.label.includes(setup.plainCurrencySymbol)) {
      op.label = `${op.label}${setup.plainCurrencySymbol}`
    }
  })
  // Call the API for the calculated value unless it has already been calculated, or the price is not of calculated source.
  const submitPromiseMaker = () => n.getCommitPromise()
  n.on('changeToFixed', () => focusOnNextTick(input))
  n.on('recalculationNeeded', nodeUid => {
    if (modifierInput.value) {
      if (modifierInput.value === document.activeElement) {
        // The user is changing the input. Buffer/debounce this and then react:
        Timeout.get(nodeUid, () => n.recalculate(), 500)
      } else {
        n.recalculate()
      }
    }
  })
  watch(toRefs(n.dr.modifier).type, () => focusOnNextTick(modifierInput))
  onUnmounted(() => ['changeToFixed', 'recalculationNeeded'].forEach(l => n.purgeListeners(l)))
  return {
    input,
    modifierInput,
    calcButton,
    modifierOptions,
    submitPromiseMaker,
    sourceOptions: Node.sourceOptions,
    onBeforeSubmit: () => setup.data.node.onBeforeCommit(),
    ...setup
  }
})
cmp.components.DDL = DDL
export default cmp
</script>
<template>
  <Modal v-model="show" submitText="Confirm" @submitted="onSubmitted" :onBeforeSubmit="onBeforeSubmit" :submitPromiseMaker="submitPromiseMaker" class="PriceLibModal EditPrice" :class="{isNegative: data.node.sr.isNegative.value}" :trapFocus="true">
    <template #header>Edit Price</template>
    <div class="EditPrice__Info">
      <div>
        <label>Price row</label>
        {{ data.row }}
      </div>
      <div>
        <label>Price tier</label>
        {{ data.tier }}
      </div>
    </div>
    <div class="EditPrice__Type">
      <label>Price source</label>
      <DDL v-model="data.node.sr.source" :options="sourceOptions" />
    </div>
    <div v-if="data.node.sr.source === 'nominal'">
      <label>Nominal price</label>
      {{ data.node.sr.nominal }}
    </div>
    <div v-if="data.node.sr.source === 'fixed'" class="EditPrice__Fixed">
      <label>Fixed price</label>
      <div class="icon-input" :data-label="currencySymbol">
        <input v-model="data.node.sr.value" type="number" ref="input" step="0.01" />
      </div>
    </div>
    <div v-if="data.node.sr.source === 'calculated'" class="EditPrice__Calc">
      <table>
        <thead>
          <tr>
            <th>Nominal price</th>
            <th>Modifier</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{ data.node.sr.nominal }}</td>
            <td><DDL v-model="data.node.dr.modifier.type" :options="modifierOptions" /></td>
            <td>
              <div :class="{'input-percent': data.node.sr.useRounding.value}" class="icon-input" :data-label="currencySymbol">
                <input v-model="data.node.dr.modifier.value" type="number" :step="data.node.sr.useRounding.value ? 0.00001 : 0.01" ref="modifierInput" placeholder="0" />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="EditPrice__CalcValue">
        <label>Calculated price</label>
        <div>
          {{ data.node.sr.calculatedValue.value }}
        </div>
      </div>
    </div>
  </Modal>
</template>
<style lang="scss">
.EditPrice {
  color: $v3-note-color;
  &__Fixed {
    .icon-input {
      width: 124px;
    }
  }
  &.isNegative {
    .EditPrice__CalcValue > div {
      color: $warning-color;
    }
  }
  &__Info {
    display: flex;
    justify-content: space-between;
    > div {
      width: 50%;
    }
    padding-bottom: 16px;
    margin-bottom: 16px;
    border-bottom: 1px solid #ddd;
  }
  &__Type {
    margin-bottom: 16px;
  }
  &__Calc {
    table {
      width: 100%;
    }
    th {
      @extend %labelStyle;
      text-align: left;
    }
    th,
    td {
      &:not(:first-child) {
        width: 190px;
        padding-left: 16px;
      }
    }
    > div:last-child {
      border-top: 1px solid #ddd;
      margin-top: 16px;
      padding-top: 16px;
    }
  }
}
</style>
