<script>
import {computed, watch} from 'vue'
import DDL from '../DDL.vue'
import {addCfg, addWatch} from '../../helpers/vModelPassThrough'

export default addCfg({
  props: {
    data: {
      type: Object,
      required: true
    },
    options: {
      type: Array,
      required: true
    },
    // This function returns the actual component object to be used for individual triggers/actions
    cmpTeller: {
      type: Function,
      required: true
    },
    placeholder: {
      type: String
    }
  },
  emits: ['empty', 'typechange'],
  setup(props, {emit}) {
    // Type of the trigger or action
    const itemType = addWatch(props, emit)
    watch(itemType, (nv, ov) => emit('typechange', [nv, ov]))
    const cmpIs = computed(() => props.cmpTeller(itemType.value))
    const onEmpty = () => emit('empty', itemType.value)
    const placeholder = `-- Select ${props.placeholder} --`
    return {
      cmpIs,
      itemType,
      onEmpty,
      placeholder
    }
  },
  components: {
    DDL
  }
})
</script>
<template>
  <div class="MR__Item" :data-type="itemType">
    <DDL v-model="itemType" :options="options" :placeholder="placeholder" />
    <component :is="cmpIs" :data="data" @empty="onEmpty" :itemType="itemType" />
    <slot></slot>
  </div>
</template>
<style lang="scss">
.MR__Item {
  display: flex;
  width: 100%;

  &[data-type='quantity'] .MR__Trigger__TwoProngFork__Wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .MR__Trigger__TwoProngFork__Add,
    .MR__Trigger__TwoProngFork__DDL {
      > div:first-child {
        width: 44px;
      }
    }

    .MR__Trigger__TwoProngFork__DDL {
      &.isFirst {
        > div:first-child::before {
          content: 'from';
        }
      }
    }
  }

  .WarningBlock {
    margin: 0 0 0 16px;
    background-color: $v3-warning-bg;
    padding: 0 16px 0 48px;
    align-self: stretch;
    display: flex;
    align-items: center;
    &::before {
      border: 0;
    }
  }
}
</style>
