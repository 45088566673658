<script>
import {computed} from 'vue'
import {addCfg, addWatch} from '../../../../helpers/vModelPassThrough'
import DDL from '../../../DDL.vue'
import DDLManager from './DDLManager'
import field from '../../../Field/Field.vue'

export default addCfg({
  props: {
    index: {
      type: Number,
      required: true
    },
    manager: {
      type: DDLManager,
      required: true
    },
    options: {
      type: Array,
      required: true
    },
    prongOptions: {
      type: Array,
      required: true
    },
    error: {}
  },
  setup(props, {emit}) {
    const value = addWatch(props, emit)
    const isFirst = computed(() => props.index === 0)
    const onDelete = () => props.manager.removeValue(value.value)
    return {
      value,
      onDelete,
      isFirst
    }
  },
  components: {
    DDL,
    field
  }
})
</script>
<template>
  <div class="MR__Trigger__TwoProngFork__DDL" :class="{isFirst: isFirst}">
    <div></div>
    <DDL v-if="isFirst" v-model="manager.sr.type" :options="prongOptions" />
    <div v-else class="MR__Trigger__TwoProngFork__DDL__Spacer">or</div>
    <DDL v-model="value" :options="options" :allowAdditions="manager.allowAdditions" v-if="options.length > 0 || !manager.allowAdditions" />
    <field v-else v-model="value" :error="error" class="MR__DDL-input" />
    <div class="MR__Delete" @click="onDelete" />
  </div>
</template>
<style lang="scss">
.MR__Trigger__TwoProngFork__DDL {
  display: flex;
  justify-content: space-between;
  position: relative;
  align-items: center;

  &.error {
    margin-bottom: 32px;
  }

  &.isFirst > div:first-child::before {
    content: 'is';
  }

  .MR__DDL-input input,
  .v-select {
    background-color: white;
    width: $message-rules-ddl-width;
    min-width: $message-rules-ddl-width;
  }

  &:not(:first-child) {
    margin-top: $v3-spacing;
  }

  > div:first-child,
  &__Spacer {
    font-weight: 600;
    color: $v3-dark-color;
    text-align: right;
  }

  > div:first-child {
    width: 26px;
  }

  &__Spacer {
    width: $message-rules-ddl-width;
  }

  .MR__DDL-input,
  > div:not(:first-child) {
    margin-left: 16px;
  }
}
</style>
