// This "Node" class represents "nodes" from Price Library JSON schema.
// Essentially they are cells in the price matrix where "tiers" are the columns and "rows" are the rows.
// The purpose of this class is to encapsulate the translation between JSON representation of Nodes and
// their UI ("Edit Price" form) representation.

import {computed, toRefs, watch} from 'vue'
import Base from '@/classes/Base'
import {formatMoney, isValidMod, isGoodNumericValue, percentToMoneySwitch} from '@/helpers/money'
import {isSame} from '@/helpers/comparison'
import Timeout from '@/classes/Timeout'
import {randomString, copy, dClone, chopZeroesOff} from '@/helpers/format'
import WatchAndCorrect from '@/helpers/WatchAndCorrect'

class NegativePriceError extends Error {}

class Node extends Base {
  static nominalCell = {ref: 'Nominal'}

  static opFunctions = ['ADD', 'SUB', 'ROUND', 'ROUND_UP', 'ROUND_DOWN', 'ADD_PC', 'SUB_PC', 'MUL']

  static sourceOptions = [
    {
      code: 'nominal',
      label: 'Use the nominal price'
    },
    {
      code: 'fixed',
      label: 'Use a fixed price'
    },
    {
      code: 'calculated',
      label: 'Calculate a price'
    },
    {
      code: 'none',
      label: 'No available price'
    }
  ]

  static modifierOptions = [
    {
      code: 'ADD',
      label: 'Increase by '
    },
    {
      code: 'SUB',
      label: 'Decrease by '
    },
    {
      code: 'ADD_PC',
      label: 'Increase by %'
    },
    {
      code: 'SUB_PC',
      label: 'Decrease by %'
    }
  ]

  static roundingOptions = [
    {
      code: 'none',
      label: 'None'
    },
    {
      code: 'ROUND',
      label: 'Round to nearest'
    },
    {
      code: 'ROUND_UP',
      label: 'Round up to nearest'
    },
    {
      code: 'ROUND_DOWN',
      label: 'Round down to nearest'
    }
  ]

  static roundingValueOptions = ['1.00', '0.99', '0.95', '0.50', '0.25', '0.10', '0.05']

  // Practically, there will be no more than just one instance of this class needed at any given time.
  // As the instance is reusable, let's use just one for memory efficiency.
  static #instance
  static get(nominal, data, knownCalculatedValue, globalRounding) {
    if (!this.#instance) {
      this.#instance = new this()
    }
    if (nominal) {
      if (data) {
        this.#instance.import(data)
        if (knownCalculatedValue && this.#instance.isCalculated()) {
          this.#instance.sr.calculatedRaw = knownCalculatedValue
        }
      }
      this.#instance.nominal = nominal
      this.#instance.sr.nominal = formatMoney(nominal)
    }
    if (globalRounding) {
      this.#instance.globalRounding = globalRounding
    }
    return this.#instance
  }

  #getDefaults() {
    return {
      sr: {
        source: this.constructor.sourceOptions[0].code,
        value: '',
        nominal: '',
        calculatedRaw: false
      },
      modifier: this.#getDefaultModifier()
    }
  }

  #getDefaultModifier() {
    return {
      type: this.constructor.modifierOptions[0].code,
      value: '',
      rounding: {
        type: this.constructor.roundingOptions[0].code,
        value: this.constructor.roundingValueOptions[0]
      }
    }
  }

  constructor(dontUseCalculation) {
    super()
    this.uid = randomString()
    const defs = this.#getDefaults()
    this.setShallowReactive(defs.sr)
    this.setReactive('modifier', defs.modifier)
    this.setShallowReactive({
      useRounding: computed(() => this.dr.modifier.type.includes('_PC')),
      hideRoundingValue: computed(() => this.dr.modifier.rounding.type === 'none'),
      calculatedValue: computed(() => (this.isValid() ? (this.sr.calculatedRaw ? formatMoney(this.sr.calculatedRaw) : 'Calculating...') : 'N/A')),
      isNegative: computed(() => this.isValid() && this.sr.calculatedRaw && parseFloat(this.sr.calculatedRaw) < 0)
    })

    if (!dontUseCalculation) {
      // we don't want to query the API when unit testing this class
      watch(this.getRefs().source, (v, ov) => {
        if (!this.suppressChangeWatchers) {
          if ([v, ov].includes('calculated')) {
            this.#clearCalculated()
            copy(this.#getDefaultModifier(), this.dr.modifier)
          }
          if (v === 'fixed') {
            this.trigger('changeToFixed')
          }
        }
      })
      // Automatically request the API to recalculate the price when the arguments change.
      const modRefs = toRefs(this.dr.modifier)
      // Correct invalid modifier values:
      WatchAndCorrect(
        modRefs.value,
        v => !this.isCalculated() || this.#isValidMod(v),
        () => this.#triggerRecalculation(),
        true
      )
      watch(modRefs.type, (t, ot) => {
        let valueChanged = false
        if (['ADD', 'SUB'].includes(t) && ['ADD_PC', 'SUB_PC'].includes(ot)) {
          // Remove extra decimals when switching from percentage to money
          const v = percentToMoneySwitch(this.dr.modifier.value)
          if (v !== false) {
            this.dr.modifier.value = v
            valueChanged = true
          }
        }
        if (!valueChanged) {
          // Otherwise the above watcher will trigger recalculation if needed, so no need to trigger it twice
          this.#triggerRecalculation()
        }
      })
      this.lastRounding = JSON.stringify(this.dr.modifier.rounding)
      watch(this.dr.modifier, () => {
        const currentRounding = JSON.stringify(this.dr.modifier.rounding)
        if (currentRounding !== this.lastRounding) {
          this.lastRounding = currentRounding
          this.#triggerRecalculation()
        }
      })
    }
  }

  #triggerRecalculation() {
    if (!this.suppressChangeWatchers && this.isCalculated() && this.isValid()) {
      this.#clearCalculated()
      this.trigger('recalculationNeeded', this.uid)
    }
  }

  #isValidMod(v) {
    return isValidMod(v, this.sr.useRounding.value)
  }

  isCalculated() {
    return this.sr.source === 'calculated'
  }

  isValid() {
    if (['nominal', 'none'].includes(this.sr.source)) {
      return true
    }
    if (this.sr.source === 'fixed') {
      return isGoodNumericValue(this.sr.value)
    }
    const isGoodValue = isGoodNumericValue(this.dr.modifier.value)
    if (this.dr.modifier.rounding.type === 'none') {
      return isGoodValue
    }
    return isGoodValue && isGoodNumericValue(this.dr.modifier.rounding.value)
  }

  export() {
    if (this.sr.source === 'nominal') {
      return this.constructor.nominalCell
    }
    const data = {}
    switch (this.sr.source) {
      case 'none':
        data.ref = 'PriceUnavailable'
        break
      case 'fixed':
        data.decimal = String(this.sr.value)
        break
      case 'calculated': {
        const fn = this.dr.modifier.type
        const op = {
          fn,
          args: [{ref: 'Nominal'}, {decimal: String(this.dr.modifier.value)}]
        }
        Object.assign(data, fn.includes('_PC') && this.dr.modifier.rounding.type !== 'none' ? this.constructor.buildRounding(this.dr.modifier.rounding, op) : op)
        break
      }
    }
    return data
  }

  static buildRounding(data, op) {
    op = op || {ref: 'Computed'}
    return {
      fn: data.type,
      args: [op, {int: parseInt(parseFloat(data.value) * 100, 10)}]
    }
  }

  #clearCalculated() {
    delete this.commitPromise
    this.sr.calculatedRaw = false
  }

  import(data) {
    this.#clearCalculated()
    this.suppressChangeWatchers = true
    const defs = this.#getDefaults()
    copy(defs.sr, this.sr)
    copy(defs.modifier, this.dr.modifier)
    // Don't know of a better way to wait out the watch handlers (nextTick triggers too soon),
    // but this one works quite well:
    Timeout.get(
      `${this.uid}-import`,
      () => {
        delete this.suppressChangeWatchers
      },
      10
    )
    if (data.fn) {
      this.sr.source = 'calculated'
      if (data.fn.startsWith('ROUND')) {
        this.dr.modifier.rounding.type = data.fn
        this.dr.modifier.rounding.value = this.constructor.parseRoundingValue(data)
        this.#importOp(data.args[0])
      } else if (this.constructor.opFunctions.includes(data.fn)) {
        this.dr.modifier.rounding.type = 'none'
        this.#importOp(data)
      } else {
        this.#importRant(` function: ${data.fn}`)
      }
      this.calculatedFor = data
    } else if (data.decimal) {
      this.sr.source = 'fixed'
      this.sr.value = chopZeroesOff(data.decimal, 2)
    } else if (data.ref) {
      if (data.ref === 'Nominal') {
        this.sr.source = 'nominal'
      } else if (data.ref === 'PriceUnavailable') {
        this.sr.source = 'none'
      } else {
        this.#importRant(`: ${data.ref}`)
      }
    } else {
      this.#importRant(`: ${JSON.stringify(data)}`)
    }
  }

  static parseRoundingValue(data) {
    return String((data.args[1].int / 100).toFixed(2))
  }

  #importRant(str) {
    throw new Error(`Unrecognisable price node${str}`)
  }

  #importOp(data) {
    if (!this.constructor.opFunctions.includes(data.fn)) {
      this.#importRant(` function: ${data.fn}`)
    }
    let type = data.fn
    let value = chopZeroesOff(data.args[1].decimal, 2)
    if (type === 'MUL') {
      // Backward compatibility for *_PC previously saved as MUL
      value = parseFloat(value)
      type = value > 1 ? 'ADD_PC' : 'SUB_PC'
      value = chopZeroesOff(String((100 * (value > 1 ? value - 1 : 1 - value)).toFixed(5)))
    }
    this.dr.modifier.value = value
    this.dr.modifier.type = type
    this.#saveUnchanged()
  }

  async #calculate() {
    const exported = this.export()
    const matrix = {
      tiers: ['tier'],
      rows: [
        {
          name: 'row',
          phases: [
            {
              nominal: String(this.nominal),
              tier_modifiers: [exported]
            }
          ]
        }
      ]
    }
    if (this.globalRounding && this.globalRounding.type !== 'none') {
      matrix.global_post_modifier = this.constructor.buildRounding(this.globalRounding)
    }
    if (!this.calculatedFor || !isSame(this.calculatedFor, exported)) {
      this.calculatedFor = exported
      this.lastCalcPromise = this.constructor.calcMatrix(matrix)
    }
    const result = await this.lastCalcPromise
    this.sr.calculatedRaw = result.price_matrix._data[0].matrix[0][0].price_computed
  }

  static calcMatrix(matrix) {
    return window.APIService.post('price_library/matrix', matrix)
  }

  recalculate() {
    if (this.isValid()) {
      // Avoid this to be called multiple times simultaneously (may happen when more than one element in this.dr.modifier changes):
      Timeout.get(
        `${this.uid}-recalc`,
        () => {
          this.#doRecalc().catch(e => {
            if (e instanceof NegativePriceError) {
              // swallow
            } else {
              throw e
            }
          })
        },
        10
      )
    }
  }

  #doRecalc() {
    this.#saveUnchanged()
    this.#clearCalculated()
    this.commitPromise = this.#calculate()
    return this.commitPromise
  }

  #saveUnchanged() {
    if (this.isValid()) {
      this.unchangedMod = this.#getModSnapshot()
    }
  }

  #getModSnapshot() {
    const mod = dClone(this.dr.modifier)
    mod.value = chopZeroesOff(mod.value, 2)
    return JSON.stringify(mod)
  }

  #hasModChanges() {
    if (!this.unchangedMod) {
      return true
    }
    return this.#getModSnapshot() !== this.unchangedMod
  }

  getCommitPromise() {
    ;['-import', '-recalc', ''].forEach(k => Timeout.rm(`${this.uid}${k}`))
    if (!this.isCalculated() || (!this.#hasModChanges() && Boolean(this.sr.calculatedRaw))) {
      return Promise.resolve()
    }
    if (!this.commitPromise) {
      this.commitPromise = this.#calculate()
    }
    return this.commitPromise
  }

  onBeforeCommit() {
    if (this.isValid()) {
      delete this.commitPromise
      return true
    }
    return false
  }
}

export default Node
