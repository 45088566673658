import DateTimeOrder from '@old/Sorter/DateTimeOrder'
export default class PhaseOrder extends DateTimeOrder {
  compare(a, b) {
    // The default phase has no start_date. It must always be the first on the list:
    if (a.start_date === undefined) {
      return -1
    }
    if (b.start_date === undefined) {
      return 1
    }
    return super.compare(a, b)
  }
}
