<script>
import {reactive, ref, watch, onUnmounted} from 'vue'
import {NonAlarmingError} from '@/api/error/Misc'
import AutomatedTask from './Class'
import {addCfg, addWatch} from '@/helpers/vModelPassThrough'
import simplefield from '@/components/Field/Field.vue'
import rison from 'rison-node'
const isValidKibanaURL = url => typeof url === 'string' && url.startsWith('https://' + PORTAL.slug + '.kibana.') && url.includes('/s/' + PORTAL.slug + '/api/reporting/generate/')
const requiredMessage = 'Kibana URL is required'

export default addCfg({
  components: {
    simplefield
  },
  props: {
    at: {
      type: AutomatedTask,
      required: true
    }
  },
  emits: ['name'],
  setup(props, {emit}) {
    // Collect event detachers to invoke them when the component is unmounted
    const toDetach = []
    onUnmounted(() => toDetach.forEach(off => off()))
    const input = addWatch(props, emit)
    const raw = ref(input.value)
    let isValid = true
    const error = ref('')
    // We don't bind "input" to the textfield directly because we want only valid values to be reported upwards.
    // So, we instead bind a local ref ("raw") to the text input, watch it and, if it is valid, write it to "input".
    const memo = reactive({
      name: '',
      type: '',
      height: '',
      width: ''
    })
    toDetach.push(
      props.at.on('beforesave', () => {
        if (!isValid || !input.value) {
          if (!input.value && !error.value) {
            // Happens when creating a Kibana report, when no URL value has been entered yet
            error.value = requiredMessage
          }
          throw new NonAlarmingError('Kibana URL is invalid or missing')
        }
      })
    )
    const handleValueChange = (v, initialOpening) => {
      isValid = true // presume true unless proven false
      if (isValidKibanaURL(v)) {
        let u = new URL(v)
        if (u && u.pathname) {
          const type = u.pathname.split('/').pop().toLowerCase()
          memo.type = type.includes('pdf') ? 'PDF' : type.includes('png') ? 'PNG' : type.includes('csv') ? 'CSV' : ''
        }
        try {
          u = rison.decode(u.searchParams.get('jobParams'))
          if (typeof u === 'object') {
            Object.assign(memo, {
              name: u.title,
              height: u.layout?.dimensions.height || 'N/A',
              width: u.layout?.dimensions.width || 'N/A'
            })
            if (initialOpening !== true) {
              // Only report up when the change is caused by the user
              emit('name', u.title)
            }
          }
        } catch (e) {
          isValid = false
        }
      } else {
        isValid = false
      }
      if (isValid) {
        input.value = v
        error.value = ''
      } else {
        input.value = ''
        error.value = v ? 'Invalid Kibana URL' : requiredMessage
        // Clear the labels underneath when the input is invalid
        Object.assign(memo, {
          type: '',
          name: '',
          height: '',
          width: ''
        })
      }
    }
    watch(raw, handleValueChange)
    if (input.value) {
      // When opening an exiting URL for edit, populate the labels underneath
      handleValueChange(input.value, true)
    }
    return {
      raw,
      error,
      memo
    }
  }
})
</script>
<template>
  <div class="KibanaURL">
    <div class="FieldWrapper FieldWrapper__FullWidth">
      <label class="required">Kibana report URL</label>
      <simplefield type="text" v-model="raw" :error="error" />
    </div>
    <div class="FieldWrapper FieldWrapper__FullWidth KibanaURLInfo">
      <div>
        <label>Kibana report name</label>
        {{ memo.name }}
      </div>
      <div>
        <label>Report type</label>
        {{ memo.type }}
      </div>
      <div>
        <label>Height</label>
        {{ memo.height }}
      </div>
      <div>
        <label>Width</label>
        {{ memo.width }}
      </div>
    </div>
  </div>
</template>
<style lang="scss">
.KibanaURLInfo {
  display: flex;
  justify-content: start;
  > div {
    margin: 0;
    &:first-child {
      margin-left: 8px;
      width: 50%;
    }
    &:not(:first-child) {
      margin-left: 16px;
      min-width: 15%;
    }
  }
}
.KibanaURL {
  textarea {
    height: 104px;
  }
  .FieldWrapper {
    &:first-child {
      margin-bottom: 8px;
    }
    &:last-child {
      margin-top: 8px;
    }
  }
}
</style>
