// @cms-next @tix/api-sdk
import type {ResourceName, ApiResponse} from './response'
import type {Dict} from '@/helpers/types'
import {QueryParams, buildQueryString} from '@/helpers/QueryStringParameters'
import {csvToArray} from '@/helpers/StringHelpers'
import TixApiError from './TixApiError'

export type ApiArgs = Partial<ArgsWithBody>

interface ArgsWithoutBody {
  embed?: string | string[]
  query?: QueryParams
  limit?: number
}

interface ArgsWithBody extends ArgsWithoutBody {
  body: Dict<any>
}

// TODO Convert to async/await syntax?
export const API = {
  get<T extends ResourceName>(endpoint: string, args?: ArgsWithoutBody) {
    return request<T>('GET', endpoint, args, {retry: true, cached: false})
  },

  getCached<T extends ResourceName>(endpoint: string, args?: ArgsWithoutBody) {
    return request<T>('GET', endpoint, args, {retry: true, cached: true})
  },

  post<T extends ResourceName>(endpoint: string, args?: ApiArgs) {
    return request<T>('POST', endpoint, args)
  },

  patch<T extends ResourceName>(endpoint: string, args: ArgsWithBody) {
    return request<T>('PATCH', endpoint, args)
  },

  put<T extends ResourceName>(endpoint: string, args: ArgsWithBody) {
    return request<T>('PUT', endpoint, args)
  },

  delete<T extends ResourceName>(endpoint: string, args?: ApiArgs) {
    return request<T>('DELETE', endpoint, args)
  }
}

const {floor, random} = Math

function request<T extends ResourceName>(
  method: RequestInit['method'],
  endpoint: string,
  args?: ApiArgs,
  config?: {
    cached?: boolean
    retry?: boolean
  }
): Promise<ApiResponse<T>> {
  const retries = config?.retry ? 3 : 1
  const delay = floor(random() * 500)

  // TODO How to wait for seller ID?
  // TODO Support seller scope in e-commerce too; from window.tix.seller.id
  const path = buildPath(endpoint, args, config?.cached, window.theApp.seller?.id)

  const body = args?.body ? JSON.stringify(args.body) : undefined

  return requestAndRetry<T>(retries, delay, path, {method, body, credentials: 'same-origin'})
}

export function buildPath(endpoint: string, args?: ApiArgs, cached?: boolean, sellerId?: string) {
  const prefix = cached ? 'cached_api' : 'api'
  // Any part of the resource may be provided by the user. Encode all parts.
  const resource = endpoint.split('/').map(encodeURIComponent).join('/')
  const query = normalizeQueryParams(args, sellerId)
  const queryString = query.length > 0 ? `?${query}` : ''
  return '/' + prefix + '/' + resource + queryString
}

function normalizeQueryParams(args: ApiArgs = {}, sellerId?: string): string {
  const query = args.query ?? {}
  const embeds = args.embed ?? []
  const limit = args.limit ? String(args.limit) : undefined

  return buildQueryString({
    ...query,
    _seller: sellerId,
    // Convert string embeds to an array so that it is made unique and sorted.
    // This optimizes the URL for cache hits.
    _embed: Array.isArray(embeds) ? embeds : csvToArray(embeds),
    _limit: limit
  })
}

function requestAndRetry<T extends ResourceName>(times: number, delay: number, path: string, config: RequestInit): Promise<ApiResponse<T>> {
  return new Promise((resolve, reject) => {
    function attempt() {
      fetch(path, config)
        .then(response => {
          const status = response.status
          if (status >= 200 && status < 400) {
            response.json().then(resolve)
          } else {
            throw new TixApiRetryError(response, status == 502)
          }
        })
        .catch(error => {
          times = times - 1

          if (times > 0 && canRetry(error)) {
            setTimeout(attempt, delay)
            delay *= 2
          } else {
            tryConvertingToTixApiError(error).then(reject)
          }
        })
    }

    attempt()
  })
}

async function tryConvertingToTixApiError<T>(error: T | any): Promise<TixApiError | T> {
  if (isTixApiRetryError(error)) {
    try {
      // Don't consume JSON body on error until retries have completed.
      const body = await error.response.json()
      // A JSON body may or may not be from Tix API.
      if (body?._data?.[0]) {
        return new TixApiError(body, error.response)
      }
    } catch (_) {
      // ignore
    }
  }

  return error
}

// Not intended for use outside the API.ts module.
class TixApiRetryError {
  isTixApiRetryError = true
  constructor(public response: Response, public retry: boolean) {}
}

function isTixApiRetryError(error: any): error is TixApiRetryError {
  return (error as TixApiRetryError).isTixApiRetryError
}

function canRetry(error: any): boolean {
  // Retry if cancelled or a retryable status code.
  if (isTixApiRetryError(error) && error.retry) {
    return true
  }

  // Firefox, Chromium
  if (error?.code === 'ECONNABORTED') {
    return true
  }

  // Safari
  if (error?.message === 'Network Error') {
    return true
  }

  return false
}
