<script setup>
import {copyTextToClipboard} from '@/helpers/clipboard'
const props = defineProps({
  value: {
    type: String
  }
})
const onClick = e => {
  if (copyTextToClipboard(props.value) === true) {
    const el = e.target
    el.classList.add('copied')
    setTimeout(() => {
      if (document.body.contains(el)) {
        el.classList.remove('copied')
      }
    }, 2000)
  }
}
</script>
<template>
  <div class="DisplayAndCopy">
    <div v-shtml="value || '—'" />
    <div v-if="value" @click.stop="onClick" />
  </div>
</template>
<style lang="scss">
.DisplayAndCopy {
  display: flex;
  align-items: flex-start;
  > div:not(:first-child) {
    cursor: pointer;
    margin-left: 8px;
    margin-top: -3px;
    width: 24px;
    height: 24px;
    @extend %centered-bg;
    border-radius: 2px;
    border: 1px solid $v3-separator-color;
    background-color: $v3-copy-bg;
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='5.33398' y='5.33333' width='8' height='8' rx='2' stroke='%23282B37' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M10.666 5.33333V4C10.666 3.26362 10.0691 2.66667 9.33268 2.66667H3.99935C3.26297 2.66667 2.66602 3.26362 2.66602 4V9.33333C2.66602 10.0697 3.26297 10.6667 3.99935 10.6667H5.33268' stroke='%23282B37' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    &.copied {
      background-image: url("data:image/svg+xml,%3Csvg width='16' height='10' viewBox='0 0 16 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3 5.00002L6.33333 8.33335L13 1.66669' stroke='%236DBF13' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    }
  }
}
</style>
