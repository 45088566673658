<script>
import {ref, reactive, onMounted} from 'vue'
import {useRouter} from 'vue-router'
import GETCache from '@/classes/GETCache'
import field from '../Field/HeldProperty.vue'
import {isEmptyObject, objDiff} from '@/helpers/comparison'
import isReadOnly from './IsReadOnly'
import {setError, cleanErrors} from '@/helpers/ErrorHolder'
import Validator from '@old/schema/Validator'
import schema from '@/schema/salespoint'
import {NotFoundError} from '@/api/error/Misc.js'
import {stripUnderscores, dClone} from '@/helpers/format'

const resourceName = 'salespoint'

const makeValidator = type =>
  Validator.get(
    schema,
    `/tix/salespoint#/$defs/${type}`,

    // Error setter
    ed => {
      // "ed" for error details
      if (ed.key) {
        setError(ed.holder, ed.key, ed.message)
      }
    },

    // Error cleaner. This function is ultimately passed to the strip function as the 2nd argument.
    cleanErrors
  )

export default {
  props: {
    // This is supplied when editing. Otherwise we're creating.
    id: String
  },
  async setup(props) {
    const router = useRouter()
    const buttons = ref(null)
    const name = ref(null)
    if (!props.id) {
      onMounted(() => name.value.$el.querySelector('input').focus())
    }
    await vue1AppSellerPromise
    let original
    const sps = await GETCache.get(resourceName)
    const readOnly = isReadOnly()
    let data
    let record
    if (props.id) {
      record = sps.find(pos => pos.id === props.id)
      if (!record) {
        throw new NotFoundError()
      }
      const rawData = dClone(record)
      ;['id', 'portal_id', 'seller_id'].forEach(k => delete rawData[k])
      original = dClone(rawData)
      data = reactive(rawData)
    } else {
      data = reactive({
        name: '',
        portal_id: PORTAL.id,
        seller_id: theApp.seller.id
      })
    }
    const validator = makeValidator(props.id ? 'update' : 'create')
    const validate = () => {
      const candidateData = dClone(data)
      stripUnderscores(candidateData)
      let valid = validator(candidateData, data)
      if (valid) {
        // Verify uniqueness
        if (sps.some(pos => pos !== record && pos.name.toLowerCase() === candidateData.name.toLowerCase())) {
          valid = false
          setError(data, 'name', 'The salespoint name is a duplicate of an existing salespoint, and must be unique.')
        }
      }
      return {
        valid,
        candidateData
      }
    }
    const cancel = () =>
      router.push({
        name: 'pos',
        query: {
          seller: theApp.seller.id
        }
      })
    const submit = async () => {
      const val = validate()
      if (val.valid) {
        const toSave = props.id ? objDiff(val.candidateData, original) : val.candidateData
        if (!isEmptyObject(toSave)) {
          const verb = props.id ? 'patch' : 'post'
          let url = resourceName
          if (props.id) {
            url = `${url}/${props.id}`
          }
          await APIService[verb](url, toSave, {ui: buttons.value})
          if (record) {
            Object.assign(record, toSave)
          } else {
            GETCache.bust(resourceName)
          }
        }
        cancel()
      }
    }
    const headPrefix = props.id ? 'Edit' : 'Create'
    return {
      buttons,
      readOnly,
      cancel,
      submit,
      headPrefix,
      name,
      data
    }
  },
  components: {
    field
  }
}
</script>
<template>
  <form @submit.prevent="submit" :class="{'form-disabled': readOnly}">
    <div class="Settings__Content__heading">
      <h2>{{ headPrefix }} Salespoint</h2>
    </div>
    <div class="FormSection">
      <div class="MR__Name">
        <label class="required">Name</label>
        <field :holder="data" name="name" ref="name" />
      </div>
    </div>
    <div class="BottomControls">
      <div ref="buttons">
        <a data-submit-ui-disabled="1" class="btn btn-default uppercase" @click="cancel">Cancel</a>
        <button type="submit" data-submit-ui-disabled="1" data-submit-spinner="1" class="btn btn-primary uppercase">Save Salespoint</button>
      </div>
    </div>
  </form>
</template>
<style lang="scss" scoped>
.FormSection {
  margin-bottom: 0;
  border-bottom: 1px solid #ddd;
}
</style>
