<script>
import {ref} from 'vue'
import makeModalCmp from './Abstract'
import {focusOnNextTick} from '@/helpers/ui'
import {setError, cleanErrors} from '@/helpers/ErrorHolder'
import {strip} from '@/helpers/format'
import IsValidNominalPrice from '../IsValidNominalPrice'

export default makeModalCmp(setup => {
  const step = ref(1)
  const onBeforeSubmit = () => {
    strip(setup.data, cleanErrors, true)
    if (!Boolean(setup.data.rowName)) {
      setError(setup.data, 'rowName')
      return false
    }
    if (!IsValidNominalPrice(setup.data)) {
      return false
    }
    if (!setup.data.isExternal) {
      if (step.value === 1) {
        step.value++
        focusOnNextTick(() => setup.element.value.dialogEl.querySelector('input'))
        return false
      }
      if (!Boolean(setup.data.tierName)) {
        setError(setup.data, 'tierName')
        return false
      }
    }
    return true
  }
  return {
    step,
    onBeforeSubmit,
    name: 'Price Matrix',
    ...setup
  }
})
</script>
<template>
  <Modal v-model="show" ref="element" submitText="Next" :onBeforeSubmit="onBeforeSubmit" @submitted="onSubmitted" class="PriceLibModal AddPriceMatrix">
    <template #header>{{ header }}</template>
    <template v-if="step === 1">
      <div class="InfoBlock">
        <p>Start by adding a price row to your price matrix (eg. Adult, Child).</p>
        <p v-if="data.isExternal">The nominal price is used for internal display purposes only as the price is taken from the 3rd party pricing system. You can always edit the price row name and nominal price later.</p>
        <p v-else>The nominal price is the base price that any calculations will be made from. You can always edit the price row name and nominal price later.</p>
      </div>
      <div>
        <label class="required">Price row name</label>
        <field :holder="data" name="rowName" />
      </div>
      <div class="NominalPrice">
        <label class="required">Nominal price</label>
        <field :holder="data" name="nominalPrice" type="money" :description="data.isExternal ? 'This help text needs to be re-written.' : null" />
      </div>
    </template>
    <template v-if="step === 2">
      <div class="InfoBlock">Now add an initial price tier (eg. Peak Pricing, Off-Peak Pricing). You can always edit the price tier name later.</div>
      <div>
        <label>Price tier name</label>
        <field :holder="data" name="tierName" />
      </div>
    </template>
  </Modal>
</template>
