<template>
  <div class="TableWrapper">
    <table class="Table">
      <thead>
        <tr>
          <td class="Table__Cell" v-for="[key, name] in Object.entries(displayFields)" :class="`col-${key}`">{{ name }}</td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="row in rows" class="Table__Row">
          <td class="Table__Cell" v-for="col in Object.keys(displayFields)" :class="`col-${col}`">
            <slot :name="`cell(${col})`" :col="col" :row="row">{{ row[col] }}</slot>
          </td>
          <td class="Table__Cell Table__CelLEditOptions" v-if="actions(row).length > 0">
            <KebabMenu>
              <li v-for="action in actions(row)" @click="() => action.callback(row)">{{ action.name }}</li>
            </KebabMenu>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts">
import {PropType} from 'vue'
import {Dict} from '@/helpers/types'
import KebabMenu from '@/components/KebabMenu.vue'

export interface RowAction {
  name: string
  callback: Function
}

export default {
  components: {KebabMenu},
  props: {
    rows: Array,
    displayFields: Object as PropType<Dict<string>>,
    // Use `actions()` in the template
    rowActions: Function
  },

  setup(props) {
    const headers = Object.values(props.displayFields)

    const actions = row => {
      return typeof props.rowActions === 'function' ? props.rowActions(row) : props.rowActions ?? []
    }

    return {
      headers,
      actions
    }
  }
}
</script>

<style lang="scss">
.Table {
  width: 100%;
  border-spacing: 0 16px;
  border-collapse: separate;

  thead {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 12px;

    td {
      padding-bottom: 0;
      padding-top: 6px;
      line-height: 1;
    }
  }

  tbody {
    padding: 12px 16px;
    margin-bottom: 16px;
    min-height: 64px;

    tr {
      padding-bottom: 16px;
    }

    td {
      background-color: white;
    }
  }

  td {
    padding: 12px 16px;
    text-align: left;
  }
}
</style>
