<template>
  <div class="staff StaffMembers Settings21">
    <div class="Settings21__Form">
      <div class="StaffMembers__SearchWrapper">
        <div class="SearchInput" id="searchContainer" data-submit-spinner="1">
          <input type="text" v-model="keywords" placeholder="Enter text to search" debounce="400" />
        </div>
        <button @click="$router.push({name: 'add-staff-member'})" class="uppercase btn btn-primary">Create staff member</button>
      </div>
      <div class="Filter" v-if="statusFilters.length > 0">
        Filter
        <ul>
          <li><a href="#" role="button" @click.prevent="statusFilter = ''" :class="{active: statusFilter === ''}">All</a></li>
          <li v-for="filter in statusFilters">
            <a href="#" role="button" @click.prevent="statusFilter = filter" :class="{active: filter === statusFilter}">{{ filter }}</a>
          </li>
        </ul>
      </div>
    </div>
    <div class="InfoBlockWrap">
      <Table v-if="filteredRecords && filteredRecords.length > 0" :rows="filteredRecords" :displayFields="displayFields" :rowActions="rowActions">
        <template #cell(name)="{col, row}">
          <h3>{{ row['first_name'] }} {{ row['last_name'] }}</h3>
          <small class="email">{{ row['email'] }}</small>
        </template>
        <template #cell(groups)="{col, row}">
          <div v-shtml="row['groups'].map(g => `<p>${g.name}</p>`).join('')"></div>
        </template>
        <template #cell(types)="{col, row}">
          <div v-shtml="row['types'].map(t => `<p>${t}</p>`).join('')"></div>
        </template>
        <template #cell(status)="{col, row}"
          ><span class="Status" :class="getStatusClass(formatState(row))">{{ formatState(row) }}</span></template
        >
        <template #cell(time)="{col, row}">
          <small class="timestamp" v-shtml="formatLastLoginOrCreated(row)" />
        </template>
      </Table>
      <div v-else-if="keywords.trim() !== '' || statusFilter !== ''" class="InfoBlock">
        <div>No Staff Members match your search</div>
      </div>
      <div v-else class="InfoBlock">
        <div v-if="ssoEnabled">You currently have SSO (Single Sign-On) enabled. Staff members are therefore managed externally. Please contact your site administrator for help.</div>
        <div v-else>You currently have no active Staff Members. Before you can invite a Staff Member, you need to create at least one Staff Group. Click the ‘Staff Groups’ tab above and then the ‘Create Staff Group’ button to begin adding Staff Groups.</div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {computed, Ref, ref} from 'vue'
import toast from '@/classes/ToastSingleton'
import Table from '@/components/Table.vue'
import type {RowAction} from '@/components/Table.vue'
import {isoToMomentInTenantTimezone} from '@/helpers/datetime.js'
import {useRouter} from 'vue-router'
import type {StaffGroup, StaffMember} from '@/components/Staff/types'
import {getConfigAuthMethods} from '@/helpers/access'
import {capitalise, fullDateTimeFormat} from '@/helpers/format'

export interface MembersGroupsJointRecord {
  staff_id: string
  staff_group_id: string
}
function getMemberTypes(member): Array<'Ticketure' | 'Okta'> {
  const res = []
  const [ssoProvider] = member.sso_identifier?.split('=') ?? []
  if (ssoProvider) {
    res.push(capitalise(ssoProvider))
  }

  if (member.password_set_at) {
    res.push('Ticketure')
  }

  return res
}

export function getMemberGroups(memberId: string, groups: Array<StaffGroup>, members_groups: Array<MembersGroupsJointRecord>): Array<any> {
  const jointRecords = members_groups.filter(i => i.staff_id === memberId)
  return jointRecords.map(r => groups.find(g => g.id === r.staff_group_id))
}

type MemberStatus = 'New' | 'Invited' | 'Active' | 'Suspended'

export function formatState(member: StaffMember): MemberStatus {
  if (member.state === 'suspended') {
    return 'Suspended'
  } else if (member.last_login_at != null) {
    return 'Active'
  } else if (member.invited_at != null) {
    return 'Invited'
  }

  return 'New'
}

export function getStatusClass(status: string): string {
  const normalised = status.trim().toLowerCase()
  const map = {
    active: 'success',
    invited: 'warning',
    suspended: 'critical'
  }

  return map[normalised] ?? ''
}

export function formatLastLoginOrCreated(member: StaffMember) {
  const status = formatState(member)
  let label = ''
  let time = ''
  if (status === 'New') {
    label = 'Created'
    time = member['created_at']
  } else if (status === 'Active') {
    label = 'Last login'
    time = member['last_login_at']
  } else if (status === 'Invited') {
    if (member.password_set_at) {
      label = 'Password set'
      time = member['password_set_at']
    } else {
      label = 'Last invited'
      time = member['invited_at']
    }
  }

  if (label === '') {
    return ''
  }

  return `
        <p>${label}</p>
        ${isoToMomentInTenantTimezone(time).format(fullDateTimeFormat)}
      `
}

interface StaffMemberWithGroup extends StaffMember {
  groups: any[]
}

function fetchStaffMembers(): Promise<StaffMemberWithGroup[]> {
  return window.APIService.get('staff?_limit=1000&_embed=staff_group,staff_group_staff,identity,identity.meta').then(rs => {
    const members = rs.staff._data
    const groups = rs.staff_group._data
    const members_groups = rs.staff_group_staff._data

    // Link members and groups
    for (const member of members) {
      member.groups = getMemberGroups(member.id, groups, members_groups)
      member.types = getMemberTypes(member)
    }
    return members
  })
}

export function sendEmail(member: StaffMember, type: 'reset-password' | 'invite' | 're-invite' = 'reset-password') {
  const data: any = {
    email: member.email,
    provider: 'staff'
  }

  window.APIService.post('password_reset/request', data).then(resp => {
    let msg = `A reset password email has successfully been sent to '${member.first_name} ${member.last_name}'.`
    if (type === 'invite') {
      msg = `An invitation has successfully been sent to '${member.first_name} ${member.last_name}'.`
      member.invited_at = new Date().toISOString()
    } else if (type === 're-invite') {
      msg = `An invitation has successfully been resent to '${member.first_name} ${member.last_name}'.`
    }
    toast.success(msg)
  })
}

function setState(member: StaffMember, state: 'active' | 'suspended', records: Ref<StaffMember[]>) {
  window.APIService.patch(`staff/${member.id}`, {state}).then(resp => {
    const action = state === 'active' ? 'activated' : 'suspended'
    const msg = `'${member.first_name} ${member.last_name}' has been successfully ${action}.`
    toast.success(msg)
    // Update the member's sate
    const record = records.value.find(r => r.id === member.id)
    if (record) {
      record.state = resp.staff._data[0].state
    }
  })
}

function makeRowActions(records: Ref<StaffMember[]>): Function {
  const router = useRouter()

  return (member: StaffMember): Array<RowAction> => {
    const actions = [
      {
        name: 'Edit',
        callback: r => router.push({name: 'edit-staff-member', params: {id: r.id}}),
        status: [] // Empty means all to all status
      },
      {
        name: 'Reset password',
        callback: r => sendEmail(r),
        status: ['Active']
      },
      {
        name: 'Send invitation',
        callback: r => sendEmail(r, 'invite'),
        status: ['New']
      },
      {
        name: 'Resend invitation',
        callback: r => sendEmail(r, 're-invite'),
        status: ['Invited']
      },
      {
        name: 'Suspend',
        callback: r => setState(r, 'suspended', records),
        status: ['Active']
      },
      {
        name: 'Restore',
        callback: r => setState(r, 'active', records),
        status: ['Suspended']
      }
    ]

    const status = formatState(member)

    return actions.filter(action => action.status.length === 0 || action.status.includes(status)).map(action => ({name: action.name, callback: action.callback}))
  }
}

export default {
  components: {Table},
  setup() {
    const keywords = ref('')
    const statusFilter = ref('')
    const ssoEnabled = ref(false)
    const records = ref([])
    const router = useRouter()

    fetchStaffMembers().then(members => {
      records.value = members
    })

    const rowActions = makeRowActions(records)

    const filteredRecords = computed(() => {
      return records.value.filter(r => {
        const name = `${r.first_name} ${r.last_name}`
        const status = formatState(r)
        return name.toLowerCase().includes(keywords.value.toLowerCase()) && (statusFilter.value.trim() === '' || statusFilter.value === status)
      })
    })

    const statusFilters = computed(() => {
      return Array.from(new Set(records.value.map(r => formatState(r))))
    })

    const isOktaSupported = getConfigAuthMethods().includes('okta_staff') || getConfigAuthMethods().includes('entra_staff')

    const displayFields = {
      name: 'Full name',
      groups: 'Staff group',
      ...(isOktaSupported ? {types: 'Type'} : {}),
      status: 'Status',
      time: ''
    }

    return {
      records,
      filteredRecords,
      statusFilters,
      keywords,
      statusFilter,
      ssoEnabled,
      displayFields,
      formatState,
      formatLastLoginOrCreated,
      rowActions,
      getStatusClass
    }
  }
}
</script>

<style lang="scss">
.StaffMembers {
  &__SearchWrapper {
    display: flex;
    justify-content: space-between;
  }

  .Table {
    thead {
      .col-name {
        padding: 0 8px 0 16px;
      }

      .col-groups,
      .col-types {
        padding: 0 8px;
      }

      .col-status {
        padding: 0 8px;
      }
    }

    tbody {
      td {
        padding: 11px 8px 14px;

        h3 {
          margin-bottom: -2px;
        }
      }
    }

    .email {
      display: block;
      font-size: 14px;
      line-height: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
    }

    .col-name {
      max-width: 285px;
      white-space: nowrap;
      padding: 11px 8px 14px 16px;
    }

    .col-groups,
    .col-types {
      max-width: 200px;
      padding: 11px 8px 14px;

      p {
        line-height: 18px;
        margin-bottom: 4px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .col-status {
      padding: 11px 8px 14px;

      .Status {
        width: 100%;
        text-align: center;
        padding: 0 10px;
      }
    }

    .col-time {
      max-width: 180px;
    }

    .timestamp {
      display: block;
      font-size: 12px;
      line-height: 16px;

      p {
        margin-bottom: 0;
      }
    }
  }
}
</style>
