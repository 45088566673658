import type {ErrorObject, AnySchema} from 'ajv'
import type {Evaluated, SourceCode} from 'ajv/dist/types'
import type {SchemaEnv} from 'ajv/dist/compile'
import Validator from '@old/schema/Validator'
import type {ConfigTemplate} from '@/config/templatesStore'
import {ConfigValue} from '@/api/config'

export function getValidator(template: ConfigTemplate) {
  // @see https://ajv.js.org/options.html
  const validator = Validator.getFor(template.schema, {coerceTypes: false}) as ValidateFunction
  return function (value: ConfigValue) {
    const errors = validate(validator, value)
    if (errors) {
      const {message, key} = errors[0]
      return `${key} ${message}`
    }
  }
}

/**
 * Copied from node_modules/ajv/lib/types/index.ts and simplified to be compatible with
 * CMS build's TypeScript configuration/version.
 */
interface ValidateFunction<T = unknown> {
  (this: any, data: any, dataCxt?: any): data is T
  errors?: null | ErrorObject[]
  evaluated?: Evaluated
  schema: AnySchema
  schemaEnv: SchemaEnv
  source?: SourceCode
}

export function validate(validator: ValidateFunction, data: ConfigValue) {
  const isValid = validator(data)
  if (!isValid) {
    return validator.errors.map(error => ({
      key: error.params.missingProperty ?? error.instancePath.substring(1).replace(/\/.+/, ''),
      message: error.message
    }))
  }
}
