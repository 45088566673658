<script>
import ddl from '@/components/DDL.vue'
import field from '@/components/Field/Field.vue'
import {filterLabels} from '../Class'
import {addCfg, addWatch} from '@/helpers/vModelPassThrough'

const labels = {}
Object.keys(filterLabels).forEach(k => {
  labels[k] = `${filterLabels[k]} (optional)`
})

export default addCfg({
  components: {
    ddl,
    field
  },
  props: {
    type: {
      type: String,
      required: true
    },
    options: {
      required: true
    },
    description: {
      type: String
    },
    multiple: {
      type: Boolean
    },
    optional: {
      type: Boolean
    }
  },
  async setup(props, {emit}) {
    const value = addWatch(props, emit)
    return {
      labels,
      value
    }
  }
})
</script>
<template>
  <div class="FieldWrapper ATFilter">
    <label v-if="labels[type]" v-shtml="labels[type]" />
    <ddl v-model="value" :options="options" :multiple="multiple" :searchable="false" :optional="optional" />
    <p v-if="description" v-shtml="description" class="description" />
  </div>
</template>
<style lang="scss">
/* https://github.com/sagalbot/vue-select/issues/294#issuecomment-1018461089 */
.ATFilter {
  .vs__search {
    height: 0;
    position: absolute;
  }
  &__audit_action,
  &__provider {
    width: 50% !important;
    flex-grow: unset !important;
  }
}
</style>
