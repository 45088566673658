// @cms-next src/services/config.ts

import {API} from '@/api/API'
import type {ConfigTemplateEntity, ConfigValueEntity, ConfigValue} from '@/api/config'
import type {ResourceName} from '@/api/response'

//
// Helper functions to wrap the Config API.
//

export async function fetchTemplates(resource: ResourceName) {
  const response = await API.get<'config_template'>('config/template', {query: {resource}})
  return response.config_template._data
}

export async function fetchConfigValues(resource: ResourceName, resourceId: string) {
  return resource === 'portal' ? await fetchPortalConfigValues() : await fetchConfigValuesByID(resourceId)
}

export async function fetchConfigValue(resource: ResourceName, resourceId: string, namespace: string, name: string): Promise<ConfigValue | undefined> {
  const response = resource == 'portal' ? await fetchPortalConfigValues(namespace, name) : await fetchConfigValuesByID(resourceId, namespace, name)
  return response[0]?.value
}

async function fetchPortalConfigValues(namespace?: string, name?: string) {
  const query = {resource: 'portal', namespace, name}
  const response = await API.get<'config'>('config', {query})
  return response.config._data
}

async function fetchConfigValuesByID(id: string, namespace?: string, name?: string) {
  // Prevent mistakes in UI code from showing/setting API values for the wrong entities;
  // E.g. Setting seller config for the wrong seller.
  if (!id) throw new Error('Cannot fetch config values without a resource ID.')
  const query = {resource_id: id, name, namespace}
  const response = await API.get<'config'>('config', {query})
  return response.config._data
}

export async function updateValue(resourceId: string, template: ConfigTemplateEntity, value: ConfigValue) {
  const body: ConfigValueEntity = {
    value: value,
    resource_id: resourceId,
    resource: template.resource,
    namespace: template.namespace,
    name: template.name
  }
  return API.post('config', {body})
}

export async function deleteValue(resourceId: string, template: ConfigTemplateEntity) {
  return updateValue(resourceId, template, null)
}
