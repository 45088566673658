// Abstract class for managing sets of interdependent DDLs.
// The DDLs share the same base set of options, which is dynamically/reactively reduced by the options already selected in all the DDLs
// (except where certain specified options are available for selection in multiple DDLs).
import {computed, watch} from 'vue'
import Base from '../../classes/Base'

class DDLSetManager extends Base {
  // Abstract. Returns the baseline set of all available options for the DDLs.
  // getBaseSet() {}

  constructor(items) {
    super()
    // Array holding the collection of items.
    this.setReactive('items', items || [])
    this.setReactive('options', [])
    const errors = []
    // An array of errors is to be maintained, each element corresponds to one in the items array:
    this.dr.items.forEach(() => errors.push(false))
    this.setReactive('errors', errors)
    this.dr.items.manager = this // loopback link, used by the Validator to access the error array.
    this.setShallowReactive(
      'isReady',
      computed(() => this.dr.items.length === this.dr.options.length && this.dr.options.length > 0)
    )
  }

  lastInitStep() {
    this.setShallowReactive(
      'availableOptions',
      computed(() => new Set([...window.subtractSets(this.getBaseSet(), new Set(this.dr.items)), ...this.getAlwaysAvailableOptions()]))
    )
    this.buildOptions() // build options based on any initially provided values
    // Any change in the values array triggers full re-calculation of the options for ALL the values' DDLs
    // because what is available for selection pretty much depends on what is selected (no 2 DDLs can have the same value selected)
    watch(this.dr.items, () => this.buildOptions())
  }

  buildOptions() {
    this.trigger('itemschange')
    const ops = []
    if (this.dr.items.length) {
      // All the values that have not yet been selected form the option base for all DDLs:
      const baseOptions = this.sr.availableOptions.value
      this.dr.items.forEach(v => {
        const vOps = Array.from(baseOptions)
        if (v && !baseOptions.has(v) && this.getBaseSet().has(v)) {
          vOps.push(v)
        }
        const DDLOps = []
        vOps.forEach(code =>
          DDLOps.push({
            code,
            label: this.getNameByCode ? this.getNameByCode(code) : code
          })
        )
        DDLOps.sort((a, b) => a.label.localeCompare(b.label))
        ops.push(DDLOps)
      })
    }
    this.setReactive('options', ops)
  }

  // To be re-defined in derived classes if needed
  getAlwaysAvailableOptions() {
    return new Set()
  }

  getNextAvailableValue() {
    const [v] = this.sr.availableOptions.value
    return v
  }

  addValue() {
    const v = this.getNextAvailableValue()
    if (v !== undefined) {
      this.dr.items.push(v)
      this.dr.errors.push(false)
    }
    return v
  }

  removeValueAt(i) {
    const value = this.dr.items[i]
    this.dr.items.splice(i, 1)
    this.dr.errors.splice(i, 1)
    this.onAfterRemove(value)
  }

  onAfterRemove() {
    if (!this.dr.items.length) {
      this.trigger('empty')
    }
  }
}

export default DDLSetManager
