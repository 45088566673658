import {computed, nextTick} from 'vue'
import DDLSetManager from './DDLSetManager'
import TriggerOrAction from './TriggerOrAction.vue'
import {randomString} from '@/helpers/format'
import {isEmpty, isEmptyObject} from '@/helpers/comparison'

// Abstract
class Manager extends DDLSetManager {
  // Some item (trigger/action) types may be available to be selected multiple times
  // while the rest become unavailable once selected.
  // Here we list those that are available multiple times:
  static ALWAYS_AVAILABLE = []

  // Some items are held deep within nested objects, other sit directly on the root message rule object.
  // List those here:
  static USE_ROOT_DATA_OBJECT_FOR = []

  getHolder() {
    const hk = this.constructor.HOLDER
    if (hk && !this.baseHolder[hk]) {
      this.baseHolder[hk] = {}
    }
    return hk ? this.baseHolder[hk] : this.baseHolder
  }

  // baseHolder is already reactive
  constructor(baseHolder) {
    super()
    this.baseHolder = baseHolder

    // Hydrate/populate items based on the provided object (used when editing existing message rules):
    this.readData()

    this.setShallowReactive({
      // Each item's data is held by an object. This can be the root message rule object, or any part of it, or a
      // detached object which is then synchronised with the message rule object.
      // Here we keep the register of those holders, each respectively belonging to the item at the same index:
      holders: [this.ensureHolderId({})],

      // Whether the "AND" button is available. It will be unless there is an unselected item type, or the options have exhausted:
      showAnd: computed(() => this.dr.items.filter(el => el === '').length === 0 && this.sr.availableOptions.value.size > 0)
    })
    this.lastInitStep()

    // If empty/new object, start with one empty value so that one item is rendered with no type selected:
    if (!this.dr.items.length) {
      this.add()
    }
  }

  getItemComponent() {
    // Derived classes might have a different idea what to return here:
    return TriggerOrAction
  }

  ensureHolderId(holder) {
    if (!holder._id) {
      holder._id = randomString()
    }
    return holder
  }

  getAlwaysAvailableOptions() {
    return new Set(this.constructor.ALWAYS_AVAILABLE)
  }

  getBaseSet() {
    return new Set(Array.from(this.constructor.OPTIONS.keys()))
  }

  add(item) {
    this.dr.items.push(item || (this.constructor.useNonEmptyDefault ? this.getNextAvailableValue() : ''))
  }

  getNameByCode(code) {
    return this.constructor.OPTIONS.get(code)
  }

  // Extending this method as we also have to build references to the data holding objects
  // along with the value options built in the parent class:
  buildOptions() {
    super.buildOptions()
    // What the holding object is depends on the type (and, possibly, ordinal number) of the item.
    if (this.dr.items.length) {
      // Rewrite the existing array's content instead of assigning a new array:
      this.sr.holders.splice(0, this.sr.holders.length, ...this.makeHolders())
    }
  }

  onTypeChange(index, newType, oldType) {
    const holder = this.getHolder()
    nextTick(() => {
      if ((oldType && holder[oldType] && isEmpty(holder[oldType])) || isEmptyObject(holder[oldType])) {
        delete holder[oldType]
      }
    })
    if (newType) {
      // This data may be required by other handlers which will be triggered right after this one
      // e.g. a watcher of the whole array which the type that has just changed is an element of.
      // So we save it:
      this.lastTypeChange = [...arguments]
    }
  }

  onEmpty(i, itemType) {
    if (itemType) {
      const holder = this.getHolder()
      if (holder[itemType]) {
        delete holder[itemType]
      }
    }
    if (this.dr.items.length > 1) {
      if (this.lastTypeChange) {
        // The type value has changed (vs the trigger/action having been emptied).
        // Do not remove the value.
      } else {
        this.removeValueAt(i)
      }
    } else {
      // Unselect the item type DDL
      this.dr.items[i] = ''
    }
  }
}

export default Manager
