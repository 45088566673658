<script>
import {ref, computed, nextTick, reactive, onMounted, onUnmounted, shallowReactive, watch} from 'vue'
import moment from 'moment'
import GETCache from '@/classes/GETCache'
import LeaveWarning from '@/classes/LeaveWarning'
import {formatMoney} from '@/helpers/money'
import {isSame, isEmptyObject, objDiff} from '@/helpers/comparison'
import DDL from '@/components/DDL.vue'
import DaC from '@/components/DisplayAndCopy.vue'
import CopyID from '@/components/CopyID.vue'
import {useRouter} from 'vue-router'
import field from '../Field/HeldProperty.vue'
import Node from './Node'
import {computedError, setError, cleanErrors} from '@/helpers/ErrorHolder'
import AddMatrix from './Modals/AddMatrix.vue'
import Tier from './Modals/Tier.vue'
import Row from './Modals/Row.vue'
import EditPrice from './Modals/EditPrice.vue'
import PriceChange from './Modals/PriceChange.vue'
import ScheduleRule from './Modals/ScheduleRule.vue'
import ScheduleItem from './ScheduleItem.vue'
import Validator from '@old/schema/Validator'
import Sorter from '@old/Sorter'
import PhaseOrder from './PhaseOrder'
import draggable from 'vuedraggable'
import schema_internal from '@/schema/pricelib/internal'
import schema_external from '@/schema/pricelib/external'
import isReadOnly from './IsReadOnly'
import isExternalEnabled from './IsExternalEnabled'
import modalSingleton from '@/classes/ModalSingleton'
import {copy, stripUnderscores, stripByFirstCharacter, dClone, arrayRemove, arrayMove} from '@/helpers/format'

const externalMattixHelpText = `An external price matrix is used to set up your price rows (eg. Adult, Child) so that you can match them with a 3rd party pricing system.`

const phaseSorter = new Sorter([
  // Sort by start_date ascending. Put the default/base phase (no start_date) always the first:
  new PhaseOrder('start_date')
])

const modalCmps = {
  AddMatrix,
  Tier,
  Row,
  EditPrice,
  ScheduleRule,
  PriceChange
}

const grandTypeOptions = [
  {
    code: 'internal_default',
    label: 'Internal'
  },
  {
    code: 'external_default',
    label: 'External'
  }
]
const defaultGrandType = grandTypeOptions[0].code
const grandTypeMap = new Map(grandTypeOptions.map(o => [o.code, o.label]))

const makeValidator = (grandType, type) => {
  if (!grandType) {
    grandType = 'internal_default'
  }
  return Validator.get(
    grandType === 'internal_default' ? schema_internal : schema_external,
    `/tix/price_library/${grandType.split('_')[0]}#/$defs/${type}`,

    // Error setter
    ed => {
      // "ed" for error details
      if (ed.key) {
        if (ed.key === 'tiers' && ed.message === 'Must NOT have fewer than 1 items') {
          ed.message = 'A price matrix is required'
        }
        setError(ed.holder, ed.key, ed.message)
      }
    },

    // Error cleaner. This function is ultimately passed to the strip function as the 2nd argument.
    cleanErrors
  )
}

const stripIds = data => stripByFirstCharacter(data, 'id')
const stripAll = data => {
  stripUnderscores(data)
  stripIds(data)
}

const calcMatrix = pl => {
  const m = dClone(copy(pl.config, null, ['tiers', 'rows', 'global_post_modifier']))
  stripAll(m)
  return Node.calcMatrix(m)
}

const loadPhaseMatrix = (plID, phase) => window.APIService.get(`price_library/${plID}/matrix?phase=${phase}`)

const formatPhaseDate = str => moment(str).format('ddd, MMM D, YYYY')

const defaultSchedule = {
  name: 'Default',
  tier_pickers: [
    {
      adjustor: 'absolute',
      offset: 0,
      condition: null
    }
  ],
  stop: false
}

const phaseKey = p => `${p.name}:${p.start_date}`

const makeDefaultConfig = grandType => {
  if (!grandType) {
    grandType = defaultGrandType
  }
  const dc = {
    type: grandType,
    rows: []
  }
  if (grandType === 'internal_default') {
    Object.assign(dc, {
      tiers: [],
      schedules: [dClone(defaultSchedule)],
      phases: [{name: 'Base'}]
    })
  }
  return dc
}

export default {
  props: {
    // This is supplied when editing a price library. Otherwise we're creating a new one.
    id: String
  },
  async setup(props) {
    const router = useRouter()
    const buttons = ref(null)
    const name = ref(null)
    const type = ref(defaultGrandType)
    const isExternal = computed(() => type.value === 'external_default')
    if (!props.id) {
      onMounted(() => name.value.$el.querySelector('input').focus())
    }
    const currentModalKey = ref(null)
    const currentModalOpen = ref(false)
    const currentIndex = ref(null)
    const onMouseDown = () => {
      if (!currentModalOpen.value) {
        // Unselect focused cell on pushing mouse down anywhere
        currentIndex.value = null
      }
    }
    document.addEventListener('mousedown', onMouseDown)
    onUnmounted(() => {
      document.removeEventListener('mousedown', onMouseDown)
      LeaveWarning.setOn(false)
    })
    await vue1AppSellerPromise
    const readOnly = isReadOnly()
    const cancel = () =>
      router.push({
        name: 'pricelib',
        query: {
          seller: theApp.seller.id
        }
      })
    // holds a copy of the un-edited object to later find out what has changed
    let original
    // To hold the price library object being edited (reactive)
    let pl
    let currentPhaseIndex = ref(0)

    const getNode = indices => {
      const pi = indices.pi === undefined ? currentPhaseIndex.value : indices.pi
      return pl.config.rows[indices.ri].phases[pi].tier_modifiers[indices.ti]
    }
    const getCalc = indices => getNode(indices)._calc
    const setCalc = (ri, ti, pi, v) => {
      if (ri >= 0 && ti >= 0 && pi >= 0) {
        const node = getNode({ri, ti, pi})
        if (node.fn) {
          node._calc = v
        }
      }
    }
    // This Set keeps the objects from pl.config.phases.
    // We keep the objects rather than their indices because they can be re-ordered.
    const unsavedPhases = new Set()
    // This Set keeps the indices which were used in the ?phase query param when requesting matrices.
    // Those indices will stay put at least until the PL is saved, so we can use them.
    const loadedPhaseIndices = new Set()

    // A Map mapping saved phase objects to their original indices
    // Needed to know which phase has which index in the API (as the phases may have been re-ordered in the browser)
    let savedIndices
    let phaseDDLValue
    let hasNonDefaultPhases
    let phasesDDLOptions
    let setPhase
    let currentPhaseObject
    let isCurrentPhase = ref(true)

    const translateIndex = (type, apiIndex) => pl.config[type].indexOf(savedIndices[`i-${type}`].get(apiIndex))
    const applyPhaseMatrix = (matrix, phaseIndex) => {
      matrix = matrix.price_matrix._data[0]
      // The "phaseIndex" argument is only supplied when loading matrices separately (vs. at the PL initial load).
      // We supply it because the order of phases in the UI may have changed, and so we can't rely on the API's matrix.phase value to be correct when applying the matrix:
      if (!phaseIndex) {
        phaseIndex = matrix.phase
      }
      matrix.matrix.forEach((tier, ti) => tier.forEach((cell, ri) => setCalc(translateIndex('rows', ri), translateIndex('tiers', ti), translateIndex('phases', phaseIndex), cell.price_computed)))
      loadedPhaseIndices.add(phaseIndex)
      return matrix.phase
    }

    const setupPhases = (pIndex = 0) => {
      hasNonDefaultPhases = computed(() => pl.config.phases && pl.config.phases.length > 1)
      phasesDDLOptions = computed(() => {
        const list = []
        let i = pIndex
        for (; i < pl.config.phases.length; i++) {
          // Any phases that are before the current one (as reported by the API) are to be excluded from the list.
          // The user no longer needs to know about their existence.
          // See https://tixtrack.atlassian.net/browse/TIC-1645?focusedCommentId=84960
          // TODO: what happens when a new phase kicks in when a PL is opened in the browser?
          list.push({
            code: phaseKey(pl.config.phases[i]),
            label: i === pIndex ? 'Current' : pl.config.phases[i].name
          })
        }
        list.push({
          code: 'add',
          label: '+ Add price change'
        })
        return list
      })
      // The "phaseDDLValue" ref will be bound to the DDL. When "Add price change" is selected
      // (which could have been a separate button, but is an option within the DDL for design purposes),
      // "phaseDDLValue" ref value will briefly change from the one representing the currently selected phase to "add".
      // Thus, we need a separate variable to keep the currently selected phase in it,
      // so that we can revert the ref back to it once the "add" value has done its job (i.e. opened the modal dialog for adding a new phase).
      setPhase = ref(phasesDDLOptions.value[0].code)
      currentPhaseObject = computed(() => (pl.config.phases ? pl.config.phases.find(p => phaseKey(p) === setPhase.value) : undefined))
      isCurrentPhase = computed(() => isExternal.value || (currentPhaseObject.value && currentPhaseObject.value === pl.config.phases[pIndex]))
      currentPhaseIndex = computed(() => (currentPhaseObject.value && pl.config.phases ? pl.config.phases.indexOf(currentPhaseObject.value) : 0))
      phaseDDLValue = ref(setPhase.value)
      watch(phaseDDLValue, ps => {
        if (ps === 'add') {
          data.phases = pl.config.phases
          currentModalKey.value = 'PriceChange'
          // Switch it back right away so that the switching can be used again (we don't want to keep the "Add price change" option selected):
          phaseDDLValue.value = setPhase.value
        } else if (setPhase.value !== ps) {
          setPhase.value = ps
        }
      })
    }

    let originalPhaseDDLValue
    let currentlyActivePhaseIndex = 0

    if (props.id) {
      // Editing an existing price library
      const r = await APIService.get(`price_library/${props.id}?_matrix`)
      const rawData = r.price_library._data[0]
      type.value = rawData.config.type
      delete rawData.config.type
      ;['id', 'portal_id', 'seller_id'].forEach(k => delete rawData[k])
      if (isExternal.value) {
        // Get rid of certain fields which are automatically populated by the API and
        // are only a hindrance here (the API does not even accept some of them back on PATCH!)
        ;['phases', 'schedules', 'tiers'].forEach(k => delete rawData.config[k])
        rawData.config.rows.forEach(r => r.phases.forEach(p => delete p.tier_modifiers))
      } else {
        // Fix any remains of the previous lack of support of the name property on schedules:
        rawData.config.schedules.forEach(s => {
          if (!s.name) {
            s.name = 'Default'
          }
          // We now use date_ranges instead, delete the deprecated:
          delete s.date_start
          delete s.date_end
        })
        // Ensure the default/base phase is explicitly present (we need the phase object to track matrix loading/caching)
        if (!rawData.config.phases) {
          rawData.config.phases = []
        }
        if (!rawData.config.phases.length) {
          rawData.config.phases.push({name: 'Base'})
        }
      }
      original = dClone(rawData)
      pl = reactive(rawData)
      if (!isExternal.value) {
        // As rows, tiers and phases get added/deleted/moved locally, their indices change.
        // Yet we still may need to receive additional data from the API (e.g. pricing for a specified phase) which has no idea of our changes yet.
        // Thus, we have to save the initial indices so that we later could translate the API indicies to our local ones:
        savedIndices = {
          phases: new Map(),
          rows: new Map(),
          tiers: new Map()
        }
        const savedTypes = Object.keys(savedIndices)
        savedTypes.forEach(k => {
          pl.config[k].forEach((item, i) => savedIndices[k].set(item, i))
          // Now setup inverted maps: // https://stackoverflow.com/questions/56550463/invert-a-map-object
          savedIndices[`i-${k}`] = new Map(Array.from(savedIndices[k], a => a.reverse()))
        })
        currentlyActivePhaseIndex = applyPhaseMatrix(r)
        setupPhases(currentlyActivePhaseIndex)
        // Set the phase DDL value to the phase which the API has reported to be the current one.
        // Keep it in case unsaved PL changes need to ve reverted.
        originalPhaseDDLValue = phaseKey(pl.config.phases[currentlyActivePhaseIndex])
        phaseDDLValue.value = originalPhaseDDLValue
      }
    } else {
      // Creating a new one
      pl = reactive({
        name: '',
        description: '',
        config: makeDefaultConfig()
      })
      setupPhases()
    }

    // ==== PHASES =====
    // If needed, load/apply matrices when phases are switched.
    // Nothing needs to be done for new PLs or newly added (unsaved) phases because the matrix data
    // is just copied from the previously selected phase.
    const matrixLoading = ref(false)
    let suspendCurrentPhaseObjectWatcher = false
    if (props.id && currentPhaseObject) {
      watch(currentPhaseObject, async phaseObject => {
        if (phaseObject) {
          const theIndex = currentPhaseIndex.value
          if (!suspendCurrentPhaseObjectWatcher && !unsavedPhases.has(phaseObject) && !loadedPhaseIndices.has(theIndex)) {
            matrixLoading.value = true
            const r = await loadPhaseMatrix(props.id, savedIndices.phases.get(phaseObject))
            applyPhaseMatrix(r, theIndex)
            matrixLoading.value = false
          }
        }
        if (suspendCurrentPhaseObjectWatcher) {
          suspendCurrentPhaseObjectWatcher = false
        }
      })
    }
    const phaseInfo = computed(() => {
      const cpi = currentPhaseIndex.value
      const lastPhaseIndex = pl.config.phases.length - 1
      if (currentlyActivePhaseIndex === cpi) {
        if (cpi === lastPhaseIndex) {
          // The currently active phase is the last one. No phases to come into effect after it.
          // Hence, no info message to show
          return false
        } else {
          // Future phases exist
          const futurePhases = lastPhaseIndex - cpi
          const nextPhase = pl.config.phases[cpi + 1]
          return `<p>You have ${futurePhases} scheduled price change${futurePhases > 1 ? 's' : ''}.</p>
  <p>Your next price change is <strong>${nextPhase.name}</strong> and is scheduled to be enabled on ${formatPhaseDate(nextPhase.start_date)}.</p>`
        }
      }
      return `<p>This price matrix is scheduled to be enabled on ${formatPhaseDate(pl.config.phases[cpi].start_date)}</p>`
    })
    const removePhase = () => {
      const pi = currentPhaseIndex.value
      modalSingleton.showWith({
        onBeforeSubmit: () => {
          arrayRemove(pl.config.phases, currentPhaseObject.value)
          phaseDDLValue.value = phasesDDLOptions.value[0].code
          pl.config.rows.forEach(r => r.phases.splice(pi, 1))
        },
        header: 'Remove Price Change',
        submitText: 'Confirm',
        body: `<p>Are you sure you want to remove the price change <strong>${currentPhaseObject.value.name}</strong>?</p>
              <p>This action can not be reversed, so please ensure consideration when confirming.</p>`
      })
    }
    const header = computed(() => {
      if (isExternal.value) {
        return 'Price Matrix'
      }
      const cpi = currentPhaseIndex.value
      const suff = cpi > 0 && currentlyActivePhaseIndex === cpi ? '<span> / Current</span>' : ''
      const name = cpi > 0 ? currentPhaseObject.value.name : 'Current'
      return `Price Matrix — ${name}${suff}`
    })
    const editPhase = () => {
      const ph = currentPhaseObject.value
      Object.assign(data, ph)
      if (!ph.start_date) {
        data.isDefault = true
      }
      data.phases = pl.config.phases
      data.theIndex = currentPhaseIndex.value
      currentModalKey.value = 'PriceChange'
    }
    // ==== PHASES =====

    // Save untouched copy to track if there are any changes (to decide whether to show the leave warning)
    const untouched = JSON.stringify(pl)
    const hasChanges = computed(() => JSON.stringify(pl) !== untouched)
    watch(hasChanges, changes => LeaveWarning.setOn(changes))

    // We need to track how the tier indices change when tiers are moved or removed, so keep unmodified copy handy:
    let tiersCopy
    let saveTiersCopy
    if (!isExternal.value) {
      saveTiersCopy = () => {
        tiersCopy = dClone(pl.config.tiers)
      }
      saveTiersCopy()
    }
    const isOneDefaultSchedule = computed(() => !pl.config.schedules || (pl.config.schedules.length === 1 && isSame(pl.config.schedules[0], defaultSchedule)))
    // Schedule conditions may absolutely reference tiers by their indices.
    // These indices shift when tiers are moved/removed, so they need to be updated:
    const updateAbsoluteScheduleConditionsOnTierMove = () => {
      if (!isOneDefaultSchedule.value) {
        pl.config.schedules.forEach(s =>
          s.tier_pickers
            .filter(tp => tp.adjustor === 'absolute')
            .forEach(tp => {
              tp.offset = pl.config.tiers.indexOf(tiersCopy[tp.offset])
              if (tp.offset < 0) {
                tp.offset = 0
              }
            })
        )
      }
      saveTiersCopy()
    }
    const negativeCalcPricesPresent = computed(() => {
      if (isExternal.value) {
        return false
      }
      let ri = 0
      let ti
      for (; ri < pl.config.rows.length; ri++) {
        ti = 0
        for (; ti < pl.config.tiers.length; ti++) {
          const node = getNode({ri, ti})
          if (parseFloat(node._calc) < 0) {
            return true
          }
        }
      }
      return false
    })
    let validator = props.id ? makeValidator(type.value, 'modify') : null
    if (!props.id) {
      watch(
        isExternal,
        () => {
          validator = makeValidator(type.value, 'create')
          pl.config = makeDefaultConfig(type.value)
        },
        {immediate: true}
      )
    }
    const validate = () => {
      const candidateData = dClone(pl)
      stripUnderscores(candidateData)
      // Remove  schedules' time_of_day_start and time_of_day_end if they are the same:
      if (candidateData.config.schedules) {
        candidateData.config.schedules.forEach(s => {
          if (s.time_of_day_start === s.time_of_day_end) {
            delete s.time_of_day_start
            delete s.time_of_day_end
          }
        })
      }
      const valid = validator(candidateData, pl) && !negativeCalcPricesPresent.value
      return {
        valid,
        candidateData
      }
    }
    const headPrefix = props.id ? 'Edit' : 'Create'
    // We have to render at least 5 tier columns at all times. Fill the gap with stubs:
    const stubTiers = computed(() => {
      const stubs = []
      const n = 5 - pl.config.tiers.length
      if (n > 0) {
        while (stubs.length < n) {
          stubs.push({})
        }
      }
      return stubs
    })
    const data = shallowReactive({})
    watch(currentModalKey, v => {
      if (v) {
        // In this UI, there can always be just one modal window open at any given time.
        // When we change the key, we always want to open the modal, so:
        currentModalOpen.value = true
        data.isExternal = isExternal.value
        if (v === 'Tier') {
          data.tierInfo = {
            isOneDefaultSchedule: isOneDefaultSchedule.value,
            tiers: pl.config.tiers, // required to enforce the uniqueness of tier names
            schedules: pl.config.schedules, // required to check if tier can be removed
            index: currentIndex.value // required to check if tier can be removed
          }
        }
      }
    })
    watch(currentModalOpen, v => {
      if (!v) {
        // Reset the key back to null upon closing modals so that when the same modal is requested to open again,
        // the above watcher works:
        currentModalKey.value = null
        delete data.tierInfo
      }
    })
    const getNominal = ri => pl.config.rows[ri].phases[currentPhaseIndex.value].nominal
    const modalKeys = Object.keys(modalCmps)

    // Modals' submit handlers. They're defined here and not inside the modals
    // because access to the whole price lib config is required (and the modals should know nothing about it)
    const onSubmitted = {
      AddMatrix() {
        // Put the provided data where it belongs:
        const row = {
          name: data.rowName,
          phases: [
            {
              nominal: data.nominalPrice
            }
          ]
        }
        if (!isExternal.value) {
          row.phases[0].tier_modifiers = [Node.nominalCell]
        }
        pl.config.rows.push(row)
        if (!isExternal.value) {
          pl.config.tiers.push(data.tierName)
          saveTiersCopy()
        }
        validate() // Just to cleanup matrix error messages, if any
      },
      Tier(ctx) {
        if (ctx.isEdit) {
          pl.config.tiers[currentIndex.value] = data.name
        } else {
          // New tier
          pl.config.tiers.push(data.name)
          saveTiersCopy()
          pl.config.rows.forEach(row => row.phases.forEach(rph => rph.tier_modifiers.push(Node.nominalCell)))
        }
      },
      Row(ctx) {
        if (ctx.isEdit) {
          const row = pl.config.rows[currentIndex.value]
          row.name = data.name
          row.phases[currentPhaseIndex.value].nominal = data.nominalPrice
        } else {
          const row = {
            name: data.name,
            phases: []
          }
          const phases = pl.config.phases || [true] // provide one item for the default/base phase if there is no "phases" array on the config
          const newPhase = {
            nominal: data.nominalPrice
          }
          if (!isExternal.value) {
            newPhase.tier_modifiers = []
          }
          phases.forEach(ph => row.phases.push(dClone(newPhase)))
          if (!isExternal.value) {
            pl.config.tiers.forEach(t => row.phases.forEach(rph => rph.tier_modifiers.push(Node.nominalCell)))
          }
          pl.config.rows.push(row)
        }
      },
      EditPrice() {
        const node = data.node.export()
        const i = currentIndex.value
        const row = pl.config.rows[i.ri]
        const tModifiers = row.phases[currentPhaseIndex.value].tier_modifiers
        if (!isSame(tModifiers[i.ti], node)) {
          tModifiers[i.ti] = node
        }
        if (data.node.isCalculated()) {
          setCalc(i.ri, i.ti, currentPhaseIndex.value, data.node.sr.calculatedRaw)
        } else {
          delete getNode(i)._calc
        }
      },
      ScheduleRule() {
        delete data.schedule.isDirty
        if (data.isAdd && !isOneDefaultSchedule.value) {
          pl.config.schedules.push(data.schedule)
        } else {
          pl.config.schedules[data.si] = data.schedule
        }
      },
      PriceChange(ctx) {
        const key = phaseKey(data)
        const phaseData = copy(data, null, ['name', 'start_date'])
        const oldIndex = currentPhaseIndex.value
        let currentPhaseObjectSaved
        if (ctx.isEdit) {
          currentPhaseObjectSaved = currentPhaseObject.value
          Object.assign(currentPhaseObjectSaved, phaseData)
        } else {
          pl.config.phases.push(phaseData)
          // We can't just do unsavedPhases.add(phaseData) because Vue does some transformation
          // to the object for the array being reactive, so we do this instead:
          unsavedPhases.add(pl.config.phases[pl.config.phases.length - 1])
        }
        phaseSorter.sort(pl.config.phases)
        // Now it's time to manage the row phases accordingly.
        if (ctx.isEdit) {
          const newIndex = pl.config.phases.indexOf(currentPhaseObjectSaved)
          if (newIndex !== oldIndex) {
            // The phase being edited has changed its index.
            // Move the corresponding row phases accordingly:
            pl.config.rows.forEach(r => arrayMove(r.phases, oldIndex, newIndex))
          }
        } else {
          const insertedPhaseIndex = pl.config.phases.indexOf(phaseData)
          // Insert new row phases where the new phase has been inserted at:
          pl.config.rows.forEach(r => r.phases.splice(insertedPhaseIndex, 0, dClone(r.phases[oldIndex])))
        }
        phaseDDLValue.value = key
        setPhase.value = key
      }
    }
    const onClosed = submitted =>
      nextTick(() => {
        if (!submitted) {
          currentIndex.value = null
        }
        Object.keys(data).forEach(k => {
          delete data[k]
        })
      })
    const onDelete = k => {
      const plural = `${k.toLocaleLowerCase()}s`
      if (pl.config[plural] && pl.config[plural].length > 1) {
        arrayRemove(pl.config[plural], pl.config[plural][currentIndex.value])
        if (k === 'Tier') {
          pl.config.rows.forEach(r => r.phases.forEach(ph => arrayRemove(ph.tier_modifiers, ph.tier_modifiers[currentIndex.value])))
          // Delete any schedules' time pickers absolutely referencing no longer existing tier. TODO: ask the user
          pl.config.schedules.forEach(s => {
            s.tier_pickers.filter(tp => tp.adjustor === 'absolute' && tp.offset === currentIndex.value).forEach(tr => arrayRemove(s.tier_pickers, tr))
            if (!s.tier_pickers.length) {
              // Can't be empty. Insert default one:
              s.tier_pickers.push(dClone(defaultSchedule.tier_pickers[0]))
            }
          })
          updateAbsoluteScheduleConditionsOnTierMove()
        }
      }
      currentModalOpen.value = false
      delete data.name
      currentIndex.value = null
    }
    const ScrollTDRowSpan = computed(() => {
      let n = pl.config.rows.length + 1
      if (n === 1) {
        n++
      }
      return n
    })
    const priceMatrixExists = computed(() => pl.config.rows.length > 0)
    const notStretched = computed(() => pl.config.tiers && pl.config.tiers.length <= 5)
    // Indices start from 0
    const getCellClass = indices => {
      const node = getNode(indices)
      let cls = 'PriceType'
      if (currentIndex.value && currentIndex.value.ri === indices.ri && currentIndex.value.ti === indices.ti) {
        cls += ' PriceType__Focused'
      }
      if (getCalc(indices) < 0) {
        cls += ' PriceType__Negative'
      }
      if (node.ref === 'PriceUnavailable') {
        return cls
      }
      return `${cls} PriceType__${node.ref ? 'Nominal' : node.fn ? 'Calculated' : 'Fixed'}`
    }
    const getCellContent = indices => {
      const node = getNode(indices)
      const html = node.ref === 'PriceUnavailable' ? 'N/A' : formatMoney(node.ref ? getNominal(indices.ri) : node.decimal || getCalc(indices))
      return `<div></div>${html}` // div for the blue border when cell is in focus; see CSS.
    }
    const fm = formatMoney
    const onCellClick = indices => {
      data.node = Node.get(getNominal(indices.ri), getNode(indices), getCalc(indices))
      data.row = pl.config.rows[indices.ri].name
      data.tier = pl.config.tiers[indices.ti]
      currentModalKey.value = 'EditPrice'
      currentIndex.value = indices
    }
    const onTierClick = ti => {
      data.name = pl.config.tiers[ti]
      data.isCurrentPhase = isCurrentPhase.value
      currentModalKey.value = 'Tier'
      currentIndex.value = ti
    }
    const onRowClick = ri => {
      data.name = pl.config.rows[ri].name
      data.id = pl.config.rows[ri].id
      data.moreThanOneRow = pl.config.rows.length > 1
      data.isCurrentPhase = !isExternal.value || isCurrentPhase.value
      data.nominalPrice = pl.config.rows[ri].phases[currentPhaseIndex.value].nominal
      if (!isExternal.value) {
        // Are there any calculated cells in this row?
        // If there are any, they will need to be re-calculated upon editing the nominal price.
        const allCells = pl.config.rows[ri].phases[currentPhaseIndex.value].tier_modifiers
        let cells = allCells.filter(c => Boolean(c.fn))
        if (cells.length) {
          // We won't POST all the cells to the matrix endpoint — only the calculated ones.
          // So, unless all the cells in the row are calculated, the tier indices in the returned matrix will not match the original tier indices.
          // Let's remember who is who:
          const tierIndexMap = new Map()
          cells.forEach((c, calcIndex) => tierIndexMap.set(calcIndex, allCells.indexOf(c)))
          cells = dClone(cells)
          stripAll(cells)
          data.calcPromiseMaker = async newNominal => {
            const matrix = {
              tiers: cells.map((c, i) => `T${i}`),
              rows: [
                {
                  name: 'row',
                  phases: [
                    {
                      nominal: newNominal,
                      tier_modifiers: cells
                    }
                  ]
                }
              ]
            }
            if (pl.config.global_post_modifier) {
              matrix.global_post_modifier = global_post_modifier
            }
            const result = await Node.calcMatrix(matrix)
            // Lay out the re-calculated prices:
            result.price_matrix._data[0].matrix.forEach((tier, ti) => setCalc(ri, tierIndexMap.get(ti), currentPhaseIndex.value, tier[0].price_computed))
          }
        }
      }
      currentModalKey.value = 'Row'
      currentIndex.value = ri
    }
    const schedules = computed(() => (isOneDefaultSchedule.value ? [] : pl.config.schedules.filter(s => s.isDirty === undefined)))
    const editSchedule = (arg, isAdd) => {
      data.isAdd = Boolean(isAdd)
      if (typeof arg === 'number') {
        // Schedule index provided.
        // Editing existing schedule, here is the index:
        data.si = arg
        // Create a separate clone to avoid touching the original in case the window is closed with "Cancel".
        arg = dClone(pl.config.schedules[arg])
      }
      if (data.isAdd) {
        arg.name = '' // Remove the name "Default"
      }
      if (!arg.time_of_day_start && !arg.time_of_day_end) {
        arg.time_of_day_start = arg.time_of_day_end = '00:00:00'
      }
      data.schedule = reactive(arg)
      data.tiers = pl.config.tiers
      currentModalKey.value = 'ScheduleRule'
    }
    const onAddSchedule = () => {
      // See if we have only one default schedule, in which case we edit it.
      if (isOneDefaultSchedule.value) {
        editSchedule(0, true)
      } else {
        // Otherwise truly add a new one:
        const newOne = dClone(defaultSchedule)
        newOne.isDirty = true
        editSchedule(newOne, true)
      }
    }
    const deleteSchedule = si => {
      if (pl.config.schedules.length > 1) {
        arrayRemove(pl.config.schedules, pl.config.schedules[si])
      } else {
        // Bring it back to the invisible default one
        pl.config.schedules[0] = dClone(defaultSchedule)
      }
    }
    const submit = async () => {
      const val = validate()
      if (val.valid) {
        const toSave = props.id ? objDiff(val.candidateData, original) : val.candidateData
        let allGood = true
        if (!isEmptyObject(toSave)) {
          const verb = props.id ? 'patch' : 'post'
          let url = 'price_library'
          if (props.id) {
            url = `${url}/${props.id}`
          }
          await window.APIService[verb](url, toSave, {
            ui: buttons.value,
            handler: {
              matcher: 422,
              alertUser: true,
              task() {
                if (!isExternal.value) {
                  suspendCurrentPhaseObjectWatcher = true
                  phaseDDLValue.value = originalPhaseDDLValue
                }
                allGood = false
              }
            }
          })
          if (allGood) {
            GETCache.bust('price_library')
          }
        }
        if (allGood) {
          cancel()
        }
      }
    }
    const priceMatrixError = computedError({
      name: 'tiers', // Could be "rows" - we're simply detecting the presence of at least one. If there a tier, there will always be at least one row as well.
      holder: pl.config
    })
    const disableTierDND = computed(() => !(pl.config.tiers.length > 1))
    const onTierMove = e => {
      e = e.moved
      if (e) {
        // Need to move the tier modifiers accordingly
        pl.config.rows.forEach(r => r.phases.forEach(ph => arrayMove(ph.tier_modifiers, e.oldIndex, e.newIndex)))
        updateAbsoluteScheduleConditionsOnTierMove()
      }
    }
    const onScheduleMove = e => {
      e = e.moved
      if (e) {
        arrayMove(pl.config.schedules, e.oldIndex, e.newIndex)
      }
    }
    const showPhases = computed(() => !isExternal.value && props.id)
    const nextPhaseNoteDismissKey = props.id ? `PL-NextPhaseNoteDismissed-${props.id}` : null
    const isNextPhaseNoteDismissed = ref(sessionStorage.getItem(nextPhaseNoteDismissKey) === 'yes')
    const nextPhaseNoteDismissable = computed(() => props.id && isCurrentPhase.value && !isNextPhaseNoteDismissed.value)
    const closeNextPhaseNote = () => {
      sessionStorage.setItem(nextPhaseNoteDismissKey, 'yes')
      isNextPhaseNoteDismissed.value = true
    }
    return {
      type,
      buttons,
      pl,
      data,
      cancel,
      stubTiers,
      modalKeys,
      modalCmps,
      onSubmitted,
      onClosed,
      onDelete,
      ScrollTDRowSpan,
      priceMatrixExists,
      getCellClass,
      getCellContent,
      fm,
      onCellClick,
      onTierClick,
      onRowClick,
      submit,
      headPrefix,
      name,
      priceMatrixError,
      notStretched,
      currentModalKey,
      currentModalOpen,
      negativeCalcPricesPresent,
      onAddSchedule,
      schedules,
      editSchedule,
      deleteSchedule,
      disableTierDND,
      onTierMove,
      onScheduleMove,
      phaseDDLValue,
      phasesDDLOptions,
      phaseInfo,
      editPhase,
      removePhase,
      currentPhaseObject,
      currentPhaseIndex,
      showPhases,
      matrixLoading,
      readOnly,
      isCurrentPhase,
      header,
      closeNextPhaseNote,
      isNextPhaseNoteDismissed,
      nextPhaseNoteDismissable,
      grandTypeOptions,
      grandTypeMap,
      isExternal,
      isExternalEnabled: isExternalEnabled(),
      formatMoney,
      externalMattixHelpText
    }
  },
  components: {
    field,
    AddMatrix,
    Tier,
    Row,
    EditPrice,
    ScheduleRule,
    ScheduleItem,
    draggable,
    DDL,
    DaC,
    CopyID,
    PriceChange
  }
}
</script>
<template>
  <div class="PriceLib V3ResourceList" :class="{'form-disabled': readOnly}">
    <form class="PriceLibForm" :class="{singleTier: pl.config.tiers && pl.config.tiers.length === 1, singleRow: pl.config.rows.length === 1}" @submit.prevent="submit">
      <div class="Settings__Content__heading">
        <h2>{{ headPrefix }} Price Library</h2>
        <CopyID v-if="isExternal" :value="id" />
      </div>
      <div class="FormSection">
        <div class="PriceLib__Type" v-if="isExternalEnabled">
          <label>Type</label>
          <div v-if="priceMatrixExists" v-html="grandTypeMap.get(type)" />
          <DDL v-else v-model="type" :options="grandTypeOptions" :required="true" class="PriceLib__Type" />
        </div>
        <div class="MR__Name">
          <label class="required">Name</label>
          <field :holder="pl" name="name" ref="name" />
        </div>
        <div class="MR__Description">
          <label>Description</label>
          <field :holder="pl" name="description" type="text" />
          <p class="memo">This content does not show anywhere, but is useful for admin notes.</p>
        </div>
      </div>
      <div class="FormSection PriceMatrix">
        <div class="PriceMatrix__header">
          <h3 v-shtml="header" />
          <div v-if="showPhases">
            <label>Price changes</label>
            <DDL v-model="phaseDDLValue" :options="phasesDDLOptions" />
          </div>
        </div>
        <div v-if="priceMatrixError || negativeCalcPricesPresent" v-shtml="priceMatrixError || 'The price library cannot be saved due to negative price values in the price matrix'" class="errorHolder" />
        <template v-if="priceMatrixExists">
          <div class="InfoBlock PhaseInfo" :class="{dismiss: isCurrentPhase && nextPhaseNoteDismissable && !isNextPhaseNoteDismissed}" v-if="!isExternal && phaseInfo && showPhases && (!isCurrentPhase || !isNextPhaseNoteDismissed)">
            <div v-shtml="phaseInfo" />
            <div v-if="isCurrentPhase && nextPhaseNoteDismissable" @click="closeNextPhaseNote" />
            <div v-if="!isCurrentPhase">
              <span @click="editPhase">Edit name and date</span>
              <span @click="removePhase">Remove</span>
            </div>
          </div>
          <div class="InfoBlock" v-if="isExternal">
            <p v-html="externalMattixHelpText" />
          </div>
          <div v-if="isExternal" class="PriceLib__External">
            <div v-for="(row, ri) in pl.config.rows" @click="onRowClick(ri)">
              <div>
                <label>Price Row Name</label>
                <div v-html="row.name" />
              </div>
              <div>
                <label>Nominal Price</label>
                <div v-html="formatMoney(row.phases[currentPhaseIndex].nominal)" />
              </div>
              <div class="PriceLib__External__GUID">
                <label>Price Row ID</label>
                <DaC :value="row.id" />
              </div>
            </div>
          </div>
          <table v-else class="TierTable" :class="{notStretched: notStretched, matrixLoading: matrixLoading}">
            <tbody>
              <tr>
                <td class="FirstTD HeadTD"></td>
                <td :rowspan="ScrollTDRowSpan + 1" class="ScrollTD">
                  <div>
                    <draggable v-model="pl.config.tiers" :item-key="el => el" class="ScrollTableDiv" :class="{useDND: !disableTierDND}" handle=".TierHead" :disabled="disableTierDND" @change="onTierMove">
                      <template #item="{element, index}">
                        <div class="TierColumn">
                          <div :title="element" @click="onTierClick(index)" class="TierHead">
                            <span>{{ element }}</span>
                          </div>
                          <div class="TierCellDiv" v-for="(row, ri) in pl.config.rows" @click="onCellClick({ti: index, ri})" :class="getCellClass({ti: index, ri})" v-shtml="getCellContent({ti: index, ri})"></div>
                        </div>
                      </template>
                      <template #footer>
                        <div v-for="stub in stubTiers" class="StubTierColumn">
                          <div></div>
                          <div v-for="row in pl.config.rows"></div>
                        </div>
                      </template>
                    </draggable>
                  </div>
                </td>
              </tr>
              <tr v-for="(row, ri) in pl.config.rows">
                <td class="FirstTD RowTD">
                  <div @click="onRowClick(ri)">
                    <div :title="row.name">
                      <div>{{ row.name }}</div>
                    </div>
                    <div>
                      <div class="PriceLabel">
                        <label>Nominal Price</label>
                        {{ fm(row.phases[currentPhaseIndex].nominal) }}
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr class="FooterLine">
                <td class="FooterTD"></td>
              </tr>
            </tbody>
          </table>
          <div class="UnderTable">
            <div v-if="isCurrentPhase">
              <button class="btn btn-black addRow" @click="currentModalKey = 'Row'" type="button">Add Price Row</button>
              <button class="btn btn-black addTier" @click="currentModalKey = 'Tier'" type="button" v-if="!isExternal">Add Price Tier</button>
            </div>
            <div v-if="!isExternal">
              <div class="PriceType PriceType__Nominal">Nominal price</div>
              <div class="PriceType PriceType__Fixed">Fixed price</div>
              <div class="PriceType PriceType__Calculated">Calculated price</div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="InfoBlock">
            <p v-if="isExternal" v-html="externalMattixHelpText" />
            <p v-else>A price matrix is used to set up your price rows (eg. Adult, Child) and price tiers (eg. Peak Pricing, Off-Peak Pricing) so that you can set up rules to enforce these different prices.</p>
            <p>You have not setup a price matrix. Click the ‘Add Price Matrix’ button to begin.</p>
          </div>
          <button class="btn btn-black" @click="currentModalKey = 'AddMatrix'" type="button">Add Price Matrix</button>
        </template>
      </div>
      <div class="Schedule" v-if="!isExternal && priceMatrixExists">
        <div class="Schedule__Header">
          <h3>Schedule</h3>
        </div>
        <div class="Schedule__Body">
          <template v-if="schedules.length">
            <draggable :list="schedules" :item-key="el => el" handle=".DD_Handler" @change="onScheduleMove">
              <template #item="{element, index}">
                <ScheduleItem :data="element" :tiers="pl.config.tiers" @edit="editSchedule(index)" @delete="deleteSchedule(index)" />
              </template>
            </draggable>
          </template>
          <div v-else class="InfoBlock">You have no Schedule Rules configured. Click the ‘Add Schedule Rule’ button to start adding rules.</div>
          <button class="btn btn-black" @click="onAddSchedule" type="button">Add Schedule Rule</button>
        </div>
      </div>
      <div class="BottomControls">
        <div ref="buttons">
          <a data-submit-ui-disabled="1" class="btn btn-default uppercase" @click="cancel">Cancel</a>
          <button type="submit" data-submit-ui-disabled="1" data-submit-spinner="1" class="btn btn-primary uppercase">Save Price Library</button>
        </div>
      </div>
    </form>
    <template v-for="key in modalKeys">
      <component v-if="currentModalKey === key" :is="modalCmps[key]" v-model="currentModalOpen" :data="data" @delete="onDelete(key)" @closed="onClosed" @submitted="onSubmitted[key]" />
    </template>
  </div>
</template>
<style lang="scss">
.PriceLib {
  &__External {
    border-radius: 2px;
    background-color: $v3-separator-color;
    padding: 1px;
    > div {
      display: flex;
      gap: 1px;
      &:not(:last-child) {
        margin-bottom: 1px;
      }
      cursor: pointer;
      > div {
        background-color: $ticket-group-body-colour;
        padding: 7px 16px;
        &:not(.PriceLib__External__GUID) {
          width: 25%;
          min-width: 25%;
        }
        &.PriceLib__External__GUID {
          flex-grow: 1;
        }
        label {
          font-size: 11px;
          cursor: pointer;
          font-weight: 400;
          line-height: 14px;
          color: $v3-note-color;
          margin: 0;
        }
        div {
          font-size: 14px;
          font-weight: 600;
          line-height: 20px;
          color: $v3-dark-color;
        }
      }
      &:hover > div {
        background-color: $v3-light-grey !important;
      }
    }
  }
  .PriceLib__Type.DDL {
    width: 196px;
  }
  h3 span {
    font-weight: 400;
  }
  .PhaseInfo {
    display: flex;
    justify-content: space-between;
    > div {
      span {
        color: $v3-blue;
        cursor: pointer;
        + span:last-child {
          border-left: 1px solid $v3-border-color;
          padding-left: 12px;
        }
        &:not(:last-child) {
          margin-right: 16px;
        }
      }
    }
  }
  .memo {
    font-size: 11px;
    margin: 0;
    padding-top: 0;
  }
  .MR__Description {
    padding-bottom: 0;
  }
  &__Rounding {
    margin-top: 4px;
    padding-bottom: 4px;
    justify-content: flex-start;
    p {
      margin-top: 8px !important;
    }
    > div:last-child {
      flex-basis: 25%;
    }
  }
  .ScrollTableDiv {
    display: flex;
    align-items: stretch;
    > div {
      width: 121px;
      min-width: 121px;
      border-bottom: 1px solid #ddd;
      &:not(:last-child) {
        border-right: 1px solid #ddd;
      }

      &.TierColumn {
        > div {
          cursor: pointer;
          &:hover {
            background-color: $hover;
          }
        }
      }

      > div {
        display: flex;
        justify-content: center;
        align-items: center;

        &:first-child {
          height: 56px;
          background-color: $ticket-group-body-colour;
          > span {
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-weight: 700;
            width: 100%;
            padding: 0 12px;
            text-align: center;
          }
        }
        &:not(:first-child) {
          height: 49px;
          border-top: 1px solid #ddd;
        }
      }
    }
  }

  .useDND .TierHead {
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 12px;
    background-position: center 36px;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg width='15' height='7' viewBox='0 0 15 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.398438 4.5L0.398438 6.5H2.39844V4.5H0.398438ZM0.398438 0.500113L0.398438 2.50011H2.39844V0.500113H0.398438ZM4.39844 6.5V4.5H6.39844V6.5H4.39844ZM4.39844 0.500113V2.50011H6.39844V0.500113H4.39844ZM8.39844 6.5V4.5L10.3984 4.5V6.5H8.39844ZM8.39844 0.500113V2.50011H10.3984V0.500113H8.39844ZM12.3984 6.5V4.5H14.3984V6.5H12.3984ZM12.3984 0.500113V2.50011H14.3984V0.500113H12.3984Z' fill='%2370757D'/%3E%3C/svg%3E%0A");
  }

  .Schedule {
    &__Header {
      h3 {
        margin: 0;
      }
      height: 68px;
      padding: 0 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &__Body {
      padding: 16px;
      background-color: $ticket-group-body-colour;
    }
  }

  .PriceMatrix {
    padding: 16px;
    &__header {
      padding: 0 !important;
      display: flex;
      justify-content: space-between;
      > div {
        display: flex;
        align-items: center;
        label {
          margin: 0 12px 0 0;
        }
        > .DDL {
          width: 188px;
          .vs__dropdown-menu li:last-child {
            &:hover {
              color: white;
            }
            &:not(.vs__dropdown-option--highlight) {
              color: $v3-blue;
            }
          }
        }
      }
    }
    .errorHolder {
      padding: 0;
      margin: 0 0 16px 0;
    }
  }

  .PriceLibForm {
    .FormSection,
    .Settings__Content__heading {
      border-bottom: 1px solid #ddd;
    }
  }

  .singleTier .DeleteTier {
    display: none;
  }

  .singleRow .DeleteRow {
    display: none;
  }

  .AddPriceMatrix {
    input {
      width: 100%;
    }
  }

  .NominalPrice .Field3__Container {
    width: 124px;
  }

  .PriceRowID,
  .NominalPrice {
    margin-top: 16px;
  }

  .PriceType {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      width: 0;
      height: 0;
      border-left: 20px solid transparent;
      z-index: 0;
    }

    &::after {
      position: absolute;
      bottom: 0;
      width: 20px;
      height: 20px;
      font-size: 9px;
      font-weight: 600;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      line-height: 9px;
      padding: 0 2px 1px 0;
      z-index: 2;
    }

    &__Nominal {
      &::before {
        border-bottom: 20px solid #282b37;
      }
      &::after {
        content: 'N';
        color: white;
      }
    }

    &__Negative {
      color: $warning-color;
    }

    &__Fixed {
      &::before {
        border-bottom: 20px solid $ticket-group-body-colour;
      }
      &::after {
        content: 'F';
        color: #282b37;
      }
    }

    &__Calculated {
      &::before {
        border-bottom: 20px solid #f8edd2;
      }
      &::after {
        content: 'C';
        color: #836313;
      }
    }
  }

  .TierCellDiv > div {
    pointer-events: none;
  }

  .TierTable {
    &.matrixLoading {
      .PriceLabel,
      .TierCellDiv {
        filter: blur(4px);
      }
    }

    .FirstTD {
      width: 210px;
      max-width: 210px;
      min-width: 209px;
      > div {
        cursor: pointer;
      }
    }

    .FooterTD {
      height: 14px;
    }

    @include when-at-least2018(large) {
      &.notStretched {
        .FooterLine {
          display: none;
        }
        .ScrollTD::before {
          display: none;
        }
        .ScrollTD > div {
          overflow-x: hidden;
        }
      }
    }

    .HeadTD {
      height: 56px;
    }

    > tbody > tr > td.RowTD {
      height: 48px;

      &:first-child > div {
        user-select: none;
        label {
          cursor: pointer;
        }
        &:hover {
          background-color: $hover;
        }
      }
    }

    .ScrollTD {
      width: 600px;
      max-width: 600px;
      position: relative;

      &::before {
        position: absolute;
        background: linear-gradient(90deg, rgb(0, 0, 0, 0.05) 0%, rgba(0, 0, 0, 0) 100%);
        pointer-events: none;
        content: '';
        top: 0;
        bottom: 14px;
        width: 10px;
        left: 0;
        z-index: 200;
      }

      > div {
        position: absolute;
        z-index: 100;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        overflow-x: scroll;
        overflow-y: hidden;
      }

      .PriceType {
        cursor: pointer;
        &:hover {
          background-color: $hover !important;
          cursor: pointer;
        }

        > div {
          display: none;
          position: absolute;
        }

        &__Focused {
          background-color: $light-blue2;
          position: relative;
          > div {
            display: block;
            outline: 1px solid $v3-blue;
            top: 1px;
            left: 1px;
            right: 1px;
            bottom: 1px;
          }
        }
      }

      .TierCells:last-child .PriceType {
        &__Focused > div {
          bottom: 2px;
        }
        &::before {
          bottom: 1px;
        }
        &::after {
          bottom: 1px;
        }
      }
    }

    .PriceType {
      &::before {
        right: 0;
      }
      &::after {
        right: 0;
      }
    }

    color: $v3-dark-color;

    .PriceLabel {
      text-align: center;
    }

    width: 100%;
    padding: 0;
    border-spacing: 1px;
    border-collapse: separate;
    background-color: #ddd;

    > tbody > tr {
      > td {
        background-color: white;
        padding: 0;
      }

      > td:first-child {
        background-color: $ticket-group-body-colour;
        font-weight: 600;

        label {
          color: $v3-note-color;
          font-size: 11px;
          font-weight: 400;
        }

        > div {
          height: 100%;
          width: 100%;
          display: flex;
          justify-content: space-between;
          > div {
            width: 50%;
            max-width: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            &:first-child {
              justify-content: flex-start;
              padding: 0 16px;
              position: relative;

              > div {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }

              &::after {
                content: '';
                position: absolute;
                background-color: #ddd;
                width: 1px;
                height: 20px;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
              }
            }
          }
        }
      }
    }
  }

  .UnderTable {
    margin: 16px 0 0 0;
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
      background-repeat: no-repeat;
      background-position: right 12px center;
      padding-right: 34px;
      height: 36px;
    }

    .addTier {
      margin-left: 8px;
      background-image: url("data:image/svg+xml,%3Csvg width='12' height='14' viewBox='0 0 12 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M1.99967 1.66669H4.66634C5.03453 1.66669 5.33301 1.96516 5.33301 2.33335V11.6667C5.33301 12.0349 5.03453 12.3334 4.66634 12.3334H1.99967C1.63148 12.3334 1.33301 12.0349 1.33301 11.6667V2.33335C1.33301 1.96516 1.63148 1.66669 1.99967 1.66669Z' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M8 7.00002H10.6667' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M9.33333 5.66669V8.33335' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
    }

    .addRow {
      background-image: url("data:image/svg+xml,%3Csvg width='14' height='12' viewBox='0 0 14 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12.3327 1.99998V4.66665C12.3327 5.03484 12.0342 5.33331 11.666 5.33331H2.33268C1.96449 5.33331 1.66602 5.03484 1.66602 4.66665V1.99998C1.66602 1.63179 1.96449 1.33331 2.33268 1.33331H11.666C12.0342 1.33331 12.3327 1.63179 12.3327 1.99998Z' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M6.99935 8V10.6667' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M8.33268 9.33333H5.66602' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    }

    > div:last-child {
      height: 20px;
      display: flex;
      justify-content: flex-end;
      > div {
        margin-left: 12px;
        position: relative;
      }
    }

    .PriceType {
      &::before {
        left: 0;
      }
      &::after {
        left: 0;
      }
      font-size: 12px;
      padding: 4px 0 0 28px;
      display: flex;
      align-items: center;
    }
  }
}
</style>
