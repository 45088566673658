<script>
import {computed, ref, watch} from 'vue'
import {arrayRemove} from '@/helpers/format'
import GETCache from '@/classes/GETCache'
import field from '@/components/Field/Field.vue'
import {filterLabels} from '../Class'
import ddl from '@/components/DDL.vue'

const modeOps = []
;['category', 'id'].forEach(k => modeOps.push({code: k, label: `${filterLabels[k]} (optional)`}))

const helpLabels = {
  category: subject => `Only show the selected event categories in the ${subject} output. Otherwise show all.`,
  id: subject => `Only show the selected events in the ${subject} output. Otherwise show all.`
}

const theTwo = modeOps.map(o => o.code)

export default {
  components: {
    ddl,
    field
  },
  props: {
    // See the View component in this folder for what this field means.
    eventIdJoin: {
      type: Boolean
    },
    holder: {
      type: Object,
      required: true
    },
    subject: {
      type: String,
      default: 'webhook'
    }
  },
  setup(props) {
    const mode = ref(theTwo[0])
    const values = ref(null)
    const getValueKey = modeKey => {
      if (modeKey !== 'id') {
        return modeKey
      }
      return props.eventIdJoin ? modeKey : 'event_template_id'
    }
    let i = 0
    // Either (but not both) the id or category array will have elements in it.
    // Find out which, and set the mode and values accordingly:
    for (; i < theTwo.length; i++) {
      const vk = getValueKey(theTwo[i])
      if (props.holder[vk].length) {
        mode.value = theTwo[i]
        values.value = props.holder[vk]
        break
      }
    }
    const sellers = []
    theApp.sellers.forEach(s =>
      sellers.push({
        code: s.id,
        label: s.name
      })
    )
    const seller = ref(sellers[0].code)
    const eventsLoaded = ref(false)
    let allEvents
    const eventLabels = new Map()
    GETCache.get('event_template').then(result => {
      allEvents = result
      Array.from(allEvents.keys()).forEach(s => allEvents.get(s).map(e => eventLabels.set(e.id, `<i>${theApp.sellers.get(s).name}:</i> ${e.name}`)))
      eventsLoaded.value = true
    })
    const event = ref(null)
    const events = computed(() => {
      if (!eventsLoaded.value || !allEvents.has(seller.value)) {
        return []
      }
      return allEvents
        .get(seller.value)
        .map(e => ({
          code: e.id,
          label: e.name
        }))
        .filter(e => !values.value.includes(e.code))
    })
    const cats = config('config.event_categories') || []
    watch(mode, (nv, ov) => {
      values.value = []
      props.holder[getValueKey(ov)] = []
    })
    watch(event, e => {
      if (mode.value === 'id' && e) {
        values.value.push(e)
        event.value = null // the value has been picked just to add it to the list, it needs not to stay
      }
    })
    const removeEvent = eId => arrayRemove(values.value, eId)
    watch(
      values,
      v => {
        props.holder[getValueKey(mode.value)] = v
      },
      {deep: true}
    )
    return {
      seller,
      sellers,
      cats,
      event,
      events,
      modeOps,
      mode,
      eventLabels,
      eventsLoaded,
      removeEvent,
      values,
      helpLabels
    }
  }
}
</script>
<template>
  <div class="FieldWrapper EventFilter">
    <field v-model="mode" type="radioenum" :options="modeOps" />
    <ddl v-if="mode === 'category'" v-model="values" :options="cats" :multiple="true" />
    <div v-if="mode === 'id' && eventsLoaded">
      <div class="EventFilter__SelectedEvents">
        <div v-for="eId in values">
          <span v-shtml="eventLabels.get(eId) || eId" />
          <span @click="removeEvent(eId)" />
        </div>
      </div>
      <div class="EventFilter__DDLs">
        <ddl v-model="seller" :options="sellers" />
        <ddl v-model="event" :options="events" />
      </div>
    </div>
    <p v-shtml="helpLabels[mode](subject)" class="description" />
  </div>
</template>
<style lang="scss">
.SettingForm .FieldWrappers .FieldWrapper.EventFilter {
  flex-grow: 1 !important;
}
.EventFilter {
  > .Field3 {
    margin-bottom: 4px;
  }
  > .v-select {
    width: calc(50% - 16px);
  }
  .RadioGroup {
    margin: 0;
  }
  &__SelectedEvents {
    > div {
      background-color: $headersellernav-dropdown-desktop-bg-color;
      color: $white;
      border-radius: 4px;
      padding: 4px 6px;
      margin-bottom: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      > span {
        &:first-child {
          flex-grow: 1;
        }
        &:last-child {
          width: 12px;
          height: 12px;
          display: block;
          cursor: pointer;
          @extend %tag_close;
        }
      }
    }
  }
  &__DDLs {
    display: flex;
    justify-content: space-between;
    > div {
      width: calc(50% - 8px);
    }
  }
}
</style>
