const copyTextToClipboard = text => {
  const textArea = document.createElement('textarea')
  textArea.style.position = 'absolute'
  textArea.style.top = '50%'
  textArea.style.left = '50%'
  textArea.style.opacity = '0'
  textArea.value = text
  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()
  let r
  try {
    r = !!document.execCommand('copy')
  } catch (err) {
    r = false
  }
  document.body.removeChild(textArea)
  return r
}

export {copyTextToClipboard}
