// @cms-next @tix/common-utils
import type {Dict} from './types'

export type QueryParams = Dict<string | string[] | boolean | undefined>

export function unique<T>(items: T[]): T[] {
  return Array.from(new Set(items))
}

export function buildQueryString(params: QueryParams): string {
  const result: [string, Array<string | boolean>][] = []

  // Sort keys to optimize the URL for cache hits.
  for (const key of Object.keys(params).sort()) {
    const value = params[key]!

    // Ignore undefined values.
    if (value != null) {
      if (typeof value === 'boolean') {
        result.push([key, [value]])
      } else {
        const items = Array.isArray(value)
          ? // For arrays; Ignore duplicate values. Sort values to optimize the URL for cache hits.
            unique(value).sort()
          : // for strings; Split on commas to preserve commas without URI-encoding them.
            value.split(',')

        // Ignore empty arrays.
        if (items.length > 0) {
          result.push([key, items])
        }
      }
    }
  }

  return result
    .map(([key, values]) => [encodeURIComponent(key), values.map(encodeURIComponent)] as [string, string[]])
    .map(([key, values]) => [key, values.join(',')])
    .map(([key, value]) => `${key}=${value}`)
    .join('&')
}
