// @cms-next src/stores/configTemplates.ts
import type {ConfigTemplateEntity} from '@/api/config'
import type {Dict} from '@/helpers/types'
import {fetchTemplates} from '@/config/api'
import {configItemPath} from '@/config/helpers'
import type {ResourceName} from '@/api/response'

//
// Fetches, indexes and caches config templates for config search, list and detail routes.
//

export interface ConfigTemplate extends ConfigTemplateEntity {
  path: string
}

type SearchIndex = Dict<Set<ConfigTemplate>>

interface Item {
  templates: ConfigTemplate[]
  searchIndex: SearchIndex
}

const store: Partial<Record<ResourceName, Item>> = {}

document.addEventListener('tix authentication', () => {
  for (const key in store) {
    store[key] = undefined
  }
})

export async function getConfigTemplates(resource: ResourceName): Promise<ConfigTemplate[]> {
  if (!store[resource]) {
    const templates = normalizeTemplates(await fetchTemplates(resource)).sort((a, b) => a.path.localeCompare(b.path))

    store[resource] = {
      templates: templates,
      searchIndex: indexBySearchKeywords(templates)
    }
  }

  return store[resource].templates
}

interface GetTemplateOptions {
  resource: ResourceName
  namespace: string
  name: string
}

export async function getConfigTemplate(options: GetTemplateOptions): Promise<ConfigTemplate> {
  const templates = await getConfigTemplates(options.resource)
  const path = configItemPath(options)
  return templates.find(template => template.path === path)
}

export function getMatchingTemplates(resource: ResourceName, query: string): ConfigTemplate[] {
  const words = split(query)
  if (words.length < 1) {
    return store[resource].templates
  } else {
    return search(words, store[resource].searchIndex)
  }
}

function search(keywords: string[], index: SearchIndex): ConfigTemplate[] {
  const [first, ...rest] = keywords
  // Do not mutate the stored search index.
  const result = new Set(index[first])

  for (const word of rest) {
    if (!index[word] || result.size < 1) {
      // No matches
      return []
    } else {
      for (const t of Array.from(result)) {
        if (!index[word].has(t)) {
          result.delete(t)
        }
      }
    }
  }
  return Array.from(result)
}

function normalizeTemplates(templates: ConfigTemplateEntity[]): ConfigTemplate[] {
  return templates.map(template => {
    const path = configItemPath(template)
    return Object.freeze({...template, path})
  })
}

function indexBySearchKeywords(templates: ConfigTemplate[]): SearchIndex {
  const result: SearchIndex = {}
  for (const template of templates) {
    for (const word of uniqueKeywords(template)) {
      // Index first parts of each word too.
      for (let end = word.length; end > 0; end--) {
        const part = word.substring(0, end)
        result[part] ??= new Set()
        result[part].add(template)
      }
    }
  }
  return result
}

function uniqueKeywords(template: ConfigTemplate): string[] {
  return deduplicate([template.name, template.namespace, ...split(template.title), ...split(template.description)])
}

function split(language = ''): string[] {
  return language
    .toLowerCase()
    .split(/\W+/)
    .filter(word => word != '')
}

function deduplicate<T>(items: T[]): T[] {
  return Array.from(new Set(items))
}
