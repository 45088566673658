<script setup>
import ExpandableBlock from '../ExpandableBlock.vue'
import Triggers from './Trigger/Manager'
import Actions from './Action/Manager'

// Taking advantage of the UI design which structures the Triggers and Actions very similar,
// we use this one parent component for both.

// The logic for data manipulation is spread across a hierarchy of managers:
// we detach the logic from Vue3 component code and encapsulate it into the manager classes,
// taking advantage of OOP (which works quite well in this use-case),
// and leaving Vue3 components to be primarily concerned about the UI.

const managerClassMap = {
  Triggers,
  Actions
}
const props = defineProps({
  title: {
    type: String,
    required: true
  },
  data: {
    type: Object,
    required: true
  }
})
const emit = defineEmits(['itemschange'])
const manager = new managerClassMap[props.title](props.data)
manager.on('itemschange', () => emit('itemschange'))
const onAnd = () => manager.add()
const onEmpty = (i, itemType) => manager.onEmpty(i, itemType)
const placeHolders = {
  Triggers: 'a trigger',
  Actions: 'an action'
}
const onTypeChange = (i, [nv, ov]) => manager.onTypeChange(i, nv, ov)
</script>
<template>
  <ExpandableBlock class="MR__Items" :expandedByDefault="true" v-if="manager.sr.isReady.value">
    <template #header
      ><h4>{{ title }}</h4></template
    >
    <template #content>
      <div class="FormSection__Inner" v-for="(type, i) in manager.dr.items" :key="manager.sr.holders[i]._id">
        <component :is="manager.getItemComponent(i)" :cmpTeller="manager.constructor.cmpTeller" v-model="manager.dr.items[i]" :options="manager.dr.options[i]" :placeholder="placeHolders[title]" @empty="onEmpty(i, $event)" @typechange="onTypeChange(i, $event)" :data="manager.sr.holders[i]" />
        <div @click="onEmpty(i)" v-if="i !== 0 && type === ''" class="MR__Delete" />
      </div>
      <div class="MR__AndButton" @click="onAnd" v-if="manager.sr.showAnd.value" />
    </template>
  </ExpandableBlock>
</template>
<style lang="scss"></style>
