<script>
import makeModalCmp from './Abstract'
import {setError, cleanErrors} from '@/helpers/ErrorHolder'
import DaC from '@/components/DisplayAndCopy.vue'
import {strip} from '@/helpers/format'
import IsValidNominalPrice from '../IsValidNominalPrice'
const cmp = makeModalCmp(setup => {
  const originalNominal = setup.data.nominalPrice
  const submitPromiseMaker = () => {
    const newNominal = String(setup.data.nominalPrice)
    if (!setup.data.calcPromiseMaker || newNominal === String(originalNominal)) {
      // No need to re-calculate any prices
      return Promise.resolve()
    }
    return setup.data.calcPromiseMaker(newNominal)
  }
  return {
    disableDeleteUnlessDefaultPhase: 'You can only delete a price row on the current price matrix',
    onBeforeSubmit: () => {
      strip(setup.data, cleanErrors, true)
      if (!Boolean(setup.data.name)) {
        setError(setup.data, 'name')
        return false
      }
      return IsValidNominalPrice(setup.data)
    },
    submitPromiseMaker,
    name: 'Price Row',
    ...setup
  }
})
cmp.components.DaC = DaC
export default cmp
</script>
<template>
  <Modal v-model="show" submitText="Confirm" :onBeforeSubmit="onBeforeSubmit" @submitted="onSubmitted" :submitPromiseMaker="submitPromiseMaker" class="PriceLibModal AddPriceRow" :onMounted="onMounted">
    <template #header>{{ header }}</template>
    <template #extraButtons v-if="isEdit && data.moreThanOneRow">
      <ClickAgainToConfirmButton class="DeleteButton DeleteRow" :disabled="!data.isCurrentPhase" @action="onDelete" idleText="Delete Row" />
    </template>
    <div>
      <label class="required">Name</label>
      <field :holder="data" name="name" />
    </div>
    <div class="NominalPrice">
      <label class="required">Nominal price</label>
      <field :holder="data" name="nominalPrice" type="money" :description="data.isExternal ? 'The nominal price will be shown in the CMS wherever ticket prices are displayed and in ticket-related reports in Kibana.' : null" />
    </div>
    <div v-if="data.isExternal && data.id" class="PriceRowID">
      <label>Price Row ID</label>
      <DaC :value="data.id" />
    </div>
  </Modal>
</template>
