<script lang="ts" setup>
import {getUnAuthPageAttrs, unAuthFormBgColor, altUnAuthFormBgColor} from '@/helpers/ui'
import LoginForm from '@/components/LoginForm.vue'
import ResetPassword, {UserType} from '@/routeComponents/ResetPassword.vue'
import {Ref, ref, watch} from 'vue'
import {getQuery} from '@/helpers/route'
import {AuthMethod, checkResetPasswordToken, ssoLogin} from '@/helpers/api'
import {useAuth} from '@okta/okta-vue'
import {isSsoSupported as hasSso} from '@/helpers/access'
const {bgImage} = getUnAuthPageAttrs()

const unauthMode: Ref<'login' | 'confirm-account' | 'reset-password' | 'okta-callback' | 'okta-logout'> = ref('login')

const error = ref('')
const resetPasswordEmail = ref('')
const resetPasswordUserType: Ref<UserType> = ref('staff' as UserType)
const isSsoSupported = hasSso()

const isNewStaffLogin = ref(false)
const onLoginModeChange = (mode: AuthMethod) => {
  isNewStaffLogin.value = mode === 'ticketure_staff'
}

const onForgotPasswordModeToggle = () => {
  error.value = ''
}

const updateFormBgColor = () => {
  const {formBgColor} = getUnAuthPageAttrs(isNewStaffLogin.value ? altUnAuthFormBgColor : unAuthFormBgColor)
  document.body.style.setProperty('--unauth-bg-color', formBgColor)
}

watch(isNewStaffLogin, v => {
  updateFormBgColor()
})

updateFormBgColor()

const query = getQuery()
if (query['confirm-account'] != null) {
  unauthMode.value = 'confirm-account'
} else if (query['reset-password'] != null) {
  unauthMode.value = 'reset-password'
} else if (query['okta-callback'] != null) {
  unauthMode.value = 'okta-callback'
} else if (query['okta-logout'] != null) {
  unauthMode.value = 'okta-logout'
}

if (unauthMode.value === 'confirm-account' || unauthMode.value === 'reset-password') {
  checkResetPasswordToken(query['token'])
    .then(({email, provider}) => {
      resetPasswordEmail.value = email
      resetPasswordUserType.value = provider
    })
    .catch(err => {
      error.value = err
      unauthMode.value = 'login'
    })
} else if (unauthMode.value === 'okta-callback') {
  const auth = useAuth()
  auth.token.parseFromUrl().then(data => {
    const accessToken = data.tokens.accessToken.accessToken
    if (accessToken) {
      ssoLogin(accessToken, 'okta_staff')
    }
  })
}
</script>

<template>
  <section class="UnauthView" :style="{backgroundImage: `url('${bgImage}')`}">
    <div class="UnauthView__Content" :style="{backgroundColor: 'var(--unauth-bg-color)'}">
      <div class="DesktopLogo"></div>

      <ResetPassword v-if="unauthMode === 'confirm-account' || unauthMode === 'reset-password'" :email="resetPasswordEmail" :confirm-account-mode="unauthMode === 'confirm-account'" :user-type="resetPasswordUserType"> Confirm </ResetPassword>
      <LoginForm v-else :error="error" :is-sso-supported="isSsoSupported" @login-mode-change="onLoginModeChange" @forgot-password-mode-toggle="onForgotPasswordModeToggle"></LoginForm>
    </div>
  </section>
</template>

<style lang="scss">
.UnauthView__Content {
  background-color: var(--unauth-bg-color);
}
</style>
