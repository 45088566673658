<script setup>
import Manager from './Manager'
import TriggerOrAction from '../../TriggerOrAction.vue'
const props = defineProps({
  data: {
    type: Object, // This will be an element in the "date_or_session" array
    required: true
  }
})
const manager = new Manager(props.data)
const onEmpty = i => manager.onEmpty(i)
const onTypeChange = (i, [nv, ov]) => manager.onTypeChange(i, nv, ov)
const onAnd = () => manager.add()
const isAndAvailable = manager.sr.showAnd
const onDelete = i => manager.onEmpty(i)
const emit = defineEmits(['empty'])
manager.on('empty', () => emit('empty'))
</script>
<template>
  <div class="MR__DateOrSession_ArrayElement" v-if="manager.sr.isReady.value">
    <div v-for="(type, i) in manager.dr.items" :key="manager.sr.holders[i]._id">
      <span />
      <TriggerOrAction :cmpTeller="manager.constructor.cmpTeller" v-model="manager.dr.items[i]" :options="manager.dr.options[i]" @empty="onEmpty(i)" @typechange="onTypeChange(i, $event)" :data="manager.sr.holders[i]" />
      <div class="MR__Delete" @click="onDelete(i)" />
    </div>
    <div class="MR__DateOrSession_ArrayElement__And" v-if="isAndAvailable"><div @click="onAnd"></div></div>
  </div>
</template>
<style lang="scss">
.MR__DateOrSession_ArrayElement {
  > div {
    display: flex;
    align-items: center;

    &:not(:first-child):not(.MR__DateOrSession_ArrayElement__And) {
      margin-top: 16px;
    }

    @extend %triggerActionSpan;

    > span {
      width: 42px;
      text-align: right;
      padding-right: 16px;
    }

    &:first-child > span {
      &::after {
        content: 'is';
      }
    }
    &:not(:first-child) > span {
      &::after {
        content: 'and';
      }
    }

    .MR__Item {
      > .v-select {
        margin-right: 16px;
      }
    }
  }

  &__And {
    position: relative;
    height: 40px;

    &::before {
      content: '';
      position: absolute;
      border-right: 1px solid $v3-border-color;
      bottom: 24px;
      height: 16px;
      left: 143px;
    }

    > div {
      @extend %and-button;
      display: block;
      height: 24px;
      position: absolute;
      bottom: 0px;
      left: 114px;
      cursor: pointer;
    }
  }
}
</style>
