export default {
  $id: '/tix/price_library/external',
  type: 'object',
  $defs: {
    create: {
      type: 'object',
      additionalProperties: false,
      properties: {
        name: {
          $ref: '/tix/core#/$defs/string_1_128'
        },
        description: {
          $ref: '/tix/core#/$defs/string_0_1024'
        },
        config: {
          additionalProperties: false,
          properties: {
            type: {
              enum: ['external_default']
            },
            rows: {
              type: 'array',
              items: {
                $ref: '#/$defs/row_external'
              },
              uniqueItems: true,
              minItems: 1,
              maxItems: 25
            }
          },
          required: ['type', 'rows']
        }
      },
      required: ['name', 'config']
    },
    modify: {
      type: 'object',
      additionalProperties: false,
      properties: {
        name: {
          $ref: '/tix/core#/$defs/string_1_128'
        },
        description: {
          $ref: '/tix/core#/$defs/string_0_1024'
        },
        config: {
          additionalProperties: true,
          properties: {
            rows: {
              type: 'array',
              items: {
                $ref: '#/$defs/row_external'
              },
              uniqueItems: true,
              minItems: 1,
              maxItems: 25
            }
          }
        }
      },
      required: ['name', 'config']
    },
    row_external: {
      type: 'object',
      additionalProperties: false,
      properties: {
        id: {
          $ref: '/tix/core#/$defs/guid'
        },
        name: {
          $ref: '/tix/core#/$defs/string_1_128'
        },
        phases: {
          type: 'array',
          items: {
            $ref: '#/$defs/row_phase_external'
          },
          minItems: 1,
          maxItems: 1
        }
      }
    },
    row_phase_external: {
      type: 'object',
      additionalProperties: true,
      properties: {
        nominal: {
          $ref: '/tix/core#/$defs/decimal'
        }
      },
      required: ['nominal']
    }
  }
}
