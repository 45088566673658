<script setup>
import ResourceList from '../ResourceList.vue'
import isReadOnly from './IsReadOnly'
import Sorter from '@old/Sorter'
import StringOrder from '@old/Sorter/StringOrder'
const readOnly = isReadOnly()
const sorter = new Sorter(new StringOrder('name'))
</script>
<template>
  <ResourceList heading="Salespoints" createButtonText="Create Salespoint" createRoute="create-pos" editRoute="edit-pos" resourceName="salespoint" emptyMessage="You currently have no salespoints created. Click the ‘Create Salespoint’ button to add a Salespoint." searchText="Search for salespoint name" :readOnly="readOnly" :sorter="sorter" />
</template>
