<script>
import moment from 'moment'
import {ref, watch, toRefs} from 'vue'
import Field from './Field/Field.vue'
import TimePicker from './TimePicker.vue'
import {timeFormat, lj} from '@/helpers/format'
const toM = t => moment(t, timeFormat)
const midNight = '00:00:00'
const defaultStart = '09:00:00'
const defaultEnd = '17:00:00'
const correct = (props, isStart, allDay) => {
  if (allDay.value) {
    return
  }
  const svk = props[isStart ? 'startKey' : 'endKey'] // set value key
  if (props.data[svk] === midNight) {
    // Special case. No correction is needed.
    return
  }
  const ovk = props[isStart ? 'endKey' : 'startKey'] // the other value key
  const sm = toM(props.data[svk]) // moment for the value that was just set by the user (either start or end)
  const om = toM(props.data[ovk]) // moment for the other value
  const cmp = isStart ? 'isAfter' : 'isBefore' // comparison function
  if (sm[cmp](om)) {
    // correction is required for the other value
    if ((isStart && sm.isSameOrAfter(toM('23:00:00'))) || (!isStart && sm.isSameOrBefore(toM('01:00:00')))) {
      if (props.data[ovk] !== midNight) {
        props.data[ovk] = midNight
      }
    } else {
      const dayBoundary = om.clone()[isStart ? 'endOf' : 'startOf']('day')
      const diff = Math.abs(dayBoundary.diff(om, 'minutes'))
      // new moment for the other value:
      props.data[ovk] = sm[isStart ? 'add' : 'subtract'](diff < 60 ? diff : 60, 'minutes').format(timeFormat)
    }
  }
}
export default {
  props: {
    data: {
      type: Object,
      required: true
    },
    useAllDay: {
      type: Boolean
    },
    startKey: {
      type: String,
      default: 'start'
    },
    endKey: {
      type: String,
      default: 'end'
    }
  },
  setup(props) {
    if (!props.data[props.startKey]) {
      props.data[props.startKey] = defaultStart
    }
    if (!props.data[props.endKey]) {
      props.data[props.endKey] = defaultEnd
    }
    const allDay = ref(props.data[props.startKey] === midNight && props.data[props.endKey] === midNight)
    const refs = toRefs(props.data)
    watch(refs[props.startKey], v => correct(props, true, allDay))
    watch(refs[props.endKey], () => correct(props, false, allDay))
    watch(allDay, isAllDay => {
      props.data[props.startKey] = isAllDay ? midNight : defaultStart
      props.data[props.endKey] = isAllDay ? midNight : defaultEnd
    })
    return {
      allDay
    }
  },
  components: {
    TimePicker,
    Field
  }
}
</script>
<template>
  <div class="MR__DateOrSession__TimeOfDay">
    <div class="MR__DateOrSession__TimeOfDay__TimeRange" :class="{DisabledUI: allDay}">
      <TimePicker v-model="data[startKey]" />
      <span>and</span>
      <TimePicker v-model="data[endKey]" />
    </div>
    <Field v-if="useAllDay" v-model="allDay" class="MR__DateOrSession__TimeOfDay__AllDay" type="checkbox" label="All day" />
  </div>
</template>
<style lang="scss">
.MR__DateOrSession__TimeOfDay {
  &__TimeRange {
    @extend %pairSpan;
    display: flex;
    align-items: center;
  }
  &__AllDay {
    margin-top: 8px;
    label span {
      margin: 0;
    }
  }
}
</style>
