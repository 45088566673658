<script>
// @cms-next src/components/elements/ClickAgainToConfirmButton.vue

import {ref, computed} from 'vue'
export default {
  props: {
    idleText: {
      type: String,
      required: true
    },
    checkPossibility: {
      type: Function
    },
    confirmText: {
      type: String,
      default: 'Confirm'
    },
    confirmTimeout: {
      type: Number,
      default: 1500
    }
  },
  emits: ['action'],
  setup(props, {emit}) {
    const state = ref('idle')
    const cls = computed(() => `buttonState-${state.value}`)
    const text = computed(() => (state.value === 'idle' ? props.idleText : props.confirmText))
    let confirmTimeout = false
    const onClick = ev => {
      if (state.value === 'idle') {
        if (props.checkPossibility && !props.checkPossibility()) {
          return
        }
        ev.stopPropagation()
        state.value = 'confirm'
        confirmTimeout = setTimeout(() => {
          state.value = 'idle'
          confirmTimeout = false
        }, props.confirmTimeout)
      } else if (state.value === 'confirm') {
        clearTimeout(confirmTimeout)
        confirmTimeout = false
        emit('action')
      }
    }
    return {
      onClick,
      cls,
      text
    }
  }
}
</script>
<template>
  <button :class="cls" @click="onClick" v-shtml="text" type="button" />
</template>
<style lang="scss">
.buttonState-confirm {
  background-color: $warning-color !important;
  color: white !important;
}
</style>
