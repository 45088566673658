<script>
import {computed, reactive, watch, toRefs, onUnmounted} from 'vue'
import Event from '@old/Event'
import field from '@/components/Field/HeldProperty.vue'
import {NonAlarmingError} from '@/api/error/Misc'
import KibanaURL from './KibanaURL.vue'
import AutomatedTask from './Class'
import {getSellerOptions} from './Class'
import {setError} from '@/helpers/ErrorHolder'
import {isPEM, isSSHPublicKey} from '@/helpers/comparison'
import GETCache from '@/classes/GETCache'
import {dClone, strip, dateFormat} from '@/helpers/format'
import {nowInContextTimezone} from '@/helpers/datetime'
import filtersc from './Filters/View.vue'

const stripEmpty = data => strip(data, (obj, k) => obj[k] === '')

const extraConfigFormFieldProps = field => {
  const props = {
    type: field.type,
    description: field.comment
  }
  if (['dropdown', 'radioenum'].includes(field.type)) {
    const opts = field.options
    props.options = typeof opts === 'function' ? opts() : opts
    props.multiple = field.multiple
  } else if (field.type === 'checkbox') {
    props.caption = field.title
  }
  return props
}

const keyCredValidation = [
  ['private_key', isPEM, '’Private key’ must be formatted as PEM (Privacy-Enhanced Mail)'],
  ['known_host', isSSHPublicKey, 'Known host must be formatted as Base64']
]

const deliveryTypes = [
  {code: 'email', label: 'Email'},
  {code: 'sftp', label: 'SFTP'}
]

const sftpAuthMethods = [
  {code: 'password', label: 'Password'},
  {code: 'key', label: 'Private key'}
]

const scheduleFrequencies = [
  {code: 'daily', label: 'Daily'},
  {code: 'weekly', label: 'Weekly'},
  {code: 'monthly', label: 'Monthly'}
]

const types = [
  {code: 'daily_finance', label: 'Daily Finance Report'},
  {code: 'all_members', label: 'All Members Report'},
  {code: 'donations', label: 'Donations Report'},
  {code: 'email_failures', label: 'Failed Emails Report'},
  {code: 'giftcards_with_balances', label: 'Gift Cards with Balances Report'},
  {code: 'identity_updates', label: 'Identity Updates Report'},
  {code: 'membership_updates', label: 'Member Updates Report'},
  {code: 'sold_tickets_2', label: 'Sold Tickets Report'},
  {code: 'daily_actions', label: 'Ticket Audit Report'},
  {code: 'daily_inventory', label: 'Event Sessions Report'},
  {code: 'daily_config', label: 'Ticket Type Report'}
]

const customIdentityFields = () => {
  const keys = Object.keys(window.config('config.custom_identity_fields') ?? {})
  const ignores = ['city', 'country', 'state', 'address', 'zip_code', 'phone']
  return keys.filter(k => !ignores.includes(k))
}

const gom_giftcard_groups = {
  name: 'gom_giftcard_groups',
  title: 'Gift of Membership Gift Card Groups',
  cssClasses: 'FieldWrapper__FullWidth',
  comment: 'Gift of Membership Gift Card Groups should be input as semicolon separated ticket groups. For any input ticket groups, the buyer will be replaced with the gift card buyer'
}

const reportTypesToExtraFieldsMap = {
  all_members: [
    {
      name: 'extra_meta',
      title: 'Extra meta',
      type: 'dropdown',
      multiple: true,
      options: customIdentityFields
    }
  ],
  donations: [gom_giftcard_groups],
  identity_updates: [
    {
      name: 'extra_meta',
      title: 'Extra meta',
      type: 'dropdown',
      multiple: true,
      options: customIdentityFields,
      comment: 'extra_meta should be a list of tags, represented as a comma-delimited string'
    }
  ],
  membership_updates: [
    gom_giftcard_groups,
    {
      name: 'subcategories',
      title: 'Sub categories',
      comment: 'Comma separated.'
    }
  ],
  sold_tickets_2: [
    {
      name: 'with_redeemed',
      cssClasses: 'FieldWrapper__FullWidth',
      type: 'radioenum',
      default: true,
      options: [
        {code: true, label: 'Include redeemed tickets'},
        {code: false, label: 'Exclude redeemed tickets'}
      ],
      comment: v => (v ? "Will include 'purchased', 'redeemed', 'refunded', 'transfer_from', 'transfer_to', 'unredeemed', 'force_redeemed', 'invalidated', 'revalidated'" : "Will include 'purchased', 'refunded', 'transfer_from', 'transfer_to', 'invalidated', 'revalidated'")
    }
  ]
}

const deliveryTypeDefaults = {
  email: {
    to: '',
    subject: ''
  },
  sftp: {
    address_template: '',
    credentials: {
      user: ''
    }
  }
}

const eventVisibilitiesFilterMeta = {
  options: () =>
    Event.getVisibilityOptions().then(vOps =>
      Array.from(vOps.entries()).map(e => ({
        code: e[0],
        label: e[1].title
      }))
    ),
  join: 'event_template'
}

const metaFilters = {
  daily_finance: {
    seller: {
      options: getSellerOptions,
      field: 'id',
      join: 'seller',
      multiple: false,
      optional: true
    }
  },
  daily_actions: {
    event: null,
    hidden_type: eventVisibilitiesFilterMeta,
    audit_action: {
      options: ['purchased', 'redeemed', 'force_redeemed', 'unredeemed', 'refunded', 'transfer_from', 'transfer_to', 'invalidated', 'revalidated', 'transfer_identity_from', 'transfer_identity_to'],
      join: 'ticket_audit'
    }
  },
  daily_inventory: {
    event: null,
    hidden_type: eventVisibilitiesFilterMeta
  },
  daily_config: {
    event: null,
    hidden_type: eventVisibilitiesFilterMeta
  }
}

let lastSFTPAuthMethod

const handleSFTPAuthMethodChange = (m, credentials) => {
  if (lastSFTPAuthMethod !== m) {
    // prevent double handing for the same change (can occur under some circumstances)
    if (m === 'password') {
      credentials.password = ''
      delete credentials.private_key
      delete credentials.known_host
    } else {
      credentials.private_key = ''
      delete credentials.password
    }
    lastSFTPAuthMethod = m
  }
}

const handleDeliveryTypeChange = (dt, data) => {
  delete data.destination[dt === 'email' ? 'sftp' : 'email']
  data.destination[dt] = dClone(deliveryTypeDefaults[dt])
  if (dt === 'sftp') {
    lastSFTPAuthMethod = null
    handleSFTPAuthMethodChange('password', data.destination[dt].credentials)
  }
}

const stripWhitespacesFromDelimiterSeparatedString = (string, delimiter = ',') => {
  return string
    .split(delimiter)
    .map(part => part.trim())
    .join(delimiter)
}

const transformSellerIDFilter = (data, flatten) => {
  if (data.trigger.filters) {
    const f = data.trigger.filters.find(f => f.field === 'id' && f.join === 'seller')
    if (f) {
      if (flatten && Array.isArray(f.in)) {
        f.in = f.in[0]
      } else if (!flatten && !Array.isArray(f.in)) {
        f.in = [f.in]
      }
    }
  }
}

export default {
  components: {
    field,
    KibanaURL,
    filtersc
  },
  props: {
    at: {
      type: AutomatedTask,
      required: true
    },
    // Kibana reports are way too similar to Scheduled Reports to create a separate component for them.
    // Rather, let's allow to have a "Kibana" flavour of this component.
    // Pass the type string in here so that we could see if this is a Kibana report or not:
    type: {
      type: String
    }
  },
  async setup(props) {
    // Collect event detachers to invoke them when the component is unmounted
    const toDetach = []
    onUnmounted(() => toDetach.forEach(off => off()))
    const all = await GETCache.get('webhook?v2')
    let isKibana = props.type === 'kibana_report'
    const uiData = reactive({
      deliveryType: 'email',
      sftpAuthMethod: '' // need to be present for the watcher below to initialise
    })
    const udataRefs = toRefs(uiData)
    // For storing extra config field values which dependent on the report types
    const extraConfigFieldValues = reactive({})
    const existingReportNames = props.at.id
      ? null
      : Array.from(all.values())
          .filter(r => r.intact.format?.scheduled_report?.report_name)
          .map(r => r.intact.format?.scheduled_report?.report_name)
    // Scheduled report types:
    const typeOptions = isKibana ? null : props.at.id ? types : types.filter(({code}) => !existingReportNames.includes(code))
    const data = props.at.dr.data
    const cursorDescription = computed(() => `The first report will be sent a day after the selected date, and ${data.trigger.cron.frequency} thereafter.`)
    const reportName = computed(() => data.format?.scheduled_report?.report_name)
    if (props.at.id) {
      isKibana = Boolean(data.format.kibana_report)
      if (!isKibana) {
        const tmpExtraConfigFields = data.format.scheduled_report[`${reportName.value}_config`] ?? {}
        let value
        for (const k of Object.keys(tmpExtraConfigFields)) {
          value = tmpExtraConfigFields[k]
          const fieldDef = reportTypesToExtraFieldsMap[reportName.value].find(f => f.name === k)
          if (fieldDef.multiple) {
            value = value.split(',').filter(i => i.trim() !== '')
          }
          extraConfigFieldValues[k] = value
        }
      }
      uiData.deliveryType = data.destination.sftp ? 'sftp' : 'email'
      if (data.destination.sftp) {
        uiData.sftpAuthMethod = data.destination.sftp.credentials.password ? 'password' : 'key'
      }
    } else {
      if (!isKibana) {
        data.format.scheduled_report.report_name = typeOptions[0].code
      }
      handleDeliveryTypeChange(uiData.deliveryType, data)
    }
    watch(udataRefs.deliveryType, dt => handleDeliveryTypeChange(dt, data))
    watch(udataRefs.sftpAuthMethod, m => handleSFTPAuthMethodChange(m, data.destination.sftp.credentials))
    const extraConfigFields = computed(() => reportTypesToExtraFieldsMap[reportName.value] ?? [])
    const extraConfigFieldError = field => {
      const config = data.format.scheduled_report[`${reportName.value}_config`]
      return config ? config[`_error:${field.name}`] : null
    }
    if (!props.at.id) {
      const reportTypeLabel = computed(() => {
        const type = types.find(t => t.code === reportName.value)
        return type ? type.label : ''
      })
      data.name = reportTypeLabel.value
      if (!isKibana) {
        const onReportNameChange = (nv, ov) => {
          delete data.format.scheduled_report[`${ov}_config`]
          // Remove residual extra config values, report type/name has changed.
          for (const k of Object.keys(extraConfigFieldValues)) {
            delete extraConfigFieldValues[k]
          }
          // Set all extra config fields to empty value because API
          // required them to be present
          for (const f of extraConfigFields.value) {
            extraConfigFieldValues[f.name] = f.default ?? (f.type === 'checkbox' ? false : '')
          }
          data.name = reportTypeLabel.value
        }
        watch(reportName, onReportNameChange)
        onReportNameChange()
      }
    }
    toDetach.push(
      props.at.on('beforesave', () => {
        // Some custom validation checks (see "keyCredValidation" above):
        const sftpCreds = data?.destination?.sftp?.credentials
        if (sftpCreds) {
          stripEmpty(sftpCreds)
          let isValid = true
          keyCredValidation.forEach(vc => {
            const value = sftpCreds[vc[0]]
            const isInValid = value && !vc[1](value)
            setError(sftpCreds, vc[0], isInValid ? vc[2] : false)
            if (isInValid) {
              isValid = false
            }
          })
          if (!isValid) {
            throw new NonAlarmingError('Invalid SFTP credentials')
          }
        }
        if (Object.keys(extraConfigFieldValues).length > 0) {
          data.format.scheduled_report[`${reportName.value}_config`] = Object.entries(extraConfigFieldValues).reduce((values, [key, value]) => {
            if (key === 'gom_giftcard_groups') {
              // Remove whitespaces surrounding guids because API doesn't allow it
              values[key] = stripWhitespacesFromDelimiterSeparatedString(value, ';')
            } else if (key === 'subcategories') {
              if (value) {
                // The same as above but the delimiter is `,`
                values[key] = stripWhitespacesFromDelimiterSeparatedString(value, ',')
              } else {
                // Get rid of the value altogether. When empty, JSON Schema validator still applies the pattern,
                // so do not let it try to apply it if there is nothing to apply it to
                // (otherwise it will report the data to be invalid):
                delete values[key]
              }
            } else if (Array.isArray(value)) {
              // Serialise array
              values[key] = value.join(',')
            } else {
              values[key] = value
            }
            return values
          }, {})
        }
      })
    )
    toDetach.push(
      // The seller filter here (unlike Standard Webhooks) is somewhat peculiar. It is saved as an array of GUIDs as if it was multiple,
      // yet the DDL must be single-select. But single-select DDLs do not input/output arrays: they work with primitive values.
      // So, instead of hacking the DDL component to optionally force array values (which is not needed anywhere else),
      // I think it is apt to transform the saved array data here to primitive before editing, and then transform back to array before saving.
      props.at.on('datatoedit', dataToEdit => transformSellerIDFilter(dataToEdit, true))
    )
    toDetach.push(
      props.at.on('candidatedata', candidateData => {
        // Do the reverse seller_id filter transformation. See the note above.
        transformSellerIDFilter(candidateData, false)
        if (uiData.deliveryType === 'email') {
          const emailData = candidateData.destination.email
          if (typeof emailData.cc === 'string') {
            if (emailData.cc.trim() !== '') {
              emailData.cc = emailData.cc.split(',').map(c => c.trim())
            } else {
              // Delete `cc` prop if it's empty to prevent it from being validated
              delete emailData.cc
            }
          }
        }
      })
    )
    // Workaround for showing a validation error for array items
    toDetach.push(
      props.at.on('non-object-holder-error', ed => {
        if (ed.holder === data.destination?.email?.cc) {
          setError(data.destination.email, 'cc', ed.message)
        }
      })
    )
    const onKibanaName = name => {
      if (!data.name) {
        data.name = name
      }
    }
    const dateLabel = isKibana ? 'Send first report on' : 'Reporting from the day ending'
    const startDate = isKibana ? nowInContextTimezone().format(dateFormat) : null
    return {
      data,
      uiData,
      typeOptions,
      cursorDescription,
      deliveryTypes,
      scheduleFrequencies,
      sftpAuthMethods,
      extraConfigFields,
      extraConfigFieldValues,
      extraConfigFormFieldProps,
      extraConfigFieldError,
      isKibana,
      onKibanaName,
      dateLabel,
      startDate,
      metaFilters
    }
  }
}
</script>

<template>
  <form class="SettingForm ScheduledReport">
    <slot name="forType"></slot>

    <div class="FieldWrapper" v-if="!isKibana">
      <label>Report type</label>
      <field type="dropdown" :options="typeOptions" :holder="data.format.scheduled_report" name="report_name" :disabled="!!at.id" :required="true" autoSelectFirstByDefault />
    </div>

    <hr />

    <template v-if="isKibana">
      <KibanaURL v-model="data.format.kibana_report.post_url" @name="onKibanaName" :at="at" />
      <div class="FieldWrapper">
        <label class="required">Display name</label>
        <field :holder="at.dr.data" name="name"></field>
      </div>
    </template>
    <template v-else>
      <slot name="forName"></slot>
      <filtersc :type="data.format.scheduled_report.report_name" :meta="metaFilters" :data="data" :eventIdJoin="true" subject="report" />
    </template>

    <div class="FieldWrapper">
      <label>Delivery type</label>
      <field type="dropdown" :holder="uiData" name="deliveryType" :options="deliveryTypes" :required="true" autoSelectFirstByDefault></field>
    </div>

    <template v-if="data.destination.email && uiData.deliveryType === 'email'">
      <div class="FieldWrappers">
        <div class="FieldWrapper">
          <label class="required">To</label>
          <field :holder="data.destination.email" name="to" description="Limited to a single email address."></field>
        </div>

        <div class="FieldWrapper">
          <label>CC (optional)</label>
          <field :holder="data.destination.email" name="cc" description="Single or multiple email addresses (comma separated)."></field>
        </div>
      </div>

      <div class="FieldWrapper">
        <label class="required">Subject</label>
        <field :holder="data.destination.email" name="subject"></field>
      </div>
    </template>

    <template v-if="data.destination.sftp && uiData.deliveryType === 'sftp'">
      <div class="FieldWrapper FieldWrapper__FullWidth">
        <label class="required">Address template</label>
        <field :holder="data.destination.sftp" name="address_template" type="string" description="An SFTP address containing a colon and port ':22' port'. The filename part is a template which should contain the variable '{{time}}'. Use '/~/' for relative paths. e.g. localhost:22/~/myreport-{{time}}.csv" />
      </div>

      <div class="FieldWrapper">
        <label class="required">Username</label>
        <field :holder="data.destination.sftp.credentials" name="user" />
      </div>

      <div class="FieldWrapper">
        <label>Authentication method</label>
        <field type="dropdown" :holder="uiData" name="sftpAuthMethod" :options="sftpAuthMethods" :required="true" autoSelectFirstByDefault></field>
      </div>

      <div class="FieldWrapper" v-if="uiData.sftpAuthMethod === 'password'">
        <label class="required">Password</label>
        <field :holder="data.destination.sftp.credentials" name="password" />
      </div>
      <div class="FieldWrapper FieldWrapper__FullWidth" v-else>
        <label class="required">Private key</label>
        <field type="text" :holder="data.destination.sftp.credentials" name="private_key" />
      </div>

      <div class="FieldWrapper" v-if="data.destination.sftp['_error:credentials']">
        <div class="Field3">
          <div class="errorHolder">{{ data.destination.sftp['_error:credentials'] }}</div>
        </div>
      </div>

      <div class="FieldWrapper FieldWrapper__FullWidth">
        <label>Known host (optional)</label>
        <field type="text" :holder="data.destination.sftp.credentials" name="known_host" />
      </div>
    </template>

    <div class="FieldWrappers">
      <div class="FieldWrapper">
        <label>Frequency</label>
        <field type="dropdown" :options="scheduleFrequencies" :holder="data.trigger.cron" name="frequency" description="The report will be sent at the frequency selected." :required="true" autoSelectFirstByDefault />
      </div>
      <div class="FieldWrapper DateFieldWrapper">
        <label class="required" v-shtml="dateLabel" />
        <field :holder="data.cursor" name="window_end" type="date" :description="cursorDescription" :startDate="startDate" />
      </div>
    </div>

    <template v-if="!isKibana">
      <div v-for="field in extraConfigFields" class="FieldWrapper" :class="[field.cssClasses, {hidden: field.hidden}]">
        <label v-if="field.title && field.type !== 'checkbox'" :class="{required: field.required}">{{ field.title }} {{ !field.required && '(optional)' }}</label>
        <field :holder="extraConfigFieldValues" :name="field.name" v-bind="extraConfigFormFieldProps(field)" :error="extraConfigFieldError(field)" />
      </div>
    </template>

    <slot name="bottom"></slot>
  </form>
</template>
<style lang="scss">
.WebhookForm {
  .RadioGroup {
    border: 0 !important;
    background-color: transparent !important;
    label {
      background-color: transparent !important;
      padding: 0 16px 4px 32px !important;
    }
    .Field3 {
      display: inline-block;
    }
  }
  .ScheduledReport .DateFieldWrapper .datetimepicker {
    width: 50%;
  }
}
</style>
