export default {
  $id: '/tix/message_rule',
  type: 'object',
  $defs: {
    create: {
      type: 'object',
      additionalProperties: false,
      properties: {
        name: {
          $ref: '/tix/core#/$defs/string_1_128'
        },
        description: {
          $ref: '/tix/core#/$defs/string_0_1024'
        },
        portal_id: {
          $ref: '/tix/core#/$defs/guid'
        },
        seller_id: {
          $ref: '/tix/core#/$defs/guid'
        },
        event_categories: {
          type: 'array',
          items: {
            $ref: '/tix/core#/$defs/string_1_64'
          },
          uniqueItems: true,
          minItems: 0,
          maxItems: 25
        },
        event_template_ids: {
          type: 'array',
          items: {
            $ref: '/tix/core#/$defs/guid'
          },
          uniqueItems: true,
          minItems: 0,
          maxItems: 200
        },
        triggers: {
          $ref: '#/$defs/triggers'
        },
        actions: {
          $ref: '#/$defs/actions'
        },
        disabled: {
          type: 'boolean'
        }
      },
      required: ['triggers', 'actions', 'portal_id', 'seller_id', 'name']
    },
    update: {
      type: 'object',
      additionalProperties: false,
      properties: {
        name: {
          $ref: '/tix/core#/$defs/string_1_128'
        },
        description: {
          $ref: '/tix/core#/$defs/string_0_1024'
        },
        event_categories: {
          type: 'array',
          items: {
            $ref: '/tix/core#/$defs/string_1_64'
          },
          uniqueItems: true,
          minItems: 0,
          maxItems: 25
        },
        event_template_ids: {
          type: 'array',
          items: {
            $ref: '/tix/core#/$defs/guid'
          },
          uniqueItems: true,
          minItems: 0,
          maxItems: 200
        },
        triggers: {
          $ref: '#/$defs/triggers'
        },
        actions: {
          $ref: '#/$defs/actions'
        },
        disabled: {
          type: 'boolean'
        }
      },
      minProperties: 1
    },
    triggers: {
      type: 'object',
      additionalProperties: false,
      properties: {
        quantity: {
          type: 'array',
          items: {
            $ref: '#/$defs/quantity_trigger'
          },
          uniqueItems: true,
          minItems: 1,
          maxItems: 25
        },
        date_or_session: {
          type: 'array',
          items: {
            $ref: '#/$defs/date_time_session_trigger'
          },
          uniqueItems: true,
          minItems: 1,
          maxItems: 25
        }
      },
      minProperties: 1
    },
    actions: {
      type: 'object',
      properties: {
        session_annotation: {
          $ref: '#/$defs/action_annotation'
        },
        session_message: {
          $ref: '#/$defs/action_message'
        },
        date_annotation: {
          $ref: '#/$defs/action_annotation'
        },
        date_message: {
          $ref: '#/$defs/action_message'
        },
        quantity_message: {
          $ref: '#/$defs/action_message'
        }
      },
      minProperties: 1
    },
    action_message: {
      type: 'object',
      additionalProperties: false,
      required: ['style', 'message'],
      properties: {
        style: {
          $ref: '#/$defs/action_style'
        },
        message: {
          $ref: '/tix/core#/$defs/string_2_1024'
        }
      }
    },
    action_annotation: {
      type: 'object',
      additionalProperties: false,
      required: ['style', 'message'],
      properties: {
        style: {
          $ref: '#/$defs/action_style'
        },
        message: {
          type: 'string',
          pattern: '^\\S([^<>]*\\S)?$',
          minLength: 2,
          maxLength: 50
        }
      }
    },
    action_style: {
      enum: ['info', 'warning', 'promo']
    },
    quantity_trigger: {
      type: 'object',
      additionalProperties: false,
      properties: {
        min: {
          type: 'integer',
          minimum: 0
        },
        max: {
          type: 'integer',
          minimum: 0
        },
        ticket_type_names: {
          type: 'array',
          items: {
            $ref: '/tix/core#/$defs/string_1_255'
          },
          uniqueItems: true,
          minItems: 1,
          maxItems: 100
        },
        ticket_group_names: {
          type: 'array',
          items: {
            $ref: '/tix/core#/$defs/string_1_255'
          },
          uniqueItems: true,
          minItems: 1,
          maxItems: 100
        },
        ticket_type_ids: {
          type: 'array',
          items: {
            $ref: '/tix/core#/$defs/guid'
          },
          uniqueItems: true,
          minItems: 1,
          maxItems: 100
        },
        ticket_group_ids: {
          type: 'array',
          items: {
            $ref: '/tix/core#/$defs/guid'
          },
          uniqueItems: true,
          minItems: 1,
          maxItems: 100
        }
      },
      not: {
        anyOf: [
          {
            required: ['ticket_type_names', 'ticket_type_ids']
          },
          {
            required: ['ticket_type_names', 'ticket_group_ids']
          },
          {
            required: ['ticket_group_names', 'ticket_type_ids']
          },
          {
            required: ['ticket_group_names', 'ticket_group_ids']
          }
        ]
      }
    },
    date_time_session_trigger: {
      type: 'object',
      additionalProperties: false,
      minProperties: 1,
      properties: {
        date_range: {
          type: 'object',
          additionalProperties: false,
          properties: {
            start: {
              type: 'string',
              format: 'date'
            },
            end: {
              type: 'string',
              format: 'date'
            }
          },
          required: ['start', 'end']
        },
        time_of_day: {
          type: 'object',
          additionalProperties: false,
          properties: {
            start: {
              $ref: '/tix/core#/$defs/time_of_day'
            },
            end: {
              $ref: '/tix/core#/$defs/time_of_day'
            }
          },
          required: ['start', 'end']
        },
        weekdays: {
          type: 'array',
          minItems: 1,
          maxItems: 7,
          uniqueItems: true,
          items: {
            type: 'integer',
            minimum: 0,
            maximum: 6
          }
        },
        session_indexes: {
          type: 'array',
          items: {
            type: 'integer',
            min: 0
          },
          uniqueItems: true,
          minItems: 1,
          maxItems: 500
        }
      }
    }
  }
}
