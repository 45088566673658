import {ref} from 'vue'
import {getFaCurrency, getCurrency} from '@/helpers/money'
import {addCfg, addWatch} from '@/helpers/vModelPassThrough'
import ClickAgainToConfirmButton from '../../ClickAgainToConfirmButton.vue'
import Modal from '@/components/Modal.vue'
import field from '@/components/Field/HeldProperty.vue'
import {setTooltip} from '@/helpers/ui'
import './shared.scss'
export default extra =>
  addCfg({
    props: {
      data: {
        type: Object,
        required: true
      }
    },
    emits: ['submitted', 'delete'],
    components: {
      Modal,
      field,
      ClickAgainToConfirmButton
    },
    setup(props, {emit}) {
      const show = addWatch(props, emit)
      const element = ref(null)
      const plainCurrencySymbol = getCurrency()
      const currencySymbol = getFaCurrency()
      let setup
      const onSubmitted = () => emit('submitted', setup)
      const onDelete = () => emit('delete', setup)
      setup = {
        data: props.data,
        show,
        element,
        currencySymbol,
        plainCurrencySymbol,
        onSubmitted,
        onDelete
      }
      if (extra) {
        if (typeof extra === 'function') {
          setup = extra(setup)
        } else {
          setup = {...extra, ...setup}
        }
      }
      setup.onMounted =
        setup.disableDeleteUnlessDefaultPhase && !props.data.isCurrentPhase
          ? dialogEl =>
              setTooltip(dialogEl, {
                selector: '.DeleteButton',
                viewport: dialogEl,
                title: setup.disableDeleteUnlessDefaultPhase
              })
          : false
      if (setup.name) {
        // Detect whether we are creating or editing:
        if (setup.isEdit === undefined) {
          setup.isEdit = Boolean(props.data.name)
        }
        setup.header = `${setup.isEdit ? 'Edit' : 'Add'} ${setup.name}`
      }
      return setup
    }
  })
