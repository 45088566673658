<script setup>
import Manager from './Manager'
import View from '../TwoProngForkDDL/View.vue'
import field from '../../../Field/HeldProperty.vue'
const props = defineProps({
  data: {
    type: Object,
    required: true
  }
})
if (props.data.min === undefined && props.data.max === undefined) {
  // Default
  props.data.min = 1
}
</script>
<template>
  <View :data="data" :managerClass="Manager" :prongsAnded="true">
    <div class="MR__Trigger__Quantities">
      <span>is min</span>
      <field :holder="data" name="min" type="number" />
      <span>max</span>
      <field :holder="data" name="max" type="number" />
    </div>
  </View>
</template>
<style lang="scss">
.MR__Trigger__Quantities {
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  @extend %triggerActionSpan;

  input {
    width: 62px !important;
  }

  > * {
    margin-left: 16px;
  }
}
</style>
