<script>
import makeModalCmp from './Abstract'
import {setError, cleanErrors} from '@/helpers/ErrorHolder'
import {strip} from '@/helpers/format'
export default makeModalCmp(setup => {
  const isDefault = Boolean(setup.data.isDefault)
  const toCheck = ['name']
  if (!isDefault) {
    toCheck.push('start_date')
  }
  const phases = setup.data.phases
  const theIndex = setup.data.theIndex // will be undefined when adding a new phase, this is okay
  return {
    onBeforeSubmit: () => {
      strip(setup.data, cleanErrors, true)
      // This toCheck.some will return TRUE if there is at least one error
      // Return TRUE to indicate that everything is fine, FALSE otherwise
      return !toCheck.some(k => {
        if (!Boolean(setup.data[k])) {
          setError(setup.data, k)
          return true
        }
        if (phases.some((p, i) => i !== theIndex && p[k] === setup.data[k])) {
          setError(setup.data, k, 'Another price change with this value already exists')
          return true
        }
        return false // no errors
      })
    },
    nameLabel: isDefault ? 'Name' : 'Price change name',
    isDefault,
    name: isDefault ? 'default phase name' : 'Price Change',
    ...setup
  }
})
</script>
<template>
  <Modal v-model="show" submitText="Confirm" class="PriceLibModal PriceChange" id="PriceChange" :trapFocus="isEdit" @submitted="onSubmitted" :onBeforeSubmit="onBeforeSubmit">
    <template #header>{{ header }}</template>
    <div class="PriceChange__name">
      <label class="required">{{ nameLabel }}</label>
      <field :holder="data" name="name" />
    </div>
    <div class="PriceChange__date" v-if="!isDefault">
      <label class="required">Price change date</label>
      <field :holder="data" name="start_date" type="date" :options="{container: '#PriceChange'}" />
    </div>
  </Modal>
</template>
<style lang="scss">
.PriceChange {
  &__date {
    margin-top: 16px;
    .Field3__Container {
      width: 50%;
    }
  }
  .Field3__Container > input,
  .date-manual {
    width: 100%;
  }
}
</style>
