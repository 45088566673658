<script setup>
import {computed} from 'vue'
import {useRoute} from 'vue-router'
import UnauthenticatedView from '@/components/UnauthenticatedView.vue'
import UnauthorisedView from '@/components/UnauthorisedView.vue'
import NotFoundView from '@/routeComponents/NotFoundRoute.vue'
import Modal from '@/components/Modal.vue'
import modalSingleton from '@/classes/ModalSingleton'
import toast from '@/classes/ToastSingleton'
import Vue1Teleporter from '@/components/Vue1Teleporter.vue'
const route = useRoute()
// Avoid showing unnamed routes. When they're unnamed, it typically means
// there is a corresponding Vue1 route that needs to be shown instead.
const toShow = computed(() => route.name !== undefined)
const props = defineProps({
  authenticated: {
    default: false
  },
  notFound: {
    default: false
  },
  authorised: {
    required: true
  },
  prevAuthorised: {
    default: null
  }
})
</script>
<template>
  <RouterView v-if="$route.name === 'okta-callback'"></RouterView>
  <RouterView v-else-if="$route.name === 'entra-callback'"></RouterView>
  <UnauthenticatedView v-else-if="!props.authenticated.value"></UnauthenticatedView>
  <UnauthorisedView v-else-if="props.authorised.value === false" :prevAuthorised="prevAuthorised"></UnauthorisedView>
  <NotFoundView v-else-if="props.notFound.value === true"></NotFoundView>
  <Suspense v-else-if="toShow">
    <RouterView />
  </Suspense>
  <Teleport to="body" v-if="modalSingleton.sr.isOn">
    <component v-if="modalSingleton.sr.modalCmp" :is="modalSingleton.sr.modalCmp" v-model="modalSingleton.sr.isOn" v-bind="modalSingleton.sr.props" />
    <Modal v-else id="modalSingleton" v-model="modalSingleton.sr.isOn" v-bind="modalSingleton.sr">
      <template #header><span v-shtml="modalSingleton.sr.header" /></template>
      <component v-if="modalSingleton.sr.cmp" :is="modalSingleton.sr.cmp" v-bind="modalSingleton.sr.props" />
      <div v-else v-html="modalSingleton.sr.body" />
    </Modal>
  </Teleport>
  <Teleport :to="toast.sr.container" v-if="toast.sr.isOn">
    <div :class="toast.sr.cls" role="alert">
      <div v-shtml="toast.sr.message" />
      <button type="button" class="close" @click.prevent="toast.sr.isOn = false" aria-label="Close" v-if="toast.sr.closeable"><span aria-hidden="true">&times;</span></button>
    </div>
  </Teleport>
  <Vue1Teleporter />
</template>
