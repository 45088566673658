// @cms-next src/helpers/settings/config.ts

import type {ConfigTemplateEntity, NameAndSpace, ConfigValue} from '@/api/config'
import type {JSONSchema4} from 'json-schema'
import type {ResourceName} from '@/api/response'
import {fetchConfigValues, fetchConfigValue} from '@/config/api'
import {getConfigTemplate, ConfigTemplate, getConfigTemplates} from '@/config/templatesStore'
import type {Dict} from '@/helpers/types'

export async function getConfigValuesAndInitializeTemplates(resource: ResourceName, resourceId: string): Promise<Dict<ConfigValue>> {
  // In parallel
  const [values, _] = await Promise.all([getValues(resource, resourceId), getConfigTemplates(resource)])
  return values
}

async function getValues(resource: ResourceName, resourceId: string): Promise<Dict<ConfigValue>> {
  const result = {}
  for (const value of await fetchConfigValues(resource, resourceId)) {
    const path = configItemPath(value)
    result[path] = value.value
  }
  return result
}

export async function getConfigValueAndTemplate(resource: ResourceName, resourceId: string, namespace: string, name: string): Promise<[ConfigValue, ConfigTemplate]> {
  // In parallel
  return await Promise.all([fetchConfigValue(resource, resourceId, namespace, name), getConfigTemplate({resource, name, namespace})])
}

export function configItemPath(entity: NameAndSpace): string {
  return `${entity.namespace}/${entity.name}`
}

export type ConfigEditors = 'checkbox' | 'survey' | 'json'

export function determineEditor(template: ConfigTemplateEntity): ConfigEditors {
  if (template.schema.type == 'boolean') return 'checkbox'

  if (template.schema.type === 'object') {
    for (const property of Object.values(template.schema.properties as JSONSchema4['properties'])) {
      if (property.type === 'object') return 'json'
    }
  }

  return 'survey'
}

export function formatValueForPreview(value: ConfigValue): string {
  if (value === undefined) return ''
  else if (isPrimitive(value)) return String(value)
  else return JSON.stringify(value, undefined, 2)
}

function isPrimitive(value: any): boolean {
  const type = typeof value
  return type != 'object' && type != 'function'
}

export function getResourceID(resource: ResourceName, resourceId: string): string {
  if (resource === 'portal') {
    // theApp is not initialized in time for the router to access it on cold loads.
    return window.theApp.portal.id
  } else {
    return resourceId
  }
}
