<script setup lang="ts">
// @cms-next /components/config/ConfigListItem.vue
import KebabMenu from '@/components/KebabMenu.vue'
import ClickAgainToConfirmButton from '@/components/ClickAgainToConfirmButton.vue'
import {deleteValue} from '@/config/api'
import type {ConfigTemplate} from '@/config/templatesStore'
import {computed} from 'vue'
import type {ConfigValue} from '@/api/config'
import {formatValueForPreview} from '@/config/helpers'
import CodeViewer from '@/components/CodeViewer.vue'

declare let theApp

//
// An item in the list of config templates/values on the config list route.
//

export interface ConfigItemProps {
  path: string
  resourceId: string
  template: ConfigTemplate
  value: ConfigValue
  showValue: boolean
}

const props = defineProps<ConfigItemProps>()
const emit = defineEmits<{(e: 'deleted'): void}>()

const mayEdit = props.template.current_user_may_set
const configured = computed(() => props.value != null)
const preview = computed(() => formatValueForPreview(props.value))

const detailsRoute = computed<Object>(() => {
  const {name, namespace, resource} = props.template
  const to = {
    path: `config/${namespace}/${name}`,
    query: null
  }
  if (theApp.seller) {
    to.query = {
      seller: theApp.seller.id
    }
  } else if (resource == 'seller') {
    to.query = {
      seller: props.resourceId
    }
  }
  return to
})

function remove() {
  deleteValue(props.resourceId, props.template).then(() => emit('deleted'))
}
</script>

<template>
  <div class="config-list-item" :class="{configured}">
    <div class="row">
      <div class="grow">
        <h4>
          <router-link v-if="configured || mayEdit" :to="detailsRoute">{{ template.title }}</router-link>
          <template v-else>{{ template.title }}</template>
        </h4>
        <p class="description" v-shtml="template.description" />
      </div>

      <div v-if="configured" class="status">
        <span class="pill configured">Configured</span>
      </div>

      <KebabMenu v-if="configured && mayEdit">
        <router-link :to="detailsRoute">Edit</router-link>
        <ClickAgainToConfirmButton v-if="configured && mayEdit" idleText="Delete" @action="remove" />
      </KebabMenu>
      <router-link v-else-if="mayEdit" :to="detailsRoute">Add</router-link>
      <router-link v-else-if="configured" :to="detailsRoute">View</router-link>
    </div>

    <div v-if="showValue && preview" class="preview">
      <CodeViewer :code="preview" :unstyled="true" />
    </div>
  </div>
</template>

<style scoped lang="scss">
.config-list-item {
  background: #ffffff80;
  &.configured {
    background: white;
  }

  > * {
    padding: 16px;
  }

  .row {
    display: flex;
    align-items: center;
    gap: 24px;

    .grow {
      flex-grow: 1;
    }

    h4 {
      margin: 0 0 6px;
      font-size: 14px;
      font-weight: 600;
    }

    p {
      margin: 0;
    }

    margin-bottom: 0;
    transition: margin-bottom 0.25s ease-in-out;
  }

  .preview {
    padding: 3px 5px;
    border-top: 1px solid #e6e8e8;
  }
}
// TODO Replace with .Status
// @see https://github.com/stqry/ticketing-cms/blob/2f8a686804f587aa1c1eccf80371fbb5e1ac482a/resources/assets/sass/_components/_Status.scss
.status .pill {
  display: block;
  padding: 3px 10px;
  font-weight: 600;
  font-size: 10px;
  text-transform: uppercase;
  border-radius: 16px;
  background: $gray-dark;
  color: $yet-another-grey;
  background: #eff0f5;

  &.configured {
    background: #cce8fb;
    color: #0071bc;
  }
}
</style>
