<template>
  <div class="CodeEditor" :style="style">
    <vue-monaco-editor v-model:value="code" :language="language" theme="vs-dark" :options="options" />
  </div>
</template>

<script lang="ts">
// @cms-next src/components/elements/CodeEditor.vue
import {PropType, ref, watch} from 'vue'
export type SupportedLanguage = 'javascript' | 'html' | 'css' | 'json'

export default {
  props: {
    language: {
      type: String as PropType<SupportedLanguage>,
      default: 'json',
      required: false
    },
    modelValue: String,
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '300px'
    }
  },

  setup(props, {emit}) {
    const code = ref(props.modelValue)

    watch(code, v => {
      emit('update:modelValue', v)
    })

    return {
      code,
      options: {miniMap: false},
      style: {
        width: props.width,
        height: props.height
      }
    }
  }
}
</script>
