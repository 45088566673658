<script>
export default {
  props: {
    data: {
      type: Object,
      required: true
    },
    showSellers: {
      type: Boolean
    }
  }
}
</script>
<template>
  <div class="Stripe__Account" :class="{Default: data.isDefault}">
    <div class="Stripe__Details">
      <h3>{{ data.details.name }}</h3>
      <div class="Stripe__Details__Columns">
        <div>
          <label>Account ID</label>
          {{ data.account_id }}
        </div>
        <div>
          <label>Account Status</label>
          <div :class="data.status.account">{{ data.status.account }}</div>
        </div>
        <div>
          <label>Payment Status</label>
          <div :class="data.status.payment">{{ data.status.payment }}</div>
        </div>
      </div>
    </div>
    <div class="Stripe__Sellers" v-if="showSellers">
      <div v-for="seller in data.sellers">
        <div>{{ seller.name }}</div>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
.Stripe {
  &__Details,
  &__Account {
    &.Default h3:after {
      content: 'Default';
      background-color: #cce8fb;
      text-transform: uppercase;
      color: #0071bc;
      font-size: 10px;
      font-weight: 700;
      border-radius: 14px;
      padding: 6px 10px;
      margin-left: 16px;
    }
  }

  &__Sellers {
    &:empty {
      display: none;
    }
    display: flex;
    flex-direction: column;
    padding: 8px;
    > div {
      margin: 8px;
      background-color: white;
      padding: 10px 4px 10px 16px;
      font-size: 16px;
      font-weight: 600;
      color: $header-colour;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 52px;

      .EditOptions {
        position: static;

        li {
          font-size: 14px;
          font-weight: 400;
          padding: 8px 16px;
          color: $header-colour;
          cursor: pointer;
          white-space: nowrap;
          span {
            @include fontawesome();
          }
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}
</style>
