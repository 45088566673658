<script setup lang="ts">
// @cms-next src/components/forms/SchemaFormBuilder.vue
import Field from '@/components/Field/Field.vue'
import {computed, nextTick} from 'vue'
import type {JSONSchema4} from 'json-schema'
import {schemaToFieldType, initialDefaultValue, fieldOptions} from './SurveyHelpers'
import type {ConfigValue} from '@/api/config'
import type {FieldComponentProps} from '@/components/Field/Types'
import {isEmpty} from '@/helpers/comparison'

//
// Recursively renders forms from a JSON Schema. Cannot handle arrays.
//

interface Props {
  name?: string
  namespace: string
  schema: JSONSchema4
  modelValue: ConfigValue
  readOnly: boolean
}
const props = defineProps<Props>()
const emit = defineEmits(['update:model-value'])

const v = computed<ConfigValue>({
  get() {
    if (props.schema.type === 'object' || props.modelValue !== undefined) {
      return props.modelValue
    } else {
      return null
    }
  },
  set(value) {
    emit('update:model-value', value)
  }
})

const vString = computed({
  get() {
    return v.value
  },
  set(input: string) {
    v.value = input === null || input === '' ? undefined : input
  }
})

const vStringList = computed({
  get() {
    return v.value ? (v.value as string[]).join('\n') : ''
  },
  set(input: string) {
    const value = input.split('\n').filter(item => item !== '')
    v.value = value.length === 0 ? undefined : value
  }
})

const {schema, readOnly} = props

if (v.value === undefined) {
  v.value = initialDefaultValue(schema)
}

const fieldComponentType = computed(() => schemaToFieldType(schema))

// Vue doesn't update the model value after the setup() hook has completed
nextTick(() => {
  if (fieldComponentType.value === 'checkbox') {
    // Ensure checkboxes always have a value set even if the user never touches the checkbox.
    emit('update:model-value', Boolean(v.value ?? props.schema.default))
  } else if (!isEmpty(props.schema.default)) {
    // Ensure the default value is set as expected
    emit('update:model-value', v.value ?? props.schema.default)
  }
})

const fieldComponentProps = computed<FieldComponentProps>(() => ({
  id: fullName.value,
  type: fieldComponentType.value,
  label: schema.label,
  description: schema.description,
  required: schema.required as boolean,
  options: fieldOptions(schema)
}))

const fieldLabelProps = computed(() => ({
  class: {required: Boolean(schema.required)},
  for: fullName.value
}))

interface Child {
  name: string
  namespace: string
  schema: JSONSchema4
}
const childProperties = computed<Child[]>(() => {
  const required = new Set(schema.required as string[])
  const namespace = fullName.value
  const parent = props.schema

  const result: Child[] = []
  for (const name in parent.properties) {
    const schema = {
      ...parent.properties[name],
      required: required.has(name)
    }
    result.push({name, namespace, schema})
  }
  return result.sort((a, b) => a.schema.propertyOrder - b.schema.propertyOrder)
})

const label = computed(() => schema.label ?? schema.title ?? props.name)

const fullName = computed(() => {
  const {name, namespace} = props
  if (name) return `${namespace}.${name}`
  else return namespace
})
</script>

<template>
  <template v-if="v === undefined" />

  <template v-else-if="fieldComponentType === 'checkbox'">
    <!-- Never mark checkboxes as required, since they always have a value. -->
    <label>{{ label }}</label>
    <Field v-model="v" type="checkbox" :label="schema.description" :required="schema.required" :disabled="readOnly" />
  </template>

  <template v-else-if="fieldComponentType === 'text' && schema.type === 'array'">
    <label v-bind="fieldLabelProps">{{ label }}</label>
    <Field v-model.trim="vStringList" v-bind="fieldComponentProps" :disabled="readOnly" />
  </template>

  <template v-else-if="fieldComponentType">
    <label v-bind="fieldLabelProps">{{ label }}</label>
    <Field v-model.trim="vString" v-bind="fieldComponentProps" :disabled="readOnly" />
  </template>

  <div v-else-if="schema.type == 'object'" v-for="child in childProperties" class="survey-question">
    <!-- Recursion!! -->
    <Survey v-model="v[child.name]" v-bind="child" :readOnly="readOnly" />
  </div>

  <div v-else>Not supported.</div>
</template>

<style scoped lang="scss">
.survey-question {
  margin-top: 1em;
}
</style>

<style lang="scss">
.Field3 .Checkbox span.label {
  font-weight: normal;
}
input {
  width: 100%;
}
</style>
