import TriggerOrActionManager from './TriggerOrActionManager'

class UniqueOptionsManager extends TriggerOrActionManager {
  getDefaultHolder() {
    return {}
  }

  makeHolders() {
    const holders = []
    const h = this.getHolder()
    this.dr.items.forEach(v => {
      let holder
      if (v) {
        if (!h[v]) {
          h[v] = this.getDefaultHolder(v)
        }
        holder = h[v]
      } else {
        // No value selected yet, but a holder must exist for uniformity.
        // Make a temporary stub:
        holder = {}
      }
      holders.push(this.ensureHolderId(holder))
    })
    return holders
  }

  readData() {
    const h = this.getHolder()
    this.constructor.OPTIONS.forEach((label, k) => {
      if (h && h[k]) {
        this.add(k)
      }
    })
  }

  onAfterRemove(value) {
    super.onAfterRemove(value)
    delete this.getHolder()[value]
  }

  onTypeChange(index, newType, oldType) {
    // All options are unique. No arrays/repetitions. The data structure is the same for all types.
    // So, when an action type changes, we keep the data but rename the holding key:
    const holder = this.getHolder()
    if (oldType && holder[oldType]) {
      holder[newType] = holder[oldType]
      delete holder[oldType]
    }
  }
}

export default UniqueOptionsManager
