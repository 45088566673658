// Handy switch for the beforeunload prompt.
// https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event

class LeaveWarning {
  static #on = false

  static setOn(isOn) {
    if ((isOn && this.#on) || (!isOn && !this.#on)) {
      // nothing to do
      return
    }
    window[isOn ? 'addEventListener' : 'removeEventListener']('beforeunload', this.#listener, {capture: true})
    this.#on = Boolean(isOn)
  }

  static #listener(e) {
    // This way the prompt will be shown
    e.returnValue = false
    return false
  }
}

export default LeaveWarning
