<template>
  <div class="SettingForm">
    <div class="Settings21__Heading">
      <h2>{{ mode === 'create' ? 'Create' : 'Edit' }} Staff Member</h2>
      <span v-if="mode === 'update'" class="Status" :class="getStatusClass(status)">{{ status }}</span>
    </div>
    <form @submit.prevent="save">
      <div class="FieldWrappers NameField">
        <div class="FieldWrapper FieldWrapper">
          <label class="required">First Name</label>
          <Field :holder="formData" name="first_name" />
        </div>

        <div class="FieldWrapper FieldWrapper">
          <label class="required">Last Name</label>
          <Field :holder="formData" name="last_name" />
        </div>
      </div>

      <div class="FieldWrapper FieldWrapper__FullWidth">
        <label class="required">Email Address</label>
        <Field :holder="formData" name="email" />
      </div>

      <div class="FieldWrapper" v-if="isSsoSupported">
        <label>SSO Identifier (optional)</label>
        <Field :holder="formData" name="sso_identifier" />
      </div>

      <div class="FieldWrapper FieldWrapper__FullWidth">
        <label>Note (optional)</label>
        <Field :holder="formData" name="note" type="text" />
      </div>

      <hr />
      <div class="FieldWrapper FieldWrapper__FullWidth">
        <label class="heading">Staff Groups</label>
        <p class="description">Choose which Staff Groups you want this Staff Member to belong to.</p>
        <Field v-for="group in groups" :holder="uiFormData.groups" :name="group.id" type="checkbox" :caption="group.name" />
      </div>

      <div class="FieldWrapper FieldWrapper__FullWidth" v-if="mode === 'create'">
        <label class="heading">Invitation</label>
        <p class="description">Choose whether to send an invitation to the staff member asking them to set a password and log in.</p>
        <Field :holder="uiFormData" name="invite" type="checkbox" caption="Send invitation email" />
      </div>

      <div v-if="mode === 'update'">
        <hr />

        <div class="FieldWrapper FieldWrapper__FullWidth">
          <label class="heading">Created</label>
          <p class="icon-tick">This staff member was created on {{ formatDate(memberData.created_at) }}</p>
        </div>
        <hr />

        <div class="FieldWrapper FieldWrapper__FullWidth">
          <label class="heading">Last Invitation</label>
          <p v-if="memberData.invited_at" class="icon-tick">An email invitation was last sent on {{ formatDate(memberData.invited_at) }}</p>
          <p v-else class="icon-cross">An email invitation has yet to be sent</p>
        </div>
        <hr />

        <div class="FieldWrapper FieldWrapper__FullWidth">
          <label class="heading">Password Set</label>
          <div v-if="memberData.password_set_at">
            <p class="icon-tick">A password was last set on {{ formatDate(memberData.password_set_at) }}</p>
            <p><a href="#" @click.prevent="sendResetPasswordEmail">Send reset password email</a></p>
          </div>
          <p v-else class="icon-cross">A password has not yet been set</p>
        </div>
        <hr />

        <div class="FieldWrapper FieldWrapper__FullWidth">
          <label class="heading">Last Log-in</label>
          <p v-if="memberData.last_login_at" class="icon-tick">A successful login last happened {{ formatDate(memberData.last_login_at) }}</p>
          <p v-else class="icon-cross">A successful log-in has yet to happen</p>
        </div>
      </div>

      <div class="Form__Actions">
        <button class="btn btn-blue-cancel uppercase" @click.prevent="cancel">Cancel</button>
        <button class="btn btn-primary uppercase" type="submit" :disabled="!canSubmit">Save Staff Member</button>
      </div>
    </form>
  </div>
</template>
<script lang="ts">
import Field from '@/components/Field/HeldProperty.vue'
import toast from '@/classes/ToastSingleton'
import {computed, reactive, Ref, ref} from 'vue'
import {useRouter} from 'vue-router'
import {dClone, copy, fullDateTimeFormat} from '@/helpers/format'
import {getMemberGroups, getStatusClass, formatState} from './StaffMembers.vue'
import type {StaffMember, StaffGroup} from '@/components/Staff/types'
import {isoToMomentInTenantTimezone} from '@/helpers/datetime'
import {sendEmail} from '@/components/Staff/StaffMembers.vue'
import {isSsoSupported} from '@/helpers/access'

const formatDate = (date: string) => {
  return isoToMomentInTenantTimezone(date).format(fullDateTimeFormat)
}

export default {
  components: {Field},

  props: {
    id: {
      type: String
    }
  },

  setup(props) {
    const router = useRouter()
    const mode = props.id ? 'update' : 'create'
    const formData = reactive({
      first_name: '',
      last_name: '',
      email: '',
      note: '',
      sso_identifier: ''
    })
    const memberData: Ref<StaffMember> = ref({} as StaffMember)

    const uiFormData = reactive({
      groups: {},
      invite: false
    })
    const groups = ref([])

    window.APIService.get('staff_group').then(resp => {
      groups.value = resp.staff_group._data

      if (mode === 'update') {
        window.APIService.get(`staff/${props.id}?_embed=staff_group,staff_group_staff,identity,identity.meta`).then(resp => {
          const member: StaffMember = resp.staff._data[0]
          const groups = resp.staff_group._data
          const membersGroups = resp.staff_group_staff._data
          const memberGroups: Array<StaffGroup> = getMemberGroups(member.id, groups, membersGroups)

          memberData.value = member

          // Load data onto form
          copy(member, formData, ['first_name', 'last_name', 'email', 'note', 'sso_identifier'])

          for (const g of memberGroups) {
            uiFormData.groups[g.id] = true
          }
        })
      }
    })

    const cancel = () => router.push({name: 'staff-members'})
    const save = () => {
      const success = () => {
        const msg = `<strong>Staff member was successfully ${mode}d!</strong>`
        toast.success(msg)
        cancel()
      }

      const candidateData = dClone(formData)
      candidateData.staff_group_ids = Object.entries(uiFormData.groups)
        .filter(([_, checked]) => checked)
        .map(([id]) => id)

      const method = mode === 'create' ? 'post' : 'patch'
      const url = mode === 'create' ? 'staff' : `staff/${props.id}`

      if (!candidateData.sso_identifier) {
        delete candidateData.sso_identifier
      }

      window.APIService[method](`${url}?_embed=staff_group,staff_group_staff,identity,identity.meta`, candidateData).then(resp => {
        const requests = []

        if (uiFormData.invite) {
          requests.push(
            window.APIService.post('password_reset/request', {
              provider: 'staff',
              email: candidateData.email
            })
          )
        }

        Promise.all(requests).then(() => {
          success()
        })
      })
    }

    const canSubmit = computed(() => {
      const anyGroupsSelected = Object.values(uiFormData.groups).some(g => g === true)
      return formData.first_name.trim() !== '' && formData.last_name.trim() !== '' && formData.email.trim() !== '' && anyGroupsSelected
    })

    const status = computed(() => (memberData.value ? formatState(memberData.value) : ''))

    const sendResetPasswordEmail = () => sendEmail(memberData.value)

    return {
      mode,
      memberData,
      formData,
      uiFormData,
      cancel,
      save,
      groups,
      formatDate,
      getStatusClass,
      formatState,
      canSubmit,
      status,
      sendResetPasswordEmail,
      isSsoSupported: isSsoSupported()
    }
  }
}
</script>

<style lang="scss">
.icon {
  &-tick,
  &-cross {
    padding-left: 25px;
    background-repeat: no-repeat;
  }

  &-tick,
  &-cross {
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.1053 7L8.15789 13.9474L5 10.7895' stroke='%236DBF13' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  }

  &-cross {
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14 6L6 14' stroke='%23FF3552' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M6 6L14 14' stroke='%23FF3552' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  }
}
</style>
