<script setup>
// @cms-next src/components/forms/WeekdaySelector.vue
import {reactive, watch} from 'vue'
const props = defineProps({
  data: {
    type: Array,
    required: true
  }
})
if (!props.data.length) {
  // By default, select them all
  let i = 0
  while (i <= 6) {
    props.data.push(i)
    i++
  }
}
const letters = ['S', 'M', 'T', 'W', 'T', 'F', 'S']
let internal = []
letters.forEach((l, i) => internal.push(false))
internal = reactive(internal)
const input = () => {
  letters.forEach((l, i) => {
    internal[i] = props.data.includes(i)
  })
}
const output = () => {
  const v = []
  internal.forEach((isSet, i) => {
    if (isSet) {
      v.push(i)
    }
  })
  if (v.join() !== props.data.join()) {
    props.data.splice(0, props.data.length, ...v)
  }
}
const onClick = i => {
  if (internal[i] && internal.filter(v => v).length === 1) {
    // Don't let to unselect the last one selected
    return
  }
  internal[i] = !internal[i]
}
input()
watch(internal, output)
// TODO: Watch props.data too if outside changes need to reflect on this component's state.
</script>
<template>
  <div class="WeekdaySelector2">
    <div v-for="(l, i) in letters" :class="{on: internal[i]}" @click="onClick(i)">{{ l }}</div>
  </div>
</template>
<style lang="scss">
.WeekdaySelector2 {
  display: flex;
  align-items: stretch;
  height: 36px;
  > div {
    font-weight: 600;
    font-size: 14px;
    width: 36px;
    display: flex;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    border: 1px solid $v3-border-color;
    &:not(:first-child) {
      margin-left: 4px;
    }
    &.on {
      border-color: $v3-blue;
      background-color: $v3-blue;
      color: white;
    }
  }
}
</style>
