<script>
import {ref} from 'vue'
import toast from '@/classes/ToastSingleton'
import AutomatedTask from '@/components/AutomatedTask/Class'
import scheduled_report from '@/components/AutomatedTask/ScheduledReport.vue'
import standard_webhook from '@/components/AutomatedTask/StandardWebhook.vue'
import salesforce from '@/components/AutomatedTask/Salesforce.vue'
import autorenew from '@/components/AutomatedTask/Autorenew/Form.vue'
import field from '@/components/Field/HeldProperty.vue'
import ddl from '@/components/DDL.vue'
import GETCache from '@/classes/GETCache'
import {dClone} from '@/helpers/format'

const forms = {
  scheduled_report,
  kibana_report: scheduled_report,
  standard_webhook,
  salesforce,
  autorenew
}

const typeOptions = [
  {code: 'scheduled_report', label: 'Scheduled report'},
  {code: 'kibana_report', label: 'Kibana report'},
  {code: 'standard_webhook', label: 'Webhook'},
  {code: 'autorenew', label: 'Membership Auto-renew'}
]

const salesforceType = {code: 'salesforce', label: 'Salesforce'}

const doCancel = (flashSuccess, mode) => {
  if (flashSuccess) {
    toast.success(`Automated task was successfully ${mode}d!`)
  }
  theApp.$route.router.go({
    name: 'webhooks'
  })
}

export default {
  components: {
    field,
    ddl
  },

  props: {
    id: {
      type: String,
      default: null
    }
  },

  async setup(props) {
    let at
    let all
    const promises = [GETCache.get('webhook?v2')]
    let tOps = typeOptions
    if (props.id) {
      // Load the specific AT to edit (including the secrets), do it simultaneously with loading all the ATs (without the secrets):
      promises.push(APIService.get(`webhook/${props.id}?v2`))
      const rs = await Promise.all(promises)
      at = new AutomatedTask(rs[1].webhook?._data[0])
      at.prepareEdit()
      all = rs[0]
      // When editing existing webhooks, allow the Salesforce type to be on the list:
      tOps = dClone(tOps)
      tOps.push(salesforceType)
    } else {
      // Create new AT
      all = await promises[0]
      at = new AutomatedTask()
    }
    const cancel = flashSuccess => doCancel(flashSuccess, props.id ? 'update' : 'create')
    const buttons = ref(null) // DOM element reference
    at.on('saved', () => cancel(true))
    return {
      at,
      buttons,
      forms,
      typeOptions: tOps,
      cancel
    }
  }
}
</script>

<template>
  <div class="WebhookForm">
    <div class="Settings21__Heading">
      <h2>{{ at.id ? 'Edit' : 'Create' }} Automated Task</h2>
    </div>
    <component :is="forms[at.sr.type]" :at="at" v-if="at.sr.type === at.sr.typef" :type="at.sr.type">
      <template #forType>
        <div class="FieldWrapper">
          <label>Task</label>
          <div class="Field3" :class="{disabled: !!at.id}">
            <ddl :options="typeOptions" v-model="at.sr.type" autoSelectFirstByDefault :disabled="!!at.id" />
          </div>
        </div>
      </template>
      <template #forName>
        <div class="FieldWrapper">
          <label>Display name (optional)</label>
          <field :holder="at.dr.data" name="name"></field>
        </div>
      </template>
      <template #bottom>
        <div class="Form__Actions" ref="buttons">
          <button class="btn btn-blue-cancel uppercase" type="button" @click.prevent="cancel">Cancel</button>
          <button class="btn btn-primary uppercase" type="submit" @click.prevent="at.save(buttons)" data-submit-ui-disabled="1" data-submit-spinner="1">Save automated task</button>
        </div>
      </template>
    </component>
  </div>
</template>
