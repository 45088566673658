<script>
// Ported from the Vue1 component. Note: next day AM support has not been finalised in this port.
import {computed, toRefs, watch} from 'vue'
import Base from '../classes/Base'
import {addCfg, addWatch} from '@/helpers/vModelPassThrough'
import moment from 'moment'
import DDL from './DDL.vue'
import {arrayRemove, timeFormat} from '@/helpers/format'

const inputTimeFormat = 'h:mm'
const tolerableTimeFormat = 'hmm'
const internalTimeFormat = `${inputTimeFormat}a`
const step = 15
const noon = moment('12:00:00', timeFormat)
const lastAM = noon.clone().add(-1 * step, 'minutes')
const lastPM = noon
  .clone()
  .add(12, 'hours')
  .add(-1 * step, 'minutes')
const getMomentFromInternal = time => moment(time, internalTimeFormat)

const makeMinuteOptions = step => {
  const ops = []
  let m = 0
  while (m < 60) {
    const str = m < 10 ? '0' : ''
    ops.push(`${str}${m}`)
    m = parseInt(m + step, 10)
  }
  return ops
}

const baseOptions = []
const mOps = makeMinuteOptions(step)
let h = 0
while (h < 12) {
  const str = h === 0 ? '12' : String(h)
  mOps.forEach(mo => {
    const code = `${str}:${mo}`
    baseOptions.push({
      code,
      label: code
    })
  })
  h += 1
}

const baseAMPMOptions = [
  {
    code: 'am',
    label: 'AM'
  },
  {
    code: 'pm',
    label: 'PM'
  }
]

class TimePicker extends Base {
  constructor(props, emit) {
    super()
    const eatValue = () => {
      if (props.modelValue) {
        const v = moment(props.modelValue, timeFormat).format(internalTimeFormat)
        this.setShallowReactive('time', v.slice(0, -2))
        this.setShallowReactive('m', this.nextDay ? 'nextday' : v.slice(this.sr.time.length))
      }
      this.buildOptions()
    }
    this.value = addWatch(props, emit, eatValue)
    this.setReactive({
      timeOptions: [],
      AMPMOptions: []
    })
    const minMaxWatch = () => this.buildOptions()
    const propRefs = toRefs(props)
    watch(propRefs.min, minMaxWatch)
    watch(propRefs.max, minMaxWatch)
    const getMinMaxMoment = key => {
      if (!props[key]) {
        return false
      }
      const min = moment(props[key], timeFormat)
      return min.isValid() ? min : false
    }
    this.minMoment = computed(() => getMinMaxMoment('min'))
    this.maxMoment = computed(() => getMinMaxMoment('max'))
    eatValue()
    const refs = this.getRefs()
    watch(refs.m, (nv, ov) => {
      if (this.skipMListener) {
        delete this.skipMListener
      } else if (nv !== ov) {
        this.nextDay = nv === 'nextday'
        this.buildOptions()
      }
    })
    watch(refs.time, (nv, ov) => {
      if (this.skipTListener) {
        delete this.skipTListener
      } else if (nv !== ov) {
        let m = moment(nv, inputTimeFormat)
        if (m.isValid()) {
          // "1001" will still end up here for some reason even though it does not contain the colon,
          // yet we still need to format it properly as "10:01"
          const reFormatted = m.format(inputTimeFormat)
          if (reFormatted === nv) {
            // All good, no further changes needed
            this.checkTime()
            this.buildValue()
          } else {
            this.time = reFormatted
          }
        } else {
          m = moment(nv, tolerableTimeFormat)
          this.time = m.isValid() ? m.format(inputTimeFormat) : ov
          if (!m.isValid()) {
            this.skipTListener = true
          }
        }
      }
    })
  }
  getValueMoment() {
    const m = getMomentFromInternal(`${this.sr.time}${this.nextDay ? 'am' : this.sr.m}`)
    if (this.nextDay) {
      m.add(24, 'hours')
    }
    return m
  }
  buildValue() {
    const v = this.getValueMoment().format(timeFormat)
    if (this.value.value !== v) {
      this.value.value = v
    }
  }
  checkTime() {
    if (this.minMoment.value && !this.dr.timeOptions.find(el => el.code === this.sr.time) && this.getValueMoment().isBefore(this.minMoment.value)) {
      this.sr.time = this.dr.timeOptions[0].code
      this.skipTListener = true
    }
  }
  buildOptions() {
    if (this.minMoment.value || this.maxMoment.value) {
      const mOps = Array.from(baseAMPMOptions)
      const rmFromMOps = code =>
        arrayRemove(
          mOps,
          mOps.find(el => el.code === code)
        )
      if (this.minMoment.value) {
        if (this.minMoment.value.isSameOrAfter(lastAM)) {
          rmFromMOps('am')
        }
        if (this.minMoment.value.isSameOrAfter(lastPM)) {
          rmFromMOps('pm')
        }
      }
      if (this.maxMoment.value && this.maxMoment.value.isBefore(noon)) {
        // NOTE: maxMoment isn't supposed to be used with nextDay
        rmFromMOps('pm')
      }
      this.dr.AMPMOptions = mOps
    } else {
      this.dr.AMPMOptions = baseAMPMOptions
    }
    // The current AM/PM value may not be among the allowed options. Pick the first available instead:
    if (!this.dr.AMPMOptions.find(el => el.code === this.sr.m)) {
      this.sr.m = this.dr.AMPMOptions[0].code
      this.skipMListener = true
    }
    let options = Array.from(baseOptions)
    if (this.sr.m !== 'nextday' && (this.minMoment.value || this.maxMoment.value)) {
      ;[
        ['minMoment', 'isSameOrAfter'],
        ['maxMoment', 'isSameOrBefore']
      ].forEach(cfg => {
        if (this[cfg[0]].value) {
          options = options.filter(el => getMomentFromInternal(`${el.code}${this.sr.m}`)[cfg[1]](this[cfg[0]].value))
        }
      })
      this.dr.timeOptions = options
      this.checkTime()
    } else {
      this.dr.timeOptions = options
    }
    this.buildValue()
  }
}

const optionValidator = str => /^\d{1,2}:\d{2}$/.test(str)

export default addCfg({
  props: {
    min: {
      type: String,
      default() {
        return ''
      }
    },
    max: {
      type: String,
      default() {
        return ''
      }
    }
  },
  setup(props, {emit}) {
    return {
      picker: new TimePicker(props, emit),
      optionValidator
    }
  },
  components: {
    DDL
  }
})
</script>
<template>
  <div class="TimePicker3">
    <DDL v-model="picker.sr.time" :options="picker.dr.timeOptions" :allowAdditions="true" :additionsValidator="optionValidator" />
    <DDL v-model="picker.sr.m" :options="picker.dr.AMPMOptions" />
  </div>
</template>
<style lang="scss">
.TimePicker3 {
  display: flex;
  > div {
    &:first-child {
      width: 92px;
      .DDL__Chevron {
        background-position: 5px center;
        background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.1667 6.79167C11.1667 6.6276 11.0391 6.5 10.875 6.5H10.2917C10.1276 6.5 10 6.6276 10 6.79167V10H7.95833C7.79427 10 7.66667 10.1276 7.66667 10.2917V10.875C7.66667 11.0391 7.79427 11.1667 7.95833 11.1667H10.875C11.0391 11.1667 11.1667 11.0391 11.1667 10.875V6.79167ZM14.9583 10C14.9583 12.7344 12.7344 14.9583 10 14.9583C7.26562 14.9583 5.04167 12.7344 5.04167 10C5.04167 7.26562 7.26562 5.04167 10 5.04167C12.7344 5.04167 14.9583 7.26562 14.9583 10ZM17 10C17 6.13542 13.8646 3 10 3C6.13542 3 3 6.13542 3 10C3 13.8646 6.13542 17 10 17C13.8646 17 17 13.8646 17 10Z' fill='%23282B37'/%3E%3C/svg%3E%0A");
      }
    }
    &:last-child {
      width: 78px;
      margin-left: 8px;
    }
  }
}
</style>
