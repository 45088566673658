import {App} from 'vue'
import {PublicClientApplication} from '@azure/msal-browser'

interface MsalConfigs {
  clientId: string
  issuerUrl: string
  redirectUrl: string
}

export default {
  install(app: App, {clientId, issuerUrl, redirectUrl}: MsalConfigs) {
    const msalConfig = {
      auth: {
        clientId,
        authority: issuerUrl,
        redirectUri: redirectUrl
      },
      cache: {
        cacheLocation: 'localStorage' // Options are localStorage, sessionStorage, memoryStorage
      }
    }

    app.provide('msal', new PublicClientApplication(msalConfig))
  }
}
