import BaseManager from '../TwoProngForkDDL/Manager'
import isReadOnly from '../../IsReadOnly'
import EventData from '@old/EventData'

// There are 4 possible states:
// 1. "event_categories": "event_categories" includes values, "event_template_ids" is empty.
// 2. "event_template_ids": "event_template_ids" includes values, "event_categories" is empty.
// 3. "both": Both "event_template_ids" and "event_categories" include values.
// 4. "none": The 2 arrays are both empty. This state occurs when the last DDL is deleted.

class Manager extends BaseManager {
  static prongOptions = [
    {
      label: 'Event category',
      code: 'event_categories'
    },
    {
      label: 'Event name',
      code: 'event_template_ids'
    }
  ]

  constructor(holder) {
    super(holder, ['event_template_ids', 'event_categories'])
  }

  // The async stuff in this method, ideally, needs to be in the constructor, but constructors can't be async.
  // The good news is that the async stuff in here will only be actually async once (or never at all - if the underlying methods
  // have already been called by other components in this SPA session):
  async init() {
    // Ensure that window.eventData is available:
    await window.vue1AppSellerPromise
    await EventData.promise()
    this.eventData = EventData.getInstance()
    if (!isReadOnly()) {
      await this.eventData.loadAvailable()
    }
    // See if any extra events need loading
    const extraEvents = window.subtractSets(new Set(this.holder.event_template_ids), new Set(this.eventData.availableEvents))
    if (extraEvents.size) {
      // This load only actually loads if not loaded already:
      await this.eventData.load(extraEvents, true) // load events themselves only, no need to load their dependent resources
    }
    this.allAvailableEventIDs = new Set([...this.eventData.availableEvents, ...extraEvents])
    this.afterInit()
  }

  getAllAvailableValues(prong) {
    return prong === 'event_categories' ? new Set(this.eventData.getCats()) : this.allAvailableEventIDs
  }

  getGetNameByCodeFunction(prong) {
    return prong === 'event_categories' ? null : code => this.eventData.events.get(code).name
  }

  teardown() {
    if (!this.tornDown) {
      this.clearWatchers()
      this.the2prongs.forEach(prong => {
        this.holder[prong] = []
      })
      super.teardown()
    }
  }

  onUnmounted() {
    this.teardown()
  }
}

export default Manager
