<script setup>
import {ref} from 'vue'
const props = defineProps({
  expandedByDefault: {
    type: Boolean
  }
})
const expanded = ref(props.expandedByDefault)
const initialStyle = expanded.value ? '' : 'display: none'
const contentEl = ref(null)
const headerClick = () => {
  expanded.value = !expanded.value
  // Todo: get rid of JQuery
  $(contentEl.value).slideToggle()
}
</script>
<template>
  <div class="ExpandableBlock" :class="{expanded: expanded}">
    <div class="ExpandableBlock__Header" @click="headerClick">
      <div class="ExpandableBlock__Header__Main"><slot name="header"></slot></div>
      <div class="ExpandableBlock__Header__Toggle" />
    </div>
    <div class="ExpandableBlock__Content" ref="contentEl" :style="initialStyle"><slot name="content"></slot></div>
  </div>
</template>
<style lang="scss">
.ExpandableBlock {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  position: relative;

  &__Content {
    padding: 0;
    background-color: #eff0f5;
  }

  &__Header {
    background-color: white;
    height: 52px;
    display: flex;
    border-radius: 2px;
    align-items: stretch;
    justify-content: space-between;
    cursor: pointer;

    &__Main {
      display: flex;
      align-items: center;
      padding: 0 16px;
    }

    &__Toggle {
      @extend %centered-bg;
      @extend %chevron-down;
      width: 56px;
    }
  }

  &.expanded {
    .ExpandableBlock__Header__Toggle {
      transform: scaleY(-1);
    }
  }
}
</style>
