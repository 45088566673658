<script>
import {computed, ref} from 'vue'
import toast from '@/classes/ToastSingleton'
import Account from './Account.vue'
import {mapArray} from '@/helpers/format'
import {getStripeAccount, StripeNotConfiguredError} from '@/helpers/api'

const redirectStripe = e =>
  APIService.post(
    'gateway/stripe/connect',
    {method: 'api'},
    {
      ui: e.target,
      handler: {
        matcher: {
          statusCode: 400,
          tester: 'onboarding_complete'
        },
        alertUser: true,
        reject: true
      }
    }
  ).then(r => {
    if (r.redirect) {
      location.href = r.redirect
    } else {
      toast.danger('No redirect URL received')
    }
  })

const mkSeller = id => {
  return {
    id,
    name: theApp.sellers.get(id).name
  }
}

export default {
  setup(props) {
    const isReady = ref(false)
    const notConfigured = ref(false)
    const accs = ref([])
    const showSellers = computed(() => accs.value.length > 1)
    const accsToList = computed(() => accs.value.filter(a => a.details.details_submitted))
    const defaultAcc = ref(null)
    let map
    const sellerMap = {}

    getStripeAccount()
      .catch(e => {
        if (e instanceof StripeNotConfiguredError) {
          notConfigured.value = true
        } else {
          throw e
        }
      })
      .then(r => {
        if (!notConfigured.value) {
          map = mapArray(r.stripe_account._data, 'account_id', null, true)
          if (map.size) {
            const attachedSellers = new Set()
            map.forEach(_accs => {
              const acc = _accs[0]
              if (!sellerMap[acc.account_id]) {
                sellerMap[acc.account_id] = []
              }
              acc.sellers = []
              _accs.forEach(a => {
                if (a.seller_id) {
                  // could be null
                  const s = mkSeller(a.seller_id)
                  sellerMap[acc.account_id].push(s)
                  acc.sellers.push(s)
                  attachedSellers.add(a.seller_id)
                } else {
                  acc.isDefault = true
                }
              })
              delete acc.seller_id
              delete acc.portal_id
              accs.value.push(acc)
              if (acc.isDefault) {
                defaultAcc.value = acc
              }
            })

            // Sellers that are not explicitly attached to an account are all attached to the default one
            window.subtractSets(new Set(theApp.sellers.keys()), attachedSellers).forEach(sellerId => {
              const acc = defaultAcc.value
              const s = mkSeller(sellerId)
              sellerMap[acc.account_id].push(s)
              acc.sellers.push(s)
            })

            accs.value.forEach((acc, i) => {
              acc.action = 'create'
              acc.status = {}
              if (acc.details.details_submitted) {
                const payouts = acc.details.payouts_enabled
                const charges = acc.details.charges_enabled
                if (payouts) {
                  acc.action = null
                }
                if (!payouts && !charges) {
                  acc.action = null
                  acc.status.account = 'Pending'
                  acc.status.payment = 'Pending'
                } else if (charges && !payouts) {
                  acc.status.account = 'Restricted'
                  acc.status.payment = 'Enabled'
                } else if (payouts && charges) {
                  acc.status.account = 'Complete'
                  acc.status.payment = 'Enabled'
                }
              } else {
                acc.action = 'resume'
              }
            })
          }
        }
        isReady.value = true
      })

    const action = computed(() => (defaultAcc.value ? defaultAcc.value.action : 'create'))
    const topButton = computed(() => {
      switch (action.value) {
        case 'create':
          return {
            label: 'Create Stripe Account <i class="fa fa-external-link"></i>',
            action: redirectStripe
          }
        case 'resume':
          return {
            label: 'Resume Creating Stripe Account <i class="fa fa-external-link"></i>',
            action: redirectStripe
          }
        default:
          return null
      }
    })
    const infoBlockText = computed(() => {
      if (notConfigured.value) {
        return 'This Ticketure tenant is not configured to use Stripe Connect. Stripe and TixTrack are migrating all Stripe accounts to Stripe Connect and when this is done you will see basic information about your Stripe account here.'
      } else if (action.value === 'resume') {
        return 'You have started the process of creating a Stripe account but still need to provide additional information. Please press the ‘Resume Creating Stripe Account’ button to be redirected to Stripe to continue this process.'
      } else if (action.value === 'create') {
        return 'You currently have no Stripe account created. Click the ‘Create Stripe Account’ button to add an account. Note, you will leave the site and complete the process on Stripe’s own website before being re-directed back here.'
      }
    })
    return {
      showSellers,
      topButton,
      infoBlockText,
      isReady,
      accsToList
    }
  },
  components: {
    Account
  }
}
</script>
<template>
  <div class="Stripe" v-if="isReady">
    <div>
      <h2>Stripe Accounts</h2>
      <button v-if="topButton" @click.prevent="topButton.action" data-submit-ui-disabled="1" data-submit-spinner="1" class="btn btn-primary uppercase" v-shtml="topButton.label" />
    </div>
    <div class="InfoBlockWrap" v-if="infoBlockText">
      <div class="InfoBlock" v-shtml="infoBlockText" />
    </div>
    <div>
      <Account v-for="acc in accsToList" :data="acc" :showSellers="showSellers" />
    </div>
  </div>
</template>
<style lang="scss">
.Stripe {
  &__Suffix {
    background-color: white;
    border: 1px solid $v3-separator-color;
    margin-top: 16px;
    padding: 16px;
    > * {
      display: block;
    }
    input {
      width: 100%;
    }
  }
  > :first-child {
    border-bottom: 1px solid $v3-separator-color;
  }
  .InfoBlockWrap {
    padding: 16px;
    background-color: $ticket-group-body-colour;
  }
}
</style>
