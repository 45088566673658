import {inject} from 'vue'
import type {PublicClientApplication} from '@azure/msal-browser'

export function useMsalAuth() {
  if (!window.TIX.sso?.entra_staff_sso) {
    return null
  }

  const msalObj = inject<PublicClientApplication>('msal')

  const initialize = async () => {
    await msalObj.initialize()
  }

  const login = async () => {
    await msalObj.loginRedirect()
  }

  const handleRedirect = async () => {
    await msalObj.handleRedirectPromise()
    return msalObj.getAllAccounts()?.[0]
  }

  return {
    initialize,
    login,
    handleRedirect
  }
}
