<script>
import field from '@/components/Field/HeldProperty.vue'
import AutomatedTask from './Class'
import {types} from './StandardWebhook.vue'
import dUrl from './DestinationURL.vue'
const batchSizes = [40, 60, 80, 100, 160]
export default {
  components: {
    field,
    dUrl
  },
  props: {
    at: {
      type: AutomatedTask,
      required: true
    }
  },
  setup(props) {
    const data = props.at.dr.data
    if (!data.cursor) {
      data.cursor = {window_end: ''}
    } else if (!data.cursor.window_end) {
      data.cursor.window_end = ''
    }
    const hasBatchSize = Boolean(data.trigger && data.trigger.pull)
    return {
      hasBatchSize,
      types,
      data,
      batchSizes
    }
  }
}
</script>
<template>
  <form class="SettingForm">
    <slot name="forType"></slot>
    <div class="FieldWrapper FieldWrapper__FullWidth WebhookType">
      <label>Webhook type</label>
      <field type="dropdown" :options="types" :holder="data.format.webhook_standard" name="webhook_type" :disabled="true" />
    </div>
    <hr />
    <dUrl :data="data.destination.webhook" class="SalesforceDestinationURL"></dUrl>
    <div :class="{FieldWrappers: hasBatchSize}">
      <div class="FieldWrapper" v-if="hasBatchSize">
        <label>Batch size</label>
        <field type="dropdown" :options="batchSizes" :holder="data.trigger.pull" name="batch_size" description="Specifying 100 or 160 would result in 2 webhooks being sent in quick succession." :required="true" />
      </div>
      <div class="FieldWrapper DateFieldWrapper">
        <label>Send event data starting from</label>
        <field :holder="data.cursor" name="window_end" type="date" description="A date from up to 3 months in the past may be chosen" />
      </div>
    </div>
    <slot name="bottom"></slot>
  </form>
</template>
<style lang="scss">
.SalesforceDestinationURL {
  .Field3__Container {
    @extend %ui-disabled;
  }
}
</style>
