<script setup lang="ts">
// @cms-next src/pages/Settings/Config/PropertyPage.vue
import Survey from '@/components/Survey/Survey.vue'
import Field from '@/components/Field/Field.vue'
import {ref, computed} from 'vue'
import {getValidator} from '@/config/forms'
import {updateValue} from '@/config/api'
import {determineEditor, getResourceID, getConfigValueAndTemplate} from '@/config/helpers'
import {useRoute} from 'vue-router'
import {router} from '@/router/Router'
import type {ResourceName} from '@/api/response'
import CodeEditor from '@/components/CodeEditor.vue'
import CodeViewer from '@/components/CodeViewer.vue'

declare let theApp

interface Props {
  resource_id?: string
  resource: ResourceName
  name: string
  namespace: string
}

const props = defineProps<Props>()
const resourceId = getResourceID(props.resource, props.resource_id)
// @ts-ignore Top-level await
const [initialValue, template] = await getConfigValueAndTemplate(props.resource, resourceId, props.namespace, props.name)
const v = ref<any>(initialValue)
const editor = determineEditor(template)
const mayEdit = template.current_user_may_set

const submitting = ref(false)
const error = ref<string | undefined>()
const syntaxError = ref<string | undefined>()

const jsonValue = computed<string>({
  get() {
    return JSON.stringify(v.value, undefined, 2)
  },
  set(value: string) {
    try {
      v.value = JSON.parse(value)
      syntaxError.value = undefined
      console.log('unset', syntaxError.value)
    } catch (e) {
      syntaxError.value = String(e)
    }
  }
})

let validateWithSchema
try {
  validateWithSchema = getValidator(template)
} catch (e) {
  error.value = e
  console.error(`Config template schema is not valid: ${template.path}`, template, e)
}

async function onSubmit() {
  submitting.value = true

  await validate()

  if (error.value) {
    submitting.value = false
  } else {
    await updateValue(resourceId, template, v.value)
    goToListRoute()
  }
}

async function validate() {
  error.value = undefined

  if (syntaxError.value) {
    error.value = syntaxError.value
    return
  }

  error.value = await validateWithSchema(v.value)
}

const route = useRoute()

function goToListRoute() {
  const routeParams = `/${props.namespace}/${props.name}`
  const newRoute = route.fullPath.replace(routeParams, '')
  router.push(newRoute)
}

const title = computed<string>(() => {
  if (!mayEdit) {
    return `View ${template.title}`
  } else if (initialValue == null) {
    return `Add ${template.title}`
  } else {
    return `Edit ${template.title}`
  }
})
</script>

<template>
  <div class="Settings__Content__heading">
    <h2>{{ title }}</h2>
  </div>

  <form @submit.prevent="onSubmit">
    <div class="form-body">
      <p v-shtml="template.description" />

      <!-- Use the title as the label for a single checkbox config template. -->
      <Field v-if="editor === 'checkbox'" v-model="v" type="checkbox" :required="true" :label="template.title" :disabled="!mayEdit" />

      <Survey v-else-if="editor === 'survey'" v-model="v" :schema="template.schema" :namespace="template.path" :readOnly="!mayEdit" />

      <template v-else-if="editor === 'json'">
        <CodeEditor v-if="mayEdit" v-model="jsonValue" height="max(500px, calc(100vh - 600px))" language="json" />
        <CodeViewer v-else :code="jsonValue" />
      </template>

      <p class="error">{{ error }}</p>
    </div>

    <div class="form-actions">
      <button type="button" @click="goToListRoute" :disabled="submitting" class="btn uppercase">
        {{ mayEdit ? 'Cancel' : 'Return' }}
      </button>
      <button type="submit" v-if="mayEdit" :disabled="submitting" class="btn btn-primary uppercase">Save Option</button>
    </div>
  </form>
</template>

<style scoped lang="scss">
.error {
  color: red;
}

.form-body,
.form-actions {
  padding: 16px;
  border-top: 1px solid $separator-colour !important;
}

.form-actions {
  display: flex;
  gap: 8px;
  justify-content: end;
}
</style>
