<script setup>
import {ref, computed} from 'vue'
import {useRouter} from 'vue-router'
import GETCache from '@/classes/GETCache'
import HeaderWithButton from './HeaderWithButton.vue'
import ClickAgainToConfirmButton from './ClickAgainToConfirmButton.vue'
import KebabMenu from './KebabMenu.vue'
import Sorter from '@old/Sorter'
const router = useRouter()
await vue1AppSellerPromise
const sellerId = theApp.seller.id
const props = defineProps({
  readOnly: {
    type: Boolean
  },
  heading: {
    type: String,
    required: true
  },
  createButtonText: {
    type: String,
    required: true
  },
  createRoute: {
    type: String,
    required: true
  },
  editRoute: {
    type: String,
    required: true
  },
  emptyMessage: {
    type: String,
    required: true
  },
  searchText: {
    type: String
  },
  // Promise that fetches the resources
  resourceName: {
    type: String,
    required: true
  },
  // Either String or Function which gets passed the item object and returns String
  itemCls: {},
  // Promise that archives/deletes resource by ID
  remover: {
    type: Function
  },
  sorter: {
    type: Sorter
  }
})
const button = {
  text: props.createButtonText,
  action() {
    router.push({
      name: props.createRoute,
      query: {
        seller: sellerId
      }
    })
  }
}
const list = ref([])
const data = await GETCache.get(props.resourceName)
if (props.sorter) {
  props.sorter.sort(data)
}
list.value = data
const remover = props.remover ?? (id => APIService.delete(`${props.resourceName}/${id}`))
const archive = async arg => {
  await remover(arg.id)
  window.arrayRemove(list.value, arg)
  GETCache.bust(props.resourceName)
}
function getEditRouteOptions(record) {
  return {
    name: props.editRoute,
    params: {id: record.id},
    query: {seller: sellerId}
  }
}
function edit(record) {
  router.push(getEditRouteOptions(record))
}
const query = ref('')
const showList = computed(() => (query.value ? list.value.filter(pos => pos.name.toLowerCase().includes(query.value.toLowerCase())) : list.value))
const getItemClass = item => {
  if (typeof props.itemCls === 'string') {
    return props.itemCls
  }
  if (typeof props.itemCls === 'function') {
    return props.itemCls(item)
  }
  return null
}
</script>
<template>
  <div class="V3ResourceList" :class="{'form-disabled': readOnly}">
    <HeaderWithButton :heading="heading" :button="button" />
    <div v-if="searchText" class="SearchHolder">
      <div class="SearchInput">
        <input type="search" v-model="query" :placeholder="searchText" />
      </div>
    </div>
    <div class="List" v-if="list.length">
      <div v-for="item in showList" :key="item.id" :class="getItemClass(item)">
        <div class="V3ResourceList__Name" @click="() => edit(item)">
          <span v-shtml="item.name" />
        </div>
        <KebabMenu>
          <router-link :to="getEditRouteOptions(item)">Edit</router-link>
          <ClickAgainToConfirmButton idleText="Archive" @action="archive(item)" />
        </KebabMenu>
      </div>
    </div>
    <div class="Empty" v-else>
      <div class="InfoBlock" v-html="emptyMessage" />
    </div>
  </div>
</template>
<style lang="scss">
.V3ResourceList {
  .FormSection,
  .Settings__Content__heading {
    margin: 0;
  }

  .SearchHolder {
    padding: 16px;
    > .SearchInput {
      width: 50%;
      input:focus {
        border-color: revert;
      }
    }
  }

  h3 {
    font-size: 16px;
    font-weight: 600;
    margin: 8px 0 16px 0;
    padding: 0;
    color: $v3-dark-color;
  }

  .InfoBlock {
    border: 1px solid #ddd;
  }

  .Empty,
  .List {
    background-color: $ticket-group-body-colour;
  }

  .Empty {
    padding: 16px;
    .InfoBlock {
      margin: 0;
      border: 0;
    }
  }

  .List {
    padding: 8px;

    &::before {
      content: 'Name';
      font-weight: 600;
      font-size: 12px;
      color: $v3-note-color;
      text-transform: uppercase;
      margin: 10px 0 16px 24px;
      display: block;
    }

    > div {
      display: flex;
      align-items: center;
      margin: 8px;
      font-size: 16px;
      font-weight: 600;
      color: $header-colour;
      background-color: white;
      border-radius: 2px;

      &:not(:last-child) {
        margin-bottom: 16px;
      }

      > div {
        margin-right: 8px;
        &.V3ResourceList__Name {
          padding: 16px 8px 16px 16px;
          min-height: 56px;
          align-content: center;
          margin-right: 0;
          cursor: pointer;
          flex-grow: 1;
        }
      }
    }
  }
}
</style>
