<template>
  <div class="staff StaffGroups Settings21">
    <div class="Settings21__Form create-group">
      <div class="SearchInput" id="searchContainer" data-submit-spinner="1">
        <input type="text" v-model="groupFilter" placeholder="Enter text to search" debounce="400" />
      </div>
      <button @click="$router.push({name: 'add-staff-group'})" class="uppercase btn btn-primary">Create staff group</button>
    </div>

    <div class="InfoBlockWrap">
      <Table v-if="filteredStaffGroups.length > 0" :rows="filteredStaffGroups" :displayFields="displayFields" :rowActions="rowActions">
        <template #cell(name)="{col, row}"
          ><h3>{{ row[col] }}</h3></template
        >
        <template #cell(members)> -- </template>
        <template #cell(sellerRoles)="{col, row}">
          {{ getSellerList(row[col]) }}
        </template>
      </Table>
      <div v-else class="InfoBlock">
        <template v-if="groupFilter"> No Staff Groups match your search </template>
        <template v-else> You currently have no Staff Groups created. Click the ‘Create Staff Group’ button to begin adding Staff Groups. </template>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {ref, computed, Ref} from 'vue'
import {mapArray} from '@/helpers/format'
import AddStaffGroup from './StaffGroupForm.vue'
import {StaffGroup} from './types'
import {fetchStaffGroups} from './helpers'
import Table, {RowAction} from '@/components/Table.vue'
import {useRouter} from 'vue-router'

const staffGroups: Ref<Array<StaffGroup>> = ref([])

const groupFilter = ref(null)

async function getStaffGroups() {
  await fetchStaffGroups(staffGroups)
}

const displayFields = {
  name: 'Staff Group',
  sellerRoles: 'Seller',
  members: 'Staff Members'
}

const router = useRouter()

const rowActions = (): Array<RowAction> => [
  {
    name: 'Edit',
    callback: r => router.push({name: 'edit-staff-group', params: {id: r.id}})
  },
  {
    name: 'Delete',
    callback: r => deleteGroup(r)
  }
]

getStaffGroups()

const filteredStaffGroups = computed(() => {
  if (!groupFilter.value) return staffGroups.value

  const searchTerm = groupFilter.value.toLowerCase().trim()
  const containsFilter = value => value.toLowerCase().includes(searchTerm)

  return staffGroups.value.filter(group => {
    return containsFilter(group.name) || containsFilter(group.description) || group.sellerRoles.some(sellerRole => containsFilter(sellerRole.seller.name))
  })
})

const deleteGroup = group => {
  window.APIService.delete(`staff_group/${group.id}`).then(getStaffGroups)
}

function getSellerList(sellerRoles) {
  if (!sellerRoles) {
    return ''
  }

  const uniqueSellers = mapArray(
    sellerRoles.map(sellerRole => sellerRole.seller),
    'name'
  )
  return Array.from(uniqueSellers.keys()).join(', ')
}
</script>

<style lang="scss">
.staff {
  .SearchInput {
    width: calc(50% - 8px);
  }

  .InfoBlockWrap {
    padding: 16px;
    background-color: $ticket-group-body-colour;

    .InfoBlock {
      margin-bottom: 0;
    }
  }

  .create-group {
    display: flex;
    justify-content: space-between;
  }
}

.StaffGroups {
  .Table {
    .Table__Row {
      height: 64px;
    }

    .Table__CelLEditOptions {
      width: 64px;
    }
  }
}
</style>
