<script>
import {computed, ref, reactive, watch} from 'vue'
import {useRoute} from 'vue-router'
import cmsSection from '@/components/Section.vue'
import field from '@/components/Field/HeldProperty.vue'
import {isEmptyObject} from '@/helpers/comparison'
import ErrorMessage from '@/components/ErrorMessage.vue'
import TriggersOrActions from '@/components/MessageRule/TriggersOrActions.vue'
import Validator from '@old/schema/Validator'
import schema from '@/schema/message_rule'
import {setError, cleanErrors} from '@/helpers/ErrorHolder'
import {strip} from '@/helpers/format'

const makeValidator = type =>
  Validator.get(
    schema,
    `/tix/message_rule#/$defs/${type}`,

    // Error setter
    ed => {
      // "ed" for error details
      if (Array.isArray(ed.holder)) {
        ed.holder.manager.dr.errors[ed.key] = ed.message
      } else {
        setError(ed.holder, ed.key, ed.message)
      }
    },

    // Error cleaner. This function is ultimately passed to the strip function as the 2nd argument.
    (o, k) => {
      if (Array.isArray(o[k]) && o[k].manager) {
        const errors = o[k].manager.dr.errors
        if (errors) {
          errors.forEach((v, i) => {
            errors[i] = false
          })
        }
      }
      return cleanErrors(o, k)
    }
  )

export default {
  props: {
    // This is supplied when editing a message rule. Otherwise we're creating a new one.
    id: String
  },
  async setup(props) {
    const route = useRoute()
    await window.vue1AppSellerPromise
    // DOM reference to the button container
    const buttons = ref(null)
    const validator = makeValidator(props.id ? 'update' : 'create')

    // Reference to the message rule data object (to be assigned to the ref later)
    const data = ref(null)

    // Raw message rule object
    let rawData

    // Not needed when creating a new message rule, but exists at all times for uniformity:
    let promise

    // The UI features the "Enabled" switch whereas the data uses the "disabled" boolean property.
    // These two need to be linke/synchronised.
    const isEnabled = ref(true)
    const updateDisabled = () => {
      data.value.disabled = !isEnabled.value
    }

    // holds a copy of the un-edited object to later find out what has changed
    let original

    const getExistingOne = async (id, forDupe) => {
      const r = await window.APIService.get(`message_rule/${id}`)
      rawData = r.message_rule._data[0]
      ;['id', 'portal_id', 'seller_id', '_rank'].forEach(k => delete rawData[k])
      if (forDupe) {
        rawData.name = `${rawData.name} (duplicate)`
      }
      original = window.dClone(rawData)
    }

    if (props.id) {
      // Editing an existing message rule
      promise = getExistingOne(props.id)
    } else {
      // Creating a new one.
      // Is it a duplicate?
      if (route.query.from) {
        promise = getExistingOne(route.query.from, true)
      } else {
        rawData = {
          disabled: false,
          event_categories: [],
          event_template_ids: [],
          triggers: {},
          actions: {}
        }
        promise = Promise.resolve()
      }
    }

    // We load the data (if editing) and make it reactive.
    // Then it gets passed to various subcomponents each responsible for editing certain parts of it.
    // The reactivity allows for automatic bi-directional update: direct changes on the object automatically reflect on the UI,
    // and user-initiated UI changes automatically get written to the object.
    await promise
    if (props.id) {
      isEnabled.value = !rawData.disabled
    }
    data.value = reactive(rawData)
    if (!props.id) {
      updateDisabled()
    }
    watch(isEnabled, updateDisabled)

    const header = computed(() => `${props.id ? 'Edit' : 'Create'} Message Rule`)
    const action = computed(() => `${props.id ? 'Update' : 'Create'} Message Rule`)
    const cancel = () => theApp.$route.router.go({name: 'message-rules'}) // Use Vue1 router until the target route gets rewritten in Vue3
    const submit = async () => {
      if (!props.id) {
        // Required when creating new message rules:
        Object.assign(data.value, {
          portal_id: PORTAL.id,
          seller_id: theApp.seller.id
        })
      }
      const verb = props.id ? 'patch' : 'post'
      let url = 'message_rule'
      if (props.id) {
        url = `${url}/${props.id}`
      }
      // Do not modify the working copy. It contains those _id fields we need locally:
      const candidateData = window.dClone(data.value)
      strip(candidateData, (o, k) => k.charAt(0) === '_', true)
      // Save if valid:
      if (validator(candidateData, data.value)) {
        // window.lj(candidateData)
        const toSave = props.id
          ? window.objDiff(candidateData, original, {
              // Put empty arrays in place of missing keys (instead of nulls)
              event_template_ids: [],
              event_categories: []
            })
          : candidateData
        if (!isEmptyObject(toSave)) {
          await window.APIService[verb](url, toSave, {ui: buttons.value})
        }
        cancel()
      }
    }
    return {
      header,
      action,
      submit,
      data,
      isEnabled,
      cancel,
      buttons,
      isReadOnly: !theApp.roleTeller.has('configure-checkout-rules')
    }
  },
  components: {
    field,
    cmsSection,
    TriggersOrActions,
    ErrorMessage
  }
}
</script>
<template>
  <cmsSection :header="header" is="form" @submit.prevent="submit" class="MR" :class="{'form-disabled': isReadOnly}">
    <div class="FormSection">
      <h4>Name & Description</h4>
      <div class="MR__Name">
        <label class="required">Name</label>
        <field :holder="data" name="name" />
      </div>
      <div class="MR__Description">
        <label>Description</label>
        <textarea v-model="data.description" />
      </div>
    </div>
    <ErrorMessage :holder="data" name="triggers" />
    <TriggersOrActions title="Triggers" :data="data" />
    <ErrorMessage :holder="data" name="actions" />
    <TriggersOrActions title="Actions" :data="data" />
    <div class="BottomControls">
      <div>
        <input type="checkbox" class="Switch" id="enableSwitch" v-model="isEnabled" />
        <label for="enableSwitch">Enabled</label>
      </div>
      <div ref="buttons">
        <a data-submit-ui-disabled="1" class="btn btn-default uppercase" @click="cancel">Cancel</a>
        <button type="submit" data-submit-ui-disabled="1" data-submit-spinner="1" class="btn btn-primary uppercase" v-shtml="action" />
      </div>
    </div>
  </cmsSection>
</template>
<style lang="scss">
.ExpandableBlock,
.FormSection {
  margin-bottom: 16px;
  h4 {
    font-size: 14px;
    margin: 0;
  }
  input,
  textarea {
    width: 100%;
  }
}

.FormSection {
  background-color: white;
  &__Inner {
    background-color: white;
    padding: 16px;
  }

  > * {
    padding: 8px 16px;
  }

  > div {
    &:first-child {
      padding-top: 16px;
    }
    &:last-child {
      padding-bottom: 16px;
    }
  }
}
.MR {
  &__Name .Field3__Container {
    width: 50%;
  }
  &__Description {
    textarea {
      height: 70px;
    }
  }
  &__Delete {
    @extend %blueCross;
    width: 16px;
    min-width: 16px;
    margin-left: 16px;
    align-self: stretch;
  }
  .FormSection__Inner {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;

    &:not(:first-child) {
      @extend %AddedAND;
    }
  }
  .ExpandableBlock__Content {
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__Item {
    > .v-select {
      width: $message-rules-ddl-width;
      min-width: $message-rules-ddl-width;
      align-self: flex-start;
    }
  }

  &__AndButton {
    @extend %centered-bg;
    @extend %and-button;
    position: relative;
    width: 56px;
    cursor: pointer;

    &::before {
      content: '';
      border-right: 1px solid $v3-border-color;
      top: 0;
      left: 50%;
      position: absolute;
      bottom: 40px;
      z-index: 0;
    }

    height: 56px;
  }
}
.BottomControls {
  background-color: white;
  display: flex;
  justify-content: space-between;
  padding: 16px;
  > :first-child {
    display: flex;
    align-items: center;
    label {
      margin: 2px 0 0 8px;
    }
  }
  > :last-child {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    > * {
      margin-left: 8px;
    }
  }
}
</style>
