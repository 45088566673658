<script setup>
import {onMounted} from 'vue'
import {useMsalAuth} from '@/helpers/useMsalAuth'
import {logInTrackJS} from '@/helpers/errors'

const msalAuth = useMsalAuth()

onMounted(async () => {
  window.treatApiPendingUI(true, window)

  try {
    // This redirect back to the previous page.
    // However, the second call to this function in the login form will return a token response.
    await msalAuth.handleRedirect()
  } catch (e) {
    logInTrackJS(e, null)
    window.history.back()
  }
})
</script>
