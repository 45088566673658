<script>
import {addCfg, computedError} from '../helpers/ErrorHolder'
export default addCfg({
  setup(props) {
    return {
      error: computedError(props)
    }
  }
})
</script>
<template>
  <div v-if="error" v-shtml="error" class="WarningBlock" />
</template>
<style lang="scss">
.ErrorMessage {
}
</style>
