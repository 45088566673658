import {nextTick} from 'vue'
import TriggerOrActionManager from '../TriggerOrActionManager'
import EventCatOrID from './EventCatOrID/View.vue'
import quantity from './Quantity/View.vue'
import date_or_session from './DateOrSession/View.vue'

const CMP_MAP = {
  EventCatOrID,
  quantity
}

class Manager extends TriggerOrActionManager {
  static HOLDER = 'triggers'

  static cmpTeller = k => CMP_MAP[k] || 'div'

  static ALWAYS_AVAILABLE = ['quantity']

  static OPTIONS = new Map([
    ['EventCatOrID', 'If event category or name'],
    ['quantity', 'If ticket quantity'],
    ['date_or_session', 'If date or session time']
  ])

  static USE_ROOT_DATA_OBJECT_FOR = ['EventCatOrID']

  onEmpty(index, itemType) {
    if (!this.lastTypeChange) {
      const type = this.dr.items[index]
      let ordinalCount = -1
      // Remove the data holder upon removing/emptying a trigger
      this.dr.items.forEach((v, i) => {
        if (v === type) {
          ordinalCount++
          const h = this.getHolder()
          if (index === i && h[type]) {
            h[type].splice(ordinalCount, 1)
          }
        }
      })
      super.onEmpty(index, itemType)
    }
  }

  getItemComponent(index) {
    if (this.dr.items[index] === 'date_or_session') {
      return date_or_session
    }
    return super.getItemComponent(index)
  }

  makeHolders() {
    const holders = []
    const ordinalCounters = {}
    const h = this.getHolder()
    this.dr.items.forEach((v, i) => {
      let holder
      const lastTypeChangeMatch = this.lastTypeChange && this.lastTypeChange[0] === i
      if (lastTypeChangeMatch) {
        const oldType = this.lastTypeChange[2]
        if (oldType && h[oldType]) {
          // Trigger type change has occurred, and the old type's data must be removed
          h[oldType].splice(ordinalCounters[oldType] || 0, 1)
        }
      }
      if (!v) {
        holder = {} // temporary stub object for uniformity
      } else if (this.constructor.USE_ROOT_DATA_OBJECT_FOR.includes(v)) {
        holder = this.baseHolder
      } else {
        // Some triggers are allowed in multiple instances.
        // These are held in an array under a property named after the trigger's type.
        // Yet we need to maintain them unfolded flat in the holders array while
        // not losing track of where they actually belong.
        // Counting them per type is what helps to achieve that:
        if (ordinalCounters[v] === undefined) {
          ordinalCounters[v] = 0
        } else {
          ordinalCounters[v]++
        }
        if (!h[v]) {
          h[v] = []
        }
        if (this.constructor.ALWAYS_AVAILABLE.includes(v)) {
          if (lastTypeChangeMatch) {
            h[v].splice(ordinalCounters[v], 0, {}) // New trigger is created/inserted.
          }
          holder = h[v][ordinalCounters[v]]
        } else {
          holder = h[v]
        }
      }
      holders.push(this.ensureHolderId(holder))
    })
    if (this.lastTypeChange) {
      nextTick(() => {
        delete this.lastTypeChange
      })
    }
    return holders
  }

  readData() {
    if ((this.baseHolder.event_template_ids && this.baseHolder.event_template_ids.length) || (this.baseHolder.event_categories && this.baseHolder.event_categories.length)) {
      this.add('EventCatOrID')
    }
    const h = this.baseHolder[this.constructor.HOLDER]
    this.constructor.ALWAYS_AVAILABLE.forEach(k => {
      if (h[k]) {
        h[k].forEach(() => this.add(k))
      }
    })
    if (h.date_or_session) {
      this.add('date_or_session')
    }
  }
}

export default Manager
