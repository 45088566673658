import {computed, nextTick, watch} from 'vue'
import DDLSetManager from '../../DDLSetManager'
import {randomString} from '@/helpers/format'

class DDLManager extends DDLSetManager {
  // "values" is the array holding selected values. Already reactive.
  // "allSet" is the Set of all available values
  // "getNameByCode" optional function for rendering DDL option label
  constructor(type, values, allSet, getNameByCode, allowAdditions) {
    super(values)
    this.id = randomString('ddlm-')
    this.originalType = type
    this.getNameByCode = getNameByCode
    this.allSet = allSet
    this.allowAdditions = allowAdditions
    this.setShallowReactive({
      type,
      isORAvailable: computed(() => this.dr.items.filter(i => !i).length === 0 && (this.allowAdditions || this.sr.availableOptions.value.size > 0))
    })
    // Type (event_categories vs event_template_ids) can be changed when there is only one type (prong) engaged.
    // This change causes a full reset of the "If event category or name" trigger
    watch(this.getRefs().type, t => {
      if (t !== this.originalType) {
        this.trigger('typechange', t)
        // Event dispatched. The receiver will create a new manager with the new type.
        // Now switch the type of THIS manager back because we'll need it to be the original value if/when this manager is used again:
        this.sr.type = this.originalType
      }
    })
    this.lastInitStep()
  }

  getBaseSet() {
    return this.allSet
  }

  removeValue(value) {
    window.arrayRemove(this.dr.items, value)
    this.onAfterRemove(value)
  }

  getNextAvailableValue() {
    let v = super.getNextAvailableValue()
    if (v === undefined && this.allowAdditions) {
      v = ''
    }
    return v
  }

  addValue() {
    const v = super.addValue()
    if (v === '') {
      // Focus the input
      nextTick(() => {
        const id = `${this.id}-${this.dr.items.length - 1}`
        document.querySelector(`#${id} .MR__DDL-input input`).focus()
      })
    }
    return v
  }
}

export default DDLManager
