<script>
import makeModalCmp from './Abstract'
import {ref} from 'vue'
import {setError, cleanErrors} from '@/helpers/ErrorHolder'
import {strip} from '@/helpers/format'
export default makeModalCmp(setup => {
  const blocking = ref(false)
  return {
    blocking,
    disableDeleteUnlessDefaultPhase: 'You can only delete a price tier on the current price matrix',
    checkIfCanDelete: () => {
      const info = setup.data.tierInfo
      if (info.isOneDefaultSchedule) {
        return true
      }
      const _blocking = []
      info.schedules.forEach(s => {
        if (s.tier_pickers.find(tp => tp.adjustor === 'absolute' && tp.offset === info.index)) {
          _blocking.push(s.name)
        }
      })
      if (!_blocking.length) {
        return true
      }
      blocking.value = _blocking
      return false
    },
    onBeforeSubmit: () => {
      strip(setup.data, cleanErrors, true)
      if (!Boolean(setup.data.name)) {
        setError(setup.data, 'name')
        return false
      }
      const info = setup.data.tierInfo
      if (info.tiers.includes(setup.data.name) && info.tiers[info.index] !== setup.data.name) {
        setError(setup.data, 'name', 'Price tier name already exists')
        return false
      }
      return true
    },
    name: 'Price Tier',
    ...setup
  }
})
</script>
<template>
  <Modal v-model="show" submitText="Confirm" :onBeforeSubmit="onBeforeSubmit" @submitted="onSubmitted" class="PriceLibModal AddPriceTier" :onMounted="onMounted">
    <template #header>{{ header }}</template>
    <template #extraButtons v-if="isEdit">
      <ClickAgainToConfirmButton class="DeleteButton DeleteTier" :disabled="!data.isCurrentPhase" :checkPossibility="checkIfCanDelete" @action="onDelete" idleText="Delete Tier" />
    </template>
    <div>
      <label class="required">Name</label>
      <field :holder="data" name="name" />
      <div v-if="blocking" class="Blocking">
        <p>This tier cannot be removed due to it being referenced by the following schedules:</p>
        <ul>
          <li v-for="n in blocking" v-shtml="n" />
        </ul>
        <p>Please edit or delete these schedules to remove any relationships before trying again.</p>
      </div>
    </div>
  </Modal>
</template>
<style lang="scss">
.Blocking {
  margin-top: 16px;
  color: $warning-color;
}
</style>
