<template>
  <div class="staff Settings21">
    <div v-if="!inFormView" class="Settings21__Heading"><h2>Staff Management</h2></div>
    <TabNav :items="navItems" v-if="!inFormView"></TabNav>

    <Suspense>
      <router-view></router-view>
      <template #fallback>
        <div class="SuspenseLoading">Loading...</div>
      </template>
    </Suspense>
  </div>
</template>

<script lang="ts">
import {useRoute} from 'vue-router'
import {computed} from 'vue'
import TabNav, {TabNavItem} from '@/components/TabNav.vue'

export default {
  components: {TabNav},
  setup() {
    const route = useRoute()

    const inFormView = computed(() => {
      return ['add-staff-member', 'edit-staff-member', 'add-staff-group', 'edit-staff-group'].includes(route.name as string)
    })

    const navItems: TabNavItem[] = [
      {title: 'Staff Members', name: 'staff-members'},
      {title: 'Staff Groups', name: 'staff-groups'}
    ]

    return {
      inFormView,
      navItems
    }
  }
}
</script>
<style lang="scss">
.staff {
  .Tabs {
    border-radius: 0;
    border-bottom: 1px solid #dddddd;
    margin-bottom: 0;

    > ul {
      li:first-child {
        border-radius: 0;
      }
    }
  }

  .Table {
    margin: -16px 0;

    .Table__Row {
      td {
        &:first-child {
          border-top-left-radius: 2px;
          border-bottom-left-radius: 2px;
        }

        &:last-child {
          border-top-right-radius: 2px;
          border-bottom-right-radius: 2px;
        }
      }
    }
  }
}
</style>
