<script setup>
import {computed, onUnmounted} from 'vue'
import Prong from './Prong.vue'

// At least two independent triggers (event category/name and quantity/TG-TT names) follow the same UI pattern:
// There is the 1st column DDL offering only two grand options.
// The 2nd column DDL can be bred in multiple rows (ANDed or ORed, doesn't matter here). The options in these DDLs are shared and inter-dependent.
// This pattern is complex enough to avoid it being repeated/maintained independently in each trigger that follows it.
// Rather, it needs to be generalised/abstracted. So, it is named "TwoProngForkDDL" pattern after the fact that the 1st column DDL
// always presents 2 grand options like a 2-prong fork, which the 2nd column DDLs derive their options from.

const props = defineProps({
  data: {
    type: Object,
    required: true
  },
  // Whether the two prongs are logically ORed or ANDed. Only affects the visualisation, not data.
  prongsAnded: {
    type: Boolean
  },
  // Class constructor for the manager
  managerClass: {
    type: Function,
    required: true
  }
})
const emit = defineEmits(['empty'])
const manager = new props.managerClass(props.data)
manager.on('empty', () => emit('empty'))

// Submanagers for each of the two prongs
const managers = manager.managers

// See the manager class for what the state means
const setState2Both = () => {
  manager.sr.state = 'both'
}
const isBoth = computed(() => manager.sr.state === 'both')

onUnmounted(() => manager.onUnmounted())
</script>
<template>
  <div class="MR__Trigger__TwoProngFork__Wrapper" :class="{isBoth: isBoth, prongsAnded: prongsAnded}">
    <slot></slot>
    <div>
      <template v-for="(m, i) in managers" :key="m.id">
        <div v-if="i === 1" class="ItemSeparator" />
        <Prong :manager="m" @or="setState2Both" :options="managerClass.prongOptions" :prongsAnded="prongsAnded" :isBoth="isBoth" :isFirst="i === 0" />
      </template>
    </div>
  </div>
</template>
<style lang="scss">
.MR__Trigger__TwoProngFork__Wrapper {
  .errorHolder {
    position: absolute;
  }

  &.isBoth {
    .MR__Trigger__TwoProngFork__Add__Button:not(:nth-child(3)) {
      visibility: hidden;
    }
    .MR__Trigger__TwoProngFork__Instance {
      &::before {
        display: none;
      }
      &:not(:first-child) {
        .MR__Trigger__TwoProngFork__DDL {
          > :first-child {
            visibility: hidden;
          }
        }
      }
    }
    .MR__Trigger__TwoProngFork__DDL {
      .v-select:nth-child(2) {
        @extend %ui-disabled;
      }
    }
    &.prongsAnded .ItemSeparator::after {
      content: 'and';
      margin-left: 28px;
    }
  }
}
</style>
