import type {Router as Vue1Router} from 'vueVersion1-router'
import {router as router3} from './Router'

/**
 * Vue 1 router documentation
 * @see https://web.archive.org/web/20160620004045/http://router.vuejs.org:80/en/index.html
 */
export function synchronizeRouters(router1: Vue1Router) {
  // Get local functions to call original methods with the correct `this`.
  const router1Replace = router1.replace.bind(router1)
  const router3Replace = router3.replace.bind(router3)

  function observeVue3Router(methodName: 'push' | 'replace') {
    const original = router3[methodName].bind(router3)

    router3[methodName] = async (...args) => {
      // Update Vue 1 router once Vue 3 router has completed navigation.
      const result = await original(...args)
      router1Replace(router3.currentRoute['_rawValue'].fullPath)
      return result
    }
  }

  function observeVue1Router(methodName: 'go' | 'replace') {
    let router1IsTransitioning = false

    router1.afterEach(() => {
      // Update Vue 3 router once Vue 1 router has completed navigation,
      // if that navigation was triggered by Vue 1 router.
      if (router1IsTransitioning) {
        router1IsTransitioning = false
        router3Replace(router1._currentRoute.path)
      }
    })

    const original = router1[methodName].bind(router1)
    router1[methodName] = (...args) => {
      // Remember that this navigation was triggered by Vue 1 router.
      router1IsTransitioning = true
      return original(...args)
    }
  }

  observeVue1Router('go')
  observeVue1Router('replace')
  observeVue3Router('push')
  observeVue3Router('replace')
}

// function logNavigationTransitions() {
//   const orig = window.history.pushState
//   window.history.pushState = function (...args) {
//     console.log('pushState', args)
//     orig.call(window.history, ...args)
//   }
// }

// Debug
// logNavigationTransitions()
