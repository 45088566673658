<script>
import {computed, watch, onUnmounted, reactive, ref} from 'vue'
import field from '@/components/Field/HeldProperty.vue'
import {NonAlarmingError} from '@/api/error/Misc'
import barefield from '@/components/Field/Field.vue'
import AutomatedTask from './Class'
import {getSellerOptions} from './Class'
import GETCache from '@/classes/GETCache'
import {array2String, string2Array, arrayRemove} from '@/helpers/format'
import standard_webhook from '@/schema/automated_task/standard_webhook'
import Validator from '@old/schema/Validator'
import {copyTextToClipboard} from '@/helpers/clipboard'
import filtersc from './Filters/View.vue'
import dUrl from './DestinationURL.vue'

// Request Headers validator — has to be a separate one because different AJV params are used.
const RHValidator = Validator.getFor(standard_webhook.$defs.destination.properties.webhook.properties.request_headers, {
  removeAdditional: false
})

export const types = [
  {code: 'gateway_audit', label: 'Gateway Audit'},
  {code: 'identity_changed', label: 'Identity Changed'},
  {code: 'managed_cart_audit', label: 'Managed Cart Audit'},
  {code: 'ticket_audit_2', label: 'Ticket Audit 2'},
  {code: 'ticket_audit_external', label: 'Ticket Audit - Anonymous'},
  {code: 'ticket_discount', label: 'Ticket Discount'},
  {code: 'session_changed', label: 'Session Changed'},
  {code: 'ticket_patched', label: 'Ticket Patched (requires Ticket Audit 2)', requires: 'ticket_audit_2'},
  {code: 'ticket_patched_held', label: 'Ticket Patched Held (requires Managed Cart Audit)', requires: 'managed_cart_audit'},
  {code: 'transaction_audit', label: 'Transaction Audit'}
]

const TicketAuditAndDiscountFilters = {
  seller_id: {
    options: getSellerOptions
  },
  audit_action: ['purchased', 'redeemed', 'refunded', 'transfer_from', 'transfer_to', 'unredeemed', 'force_redeemed', 'invalidated', 'revalidated'],
  event: null
}

const identityTransferOptions = ['transfer_identity_from', 'transfer_identity_to']

const TicketAudit2Filters = {
  ...TicketAuditAndDiscountFilters,
  ...{
    audit_action: TicketAuditAndDiscountFilters.audit_action.concat(identityTransferOptions)
  }
}

const metaFilters = {
  gateway_audit: {
    gateway_id: null,
    audit_action: ['refund', 'authorize', 'capture', 'cancel', 'dispute_opened', 'dispute_fee', 'dispute_closed']
  },
  identity_changed: {
    provider: ['tix', 'staff', 'org', 'admin', 'anon']
  },
  managed_cart_audit: {
    audit_action: ['held', 'removed', 'discarded'].concat(identityTransferOptions)
  },
  ticket_audit_2: TicketAudit2Filters,
  ticket_audit_external: TicketAudit2Filters,
  ticket_discount: TicketAuditAndDiscountFilters,
  transaction_audit: {
    audit_action: ['identity_merge_initiated', 'identity_merge_completed', 'cart_transfer_booked', 'cart_transfer_held']
  }
}

const batchedTypes = ['gateway_audit', 'identity_changed', 'managed_cart_audit', 'ticket_audit_2', 'ticket_audit_external', 'ticket_discount', 'transaction_audit']

const maxOfTypeAllowed = 5

const batchSizes = [40, 60, 80, 100, 200]

export default {
  components: {
    field,
    barefield,
    filtersc,
    dUrl
  },
  props: {
    at: {
      type: AutomatedTask,
      required: true
    }
  },
  async setup(props) {
    const isEditable = ref(true)
    // Collect event detachers to invoke them when the component is unmounted
    const toDetach = []
    onUnmounted(() => toDetach.forEach(off => off()))
    const toLoad = [
      GETCache.get('webhook?v2'),
      GETCache.get('event_template') // Just pre-load this. The filter subcomponents will enjoy the cached result.
    ]
    if (!metaFilters.gateway_audit.gateway_id) {
      toLoad.push(GETCache.get('portal_gateway'))
    }
    const rs = await Promise.all(toLoad)
    const all = rs[0]
    if (!metaFilters.gateway_audit.gateway_id) {
      metaFilters.gateway_audit.gateway_id = rs[2].portal_gateway._data.map(pg => pg.gateway_id)
    }
    const data = props.at.dr.data
    // Create type-to-count map:
    const t2c = new Map()
    types.forEach(t => t2c.set(t.code, 0)) // fill all the types with 0 for starters
    Array.from(all.values())
      .filter(at => at.intact.format?.webhook_standard)
      .forEach(at => {
        const type = at.intact.format.webhook_standard.webhook_type
        t2c.set(type, t2c.get(type) + 1)
      })
    const existingCount = computed(() => t2c.get(data.format.webhook_standard.webhook_type))
    if (!props.at.id) {
      const typeC = computed(() => data.format.webhook_standard.webhook_type)
      const typeLabel = computed(() => {
        const type = types.find(t => t.code === typeC.value)
        return type ? type.label : ''
      })
      const applyType = () => {
        if (t2c.get(typeC.value) < maxOfTypeAllowed) {
          data.name = typeLabel.value
          isEditable.value = true
        } else {
          isEditable.value = false
        }
        delete data.trigger.filters
      }
      applyType()
      watch(typeC, applyType)
    }
    // The Request Headers field is edited as text, but saved as a structured object (see $.destination.webhook.request_headers in the JSON schema)
    const a = []
    const who = data.destination.webhook
    const rho = who.request_headers
    if (rho) {
      Object.keys(rho).forEach(k => a.push(`${k}: ${rho[k]}`))
    }
    const rHeadersText = ref(array2String(a))
    const rHeadersError = ref(false)
    const hmacError = ref(false)
    const isBatched = computed(() => batchedTypes.includes(data.format.webhook_standard.webhook_type))
    toDetach.push(
      props.at.on('beforesave', () => {
        const wh = data.destination.webhook
        const text = rHeadersText.value.trim()
        if (text) {
          const rh = {}
          string2Array(text).forEach(al => {
            al = al.trim().split(':')
            rh[al.shift().trim()] = al.join(':').trim()
          })
          if (RHValidator(rh) !== true) {
            rHeadersError.value = 'Invalid value'
            throw new NonAlarmingError('Request Headers are invalid')
          }
          if (props.at.id && wh.hmac_signing_key !== undefined) {
            // Signing key exists for already existing webhooks only
            const signingKey = wh.hmac_signing_key
            const pattern = /[0-9a-zA-Z]{64,}/
            if (!pattern.test(signingKey) || signingKey.length % 2 > 0) {
              hmacError.value = 'Invalid HMAC signing key'
              throw new NonAlarmingError('Invalid HMAC signing key')
            }
          }
          rHeadersError.value = false
          wh.request_headers = rh
        } else {
          delete wh.request_headers
        }
      })
    )
    toDetach.push(
      props.at.on('candidatedata', candidateData => {
        if (!isBatched.value) {
          delete candidateData.trigger.pull
          delete candidateData.cursor
        }
      })
    )
    const isTypeOptionSelectable = op => (op.requires ? t2c.get(op.requires) > 0 : true)
    return {
      isTypeOptionSelectable,
      existingCount,
      maxOfTypeAllowed,
      types,
      data,
      batchSizes,
      rHeadersText,
      rHeadersError,
      hmacError,
      isEditable,
      isBatched,
      metaFilters
    }
  }
}
</script>
<template>
  <form class="SettingForm">
    <slot name="forType"></slot>
    <div class="FieldWrapper FieldWrapper__FullWidth WebhookType">
      <label>Webhook type</label>
      <div>
        <field type="dropdown" :options="types" :holder="data.format.webhook_standard" name="webhook_type" :disabled="!!at.id" :isSelectable="isTypeOptionSelectable" autoSelectFirstByDefault />
        <div>Created: {{ existingCount }}&#160;&#160;|&#160;&#160;Allowed: {{ maxOfTypeAllowed }}</div>
      </div>
    </div>
    <template v-if="isEditable">
      <hr />
      <slot name="forName"></slot>
      <filtersc :type="data.format.webhook_standard.webhook_type" :meta="metaFilters" :data="data" />
      <dUrl :data="data.destination.webhook"></dUrl>
      <div class="FieldWrappers" v-if="isBatched">
        <div class="FieldWrapper">
          <label>Batch size</label>
          <field type="dropdown" :options="batchSizes" :holder="data.trigger.pull" name="batch_size" description="Specifying 200 would result in 2 webhooks being sent in quick succession." />
        </div>
        <div class="FieldWrapper DateFieldWrapper">
          <label class="required">Send event data starting from</label>
          <field :holder="data.cursor" name="window_end" type="date" description="A date from up to 3 months in the past may be chosen" />
        </div>
      </div>
      <div class="FieldWrapper FieldWrapper__FullWidth RequestHeaders">
        <label>Request headers (optional)</label>
        <barefield type="text" v-model="rHeadersText" description="Key:Value pairs, one per line" placeholder="Key: Value" :error="rHeadersError"></barefield>
      </div>
      <div class="FieldWrapper FieldWrapper__FullWidth hmacSigningKey">
        <label>HMAC signing key</label>
        <barefield type="text" v-model="data.destination.webhook.hmac_signing_key" :error="hmacError" description="Minimum of 64 characters required. Format should be HEX format (0-9, A-F), and an even number of characters."></barefield>
      </div>
      <slot name="bottom"></slot>
    </template>
  </form>
</template>
<style lang="scss">
.WebhookType {
  > div {
    display: flex;
    align-items: center;
    > div:first-child {
      width: calc(50% - 8px);
    }
    > div:last-child:not(.Field3__Container) {
      margin-left: 16px;
      color: $yet-another-grey;
    }
  }
}
.RequestHeaders textarea {
  height: 120px;
}
.hmacSigningKey {
  textarea {
    height: 96px;
  }
}
</style>
