<script>
import {onUnmounted} from 'vue'
import {NonAlarmingError} from '@/api/error/Misc'
import {api2number} from './NextOffset.vue'
import AutomatedTask from '../Class'
import Task from './Task.vue'
import {defaultData} from '../Class'
import ttd from '@old/TicketTemplateData'
import {setError, maybeScrollToError} from '@/helpers/ErrorHolder'
const allTheKeys = Object.keys(defaultData.autorenew.format.auto_renew)

const getTaskNextOffset = (taskKey, allTasks) => ({
  holder: allTasks[taskKey],
  value: api2number(allTasks[taskKey].next_offset),
  taskKey
})

const offerErrorCommon = ". It should decrease in each subsequent task, ensuring it's lower than the preceding one."

// Each offset needs to be between 20 hours and 60 days (24*60 hours = 1440 hours) from time of expiry (see https://tixtrack.atlassian.net/browse/TIC-2884?focusedCommentId=92859 )
const minOffsetFromRenewal = 20
const maxOffsetFromRenewal = 1440

const validateNextOffsets = allTasks => {
  // Check that the next_offset value of each subsequent task is lower than the previous.
  let keys = Object.keys(allTasks).filter(key => key.startsWith('notify') || key === 'request')
  keys = allTheKeys.filter(k => keys.includes(k))
  if (!keys.length) {
    return
  }
  let i = 0
  let currentOffset
  let nextOffset
  let holder
  let nextHolder
  let erred
  for (; i < keys.length; i++) {
    erred = false
    holder = allTasks[keys[i]]
    currentOffset = api2number(holder.next_offset)
    nextOffset = false
    if (i < keys.length - 1) {
      nextHolder = allTasks[keys[i + 1]]
      nextOffset = api2number(nextHolder.next_offset)
    }
    if (currentOffset !== false) {
      erred = true
      if (currentOffset < minOffsetFromRenewal) {
        setError(holder, 'next_offset', `This value must be at least ${minOffsetFromRenewal} hours`)
      } else if (currentOffset > maxOffsetFromRenewal) {
        setError(holder, 'next_offset', `This value must be no higher than ${maxOffsetFromRenewal} hours`)
      } else if (nextOffset !== false && nextOffset >= currentOffset) {
        setError(holder, 'next_offset', `This value should be higher than ${nextOffset}${offerErrorCommon}`)
        setError(nextHolder, 'next_offset', `This value should be less than ${currentOffset}${offerErrorCommon}`)
      } else {
        erred = false
      }
    }
    if (erred) {
      maybeScrollToError()
      throw new NonAlarmingError('Incorrect next_offset values')
    } else {
      ;[holder, nextHolder].forEach(h => {
        if (h) {
          setError(h, 'next_offset', false)
        }
      })
    }
  }
}

export default {
  components: {
    Task
  },
  props: {
    at: {
      type: AutomatedTask,
      required: true
    }
  },
  async setup(props) {
    const toDetach = []
    onUnmounted(() => toDetach.forEach(off => off()))
    await ttd.load()
    const data = props.at.dr.data.format.auto_renew
    toDetach.push(props.at.on('beforesave', () => validateNextOffsets(data)))
    return {
      validateNextOffsets,
      allTheKeys,
      data
    }
  }
}
</script>
<template>
  <form class="SettingForm MembershipAutoRenew">
    <slot name="forType"></slot>
    <h3>Tasks</h3>
    <div class="MembershipAutoRenew__Body">
      <Task v-for="key in allTheKeys" :data="data" :key="key" :thekey="key" @nextOffsetBlur="validateNextOffsets(data)" />
    </div>
    <slot name="bottom"></slot>
  </form>
</template>
<style lang="scss">
.MembershipAutoRenew {
  h3 {
    border-top: 1px solid $v3-separator-color;
    color: $v3-dark-color;
    padding: 16px;
    margin: 0;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
  }
  &__Body {
    background-color: $ticket-group-body-colour;
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    padding: 16px;
    > div {
      border-radius: 2px;
      background-color: $white;
      flex-basis: 100%;
    }
  }
  .Form__Actions {
    margin-top: 0 !important;
    border-top: 0 !important;
  }
}
</style>
