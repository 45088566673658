import UniqueOptionsManager from '../../UniqueOptionsManager'
import time_of_day from '@/components/TimeRange.vue'
import date_range from '@/components/DateRange.vue'
import weekdays from '@/components/WeekdaySelector.vue'

const cmpMap = {
  weekdays,
  date_range,
  time_of_day
}

class Manager extends UniqueOptionsManager {
  static useNonEmptyDefault = true

  static OPTIONS = new Map([
    ['date_range', 'between the following dates'],
    ['weekdays', 'only on the following days'],
    ['time_of_day', 'between the following times']
  ])

  static cmpTeller = k => cmpMap[k] || 'div'

  onEmpty(i) {
    this.removeValueAt(i)
  }

  getDefaultHolder(k) {
    if (k === 'weekdays') {
      return []
    }
    return super.getDefaultHolder(k)
  }

  onTypeChange(index, newType, oldType) {
    const holder = this.getHolder()
    if (oldType && holder[oldType]) {
      delete holder[oldType]
      holder[newType] = this.getDefaultHolder(newType)
    }
  }
}

export default Manager
