<script setup>
import DDLManager from './DDLManager'
import DDL from './DDL.vue'

const props = defineProps({
  manager: {
    type: DDLManager,
    required: true
  },
  options: {
    type: Array,
    required: true
  },
  prongsAnded: {
    type: Boolean
  },
  isBoth: {
    type: Boolean
  },
  isFirst: {
    type: Boolean
  }
})
const onItemOR = () => props.manager.addValue()
const emit = defineEmits(['or'])
const onAddProng = () => emit('or')
const isORAvailable = props.manager.sr.isORAvailable
</script>
<template>
  <div class="MR__Trigger__TwoProngFork__Instance" :class="{ORUnAvailable: !isORAvailable, isNotFirst: !isFirst}" v-if="manager.sr.isReady.value">
    <DDL v-for="(v, i) in manager.dr.items" :index="i" :manager="manager" v-model="manager.dr.items[i]" :error="manager.dr.errors[i]" :options="manager.dr.options[i]" :prongOptions="options" :id="`${manager.id}-${i}`" :class="{error: manager.dr.errors[i]}" />
    <div v-if="!isBoth || isORAvailable" class="MR__Trigger__TwoProngFork__Add">
      <div></div>
      <div class="MR__Trigger__TwoProngFork__Add__Button" :class="{prongsAnded: prongsAnded}"><div @click="onAddProng" /></div>
      <div class="MR__Trigger__TwoProngFork__Add__Button"><div v-if="isORAvailable" @click="onItemOR" /></div>
      <div class="MR__Delete"></div>
    </div>
  </div>
</template>
<style lang="scss">
.MR__Trigger__TwoProngFork__Instance {
  position: relative;

  &::before,
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    z-index: 0;
  }

  &::before {
    border-right: 1px solid $v3-border-color;
    top: 0;
    right: 418px;
  }

  &::after {
    border-right: 1px solid $v3-border-color;
    bottom: 24px;
    height: 16px;
    right: 154px;
  }

  &.ORUnAvailable {
    &::after {
      display: none;
    }
  }

  &.isNotFirst {
    &::before {
      display: none;
    }
    .MR__Trigger__TwoProngFork__Add__Button:not(:nth-child(3)) {
      visibility: hidden;
    }
  }
}
.MR__Trigger__TwoProngFork__Add {
  background-color: white;
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  margin-top: $v3-spacing;

  .MR__Delete {
    visibility: hidden;
  }

  > div:first-child {
    width: 26px;
  }

  &__Button {
    width: $message-rules-ddl-width;
    display: flex;
    justify-content: center;
    margin-left: $v3-spacing;

    > div {
      @extend %or-button;
    }

    &.prongsAnded > div {
      @extend %and-button;
    }
  }
}
</style>
