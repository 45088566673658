<script setup>
import {ref} from 'vue'
import {useRoute} from 'vue-router'
import {buildMenuRoutes} from '../helpers/MenuRoutes'
import IsActiveRoute from '../helpers/IsActiveRoute'
import {filterLegacyStaffRoute, filterStaffManagementRoute, getConfigAuthMethods} from '@/helpers/access'
const props = defineProps({
  // Either "rootName" or "routes" is required, but not both
  rootName: {
    type: String
  },
  routes: {
    type: Array
  },
  // Route params (assuming all routes in the menu will have the same ones, which is the normal use-case for this component)
  params: {
    type: Object
  }
})
const route = useRoute()
const isReady = ref(false)
let menuRoutes
if (props.rootName) {
  menuRoutes = buildMenuRoutes(props.rootName, props.params)
    // TODO Remove once staff transition is completed
    .filter(r => filterStaffManagementRoute(r.name))
    .filter(r => filterLegacyStaffRoute(r.name))
  const staffItem = menuRoutes.find(r => r.name === 'portal-administrators')
  if (staffItem && getConfigAuthMethods().includes('ticketure_staff')) {
    // TODO Remove once staff transition is completed
    staffItem.title = 'Staff (Legacy)'
  }
} else {
  menuRoutes = buildMenuRoutes(props.routes, props.params)
}
isReady.value = true
</script>
<template>
  <div class="Tabs" v-if="isReady">
    <ul>
      <li v-for="r in menuRoutes" :class="{active: IsActiveRoute(r.name, route.name)}" data-vue="3">
        <router-link :class="'r-' + r.name" :to="r.to" v-shtml="r.title" />
      </li>
    </ul>
  </div>
</template>
