// @cms-next @tix/api-sdk

import type {ApiErrorEntity, ApiResponseItem} from '@/api/response'

export default class TixApiError {
  isTixApiError = true
  message: string

  // retry is handled
  constructor(public body: ApiResponseItem<ApiErrorEntity>, public response: Response) {
    this.message = this.body._data[0]._description
  }

  toString(): string {
    return this.message
  }
}

export function isTixApiError(error: any): error is TixApiError {
  return (error as TixApiError).isTixApiError
}
