// @cms-next src/helpers/form/builder.ts
import type {JSONSchema4TypeName, JSONSchema4} from 'json-schema'
import type {FieldComponentProps} from '../Field/Types'

const initialValueMap: Partial<Record<JSONSchema4TypeName, () => any>> = Object.freeze({
  string: () => '',
  number: () => '',
  integer: () => '',
  boolean: () => false,
  object: () => ({}),
  array: () => []
  // null: '',
  // any: '',
})

export function initialDefaultValue(schema: JSONSchema4) {
  const type = schemaType(schema)
  if (initialValueMap[type] !== undefined) {
    return initialValueMap[type]()
  }

  if (schema.enum) {
    return schema.enum[0]
  }

  console.error('Unknown initial value', schema)
}

const schemaFieldTypeMap: Partial<Record<JSONSchema4TypeName, FieldComponentProps['type']>> = Object.freeze({
  string: 'string',
  number: 'number',
  integer: 'number',
  boolean: 'checkbox'
  // object: '',
  // array: '',
  // null: '',
  // any: 'string',
})

export function schemaToFieldType(spec: JSONSchema4): FieldComponentProps['type'] {
  if (spec.enum) {
    return 'dropdown'
  }

  if (spec.type === 'string' && spec.stringContentType === 'paragraph') {
    return 'text'
  }

  // Use a multiline <textarea> for an array of strings.
  if (spec.type === 'array') {
    const items = spec.items as JSONSchema4
    if (items.type === 'string') {
      return 'text'
    }
  }

  const type = schemaType(spec)
  if (schemaFieldTypeMap[type]) {
    return schemaFieldTypeMap[type]
  }
}

function schemaType(schema: JSONSchema4): JSONSchema4TypeName {
  return Array.isArray(schema.type) ? schema.type[0] : schema.type
}

export function fieldOptions(schema: JSONSchema4): (string | number)[] | undefined {
  if (schema.enum) {
    const result = []
    return result.concat(schema.enum)
  }
}
