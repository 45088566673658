<script>
import field from '@/components/Field/HeldProperty.vue'
export default {
  components: {
    field
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  }
}
</script>
<template>
  <div class="FieldWrapper FieldWrapper__FullWidth">
    <label class="required">Destination URL</label>
    <field :holder="data" name="url" description="The destination url endpoint is where webhook notifications or events are sent for processing or triggering actions in a recipient system or application."></field>
  </div>
</template>
