<script setup>
import {isoToMomentInTenantTimezone, nowInContextTimezone} from '@/helpers/datetime'
import {computedError} from '@/helpers/ErrorHolder'
import dp from './DatePicker.vue'
const dFormat = 'YYYY-MM-DD'
const props = defineProps({
  affix: {
    default: 'and'
  },
  data: {
    type: Object,
    required: true
  },
  // Any extra/overriding options for the underlying bootstrap datepickers
  options: {
    type: Object
  },
  startKey: {
    type: String,
    default: 'start'
  },
  endKey: {
    type: String,
    default: 'end'
  }
})
const now = nowInContextTimezone()
let minDate
if (props.data[props.startKey]) {
  const startMoment = isoToMomentInTenantTimezone(props.data[props.startKey])
  minDate = startMoment.isBefore(now) ? startMoment : now.clone()
  minDate = minDate.format(dFormat)
} else {
  props.data[props.startKey] = now.clone().format(dFormat)
  minDate = props.data[props.startKey]
}
if (!props.data[props.endKey]) {
  props.data[props.endKey] = now.clone().add(7, 'days').format(dFormat)
}
const error = computedError({
  holder: props.data,
  name: props.startKey
})
</script>
<template>
  <div class="MR__DateOrSession__DateRange" :class="{error: error}">
    <div>
      <dp v-model="data[startKey]" :options="options" />
      <span>{{ affix }}</span>
      <dp v-model="data[endKey]" :options="options" />
    </div>
    <div v-if="error" v-shtml="error" class="errorHolder" />
  </div>
</template>
<style lang="scss">
.MR__DateOrSession__DateRange {
  > div:first-child {
    @extend %triggerActionSpan;
    > span {
      padding: 0 16px;
    }
    display: flex;
    align-items: center;
  }
  &.error input.date-manual {
    border: 1px solid $warning-color !important;
  }
}
</style>
