// @cms-next @tix/common-utils
export function csvToArray(values: string): string[] {
  return parseDelimitedString(',', values)
}

function parseDelimitedString(delimiter: string, values: string): string[] {
  return values
    .split(delimiter)
    .map(value => value.trim())
    .filter(value => value.length > 0)
}
