export default {
  $id: '/tix/salespoint',
  type: 'object',
  $defs: {
    nameField: {
      type: 'string',
      pattern: '^(\\S((.|\n)*\\S)?)?$',
      minLength: 4,
      maxLength: 128
    },
    create: {
      type: 'object',
      additionalProperties: false,
      properties: {
        name: {
          $ref: '#/$defs/nameField'
        },
        portal_id: {
          $ref: '/tix/core#/$defs/guid'
        },
        seller_id: {
          $ref: '/tix/core#/$defs/guid'
        }
      },
      required: ['name', 'seller_id', 'portal_id']
    },
    update: {
      type: 'object',
      additionalProperties: false,
      properties: {
        name: {
          $ref: '#/$defs/nameField'
        }
      },
      required: ['name']
    }
  }
}
