<script setup>
// This is a Vue3 port of resources/assets/js/components/events/Details.vue1
import {ref, watch} from 'vue'
import RouteMenu from '../components/RouteMenu.vue'
import Event from '@old/Event'
import {useRoute} from 'vue-router'
import DDL from '@/components/DDL.vue'
import EventData from '@old/EventData'
const route = useRoute()
await window.vue1AppSellerPromise
await EventData.promise()
const ed = EventData.getInstance()
const eventId = route.params.id
const data1 = await Promise.all([Event.getVisibilityOptions(), ed.load(eventId)])
const visibilityOptions = []
Array.from(data1[0]).forEach(o => visibilityOptions.push({code: o[0], label: o[1].title}))
const event = ed.events.get(eventId)
await event.promiseExtraData()
const routes = event.getDetailsPageRoutes()
const routeParams = {id: eventId}
const sellerId = window.theApp.seller.id
const editLink = `/event/${eventId}/edit?seller=${sellerId}`
const dupLink = `/event/${eventId}/duplicate?seller=${sellerId}`
const canDelete = window.theApp.roleTeller.has('configure-events')
const allowVisibilitySelector = window.theApp.roleTeller.has(['configure-events', 'read-only-all'])
const visibilityValue = ref(event.hidden_type)
watch(visibilityValue, v => event.changeVisibility(v))
</script>
<template>
  <section class="Event EventVue3">
    <div slot="header" class="container">
      <div class="WarningBlock" v-if="event.singleNoSessions()">This event does not yet have a valid start and end time. You must <a :href="editLink">edit the event</a> and update it before you may add or edit ticket groups.</div>
      <div :class="event.getCssClasses('Event__header')" :style="event.getImageStyle()">
        <div>
          <div class="Event__header__main">
            <div class="Event__header__details">
              <h1>
                {{ event.name }}
                <a title="View on the frontend" target="_blank" class="Event__header__link2web" :href="event.webLink"></a>
              </h1>
              <div class="Event__header__venue">{{ event.venue.name }}</div>
              <div v-if="event.startDate && !event.isGD()" class="Event__header__dates">{{ event.getDateLine() }}</div>
            </div>
            <div class="Event__header__btns">
              <button v-if="canDelete" @click.prevent="event.archive(true)" class="btn btn-black uppercase">Archive Event</button>
              <router-link :to="dupLink" class="btn btn-black uppercase" v-if="event.canBeCloned()">Duplicate Event</router-link>
              <router-link :to="editLink" class="btn btn-primary uppercase editLink"></router-link>
            </div>
          </div>
          <DDL v-model="visibilityValue" :options="visibilityOptions" v-if="allowVisibilitySelector" data-readonly="disable" class="Event__Visibility" />
        </div>
      </div>
    </div>
    <RouteMenu :routes="routes" :params="routeParams" class="Tabs__Vertical container" />
    <div class="container">
      <router-view />
    </div>
  </section>
</template>
<style lang="scss">
.Event {
  &.EventVue3 {
    > .container:last-child {
      background-color: $white;
    }
    .Settings__Content__heading {
      border: 0;
    }
  }
  &__Visibility {
    border-radius: 2px;
    margin-top: 16px;
    width: auto;
    display: inline-block;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    background-color: $v3-blue !important;
    .vs__dropdown-toggle {
      padding: 0;
      border: none;
    }
    .vs__dropdown-menu {
      min-width: max-content !important;
    }
    .vs__selected {
      color: $white;
      padding-top: 0 !important;
      margin: 0 0 2px 0;
      opacity: 1 !important;
      position: static !important;
    }
    .vs__dropdown-toggle {
      height: 32px;
      justify-content: space-between;
      &::before {
        font-family: FontAwesome;
        font-weight: 400;
        content: '\f06e';
        width: 32px;
        min-width: 32px;
        margin-right: 5px;
        background-color: $v3-dark-color;
        color: $white;
        font-size: 16px;
        line-height: 32px;
        text-align: center;
      }
    }
    .vs__actions {
      cursor: pointer;
    }
    .vs__selected-options {
      align-items: center;
      cursor: pointer;
    }
    .vs__search {
      position: absolute;
      left: -9999px;
      top: -9999px;
    }
    .DDL__Chevron {
      border: none;
      background-image: none;
      width: 18px;
      height: 18px;
      margin-left: 7px;
      &::before {
        content: '\f0d7';
        color: $white;
        font-family: Dropdown;
        line-height: 1;
        font-weight: 400;
        font-size: 10.2857px;
        font-style: normal;
        text-align: center;
      }
    }
    .vs__actions {
      padding: 0;
    }
    .vs__dropdown-option {
      padding: 0 12px !important;
      height: 26px;
      &--highlight {
        background-color: $v3-blue !important;
      }
    }
  }
}
</style>
