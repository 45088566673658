<script>
import Duration from '@/classes/Duration'
import {addCfg, addWatch} from '@/helpers/vModelPassThrough'
export default addCfg({
  props: {
    keys: {
      type: String,
      default: 'days,hours,minutes'
    }
  },
  setup(props, {emit}) {
    const keys = props.keys.split(',')
    const stringRef = addWatch(props, emit)
    const dur = new Duration(stringRef.value, stringRef)
    return {
      keys,
      dur
    }
  }
})
</script>
<template>
  <div>
    <template v-for="k in keys"> <input type="text" v-model="dur.keys[k]" /><span v-shtml="k" /></template>
  </div>
</template>
<style lang="scss"></style>
